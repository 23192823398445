<template>
    <div>
        <div class="columns no-padding-bottom">
            <div class="column button-trash-mobile">
                <p>
                    <a class="is-pulled-right" v-on:click="removeProduct">
                        <b-icon class="trash-icon" icon="close-thick"></b-icon>
                    </a>
                </p>
            </div>
            <div class="column is-2 no-padding-bottom" :class="screenSize >= 769 ? '': 'has-text-centered'">
                <img class="image-produit" v-if="(produit.type == 'carnet')" src="@/assets/img/produits/carnet/love-carnets.webp">
                <img class="image-produit" v-else-if="(produit.type == 'passeport')" src="@/assets/img/produits/passeport/passeport.webp">
                <img class="image-produit" v-else-if="(produit.type == 'livret')" src="@/assets/img/produits/livret/livret-1.webp">
                <img class="image-produit" v-else-if="(produit.type == 'bandana') || (produit.type == 'bandana_prenom') || (produit.type == 'bandana_texte')" src="@/assets/img/produits/bandana/bandanas-4.webp">
                <img class="image-produit" v-else-if="(produit.type == 'bandana-reversible')" src="@/assets/img/produits/bandana/bandana-reversible-1.webp">
                <img class="image-produit" v-else-if="(produit.type == 'bandana-reversible-noel')" src="@/assets/img/produits/bandana/noel/bandanas.webp">
                <img class="image-produit" v-else-if="(produit.type == 'bandana-noel')" src="@/assets/img/produits/noeud/noeud.webp">
                <img class="image-produit" v-else-if="(produit.type == 'noeud-noel')" src="@/assets/img/produits/noeud/noel/noeuds.webp">
                <img class="image-produit" v-else-if="(produit.type == 'collier')" src="@/assets/img/produits/collier/collier-1.webp">
                <img class="image-produit" v-else-if="(produit.type == 'chouchou')" src="@/assets/img/produits/chouchou/chouchoux-camille-2.webp">
                <img class="image-produit" v-else-if="(produit.type == 'noeud')" src="@/assets/img/produits/noeud/noeud-2.webp">
                <img class="image-produit" v-else-if="(produit.type == 'cotons')" src="@/assets/img/produits/cotons/cotons-1.webp">
                <img class="image-produit" v-else-if="(produit.type == 'sac-banane')" src="@/assets/img/produits/banane/sac-banane-7.webp">
                <img class="image-produit" v-else-if="(produit.type == 'tote-bag')" src="@/assets/img/produits/tote-bag/tote-bag-1.webp">
                <img class="image-produit" v-else-if="(produit.type == 'balle-fouille')" src="@/assets/img/produits/balle-fouille/balle-fouille-3.webp">
            </div>
            <div class="column no-padding-bottom" :class="screenSize >= 1024 ? 'is-6' : screenSize < 769 ? 'is-9 has-text-centered center-block' : 'is-8'">
                <p class="product-title">
                    {{
                        produit.type == "carnet" && produit.noeud ? "Housse de carnet de santé pour animaux avec noeud" :
                        produit.type == "carnet" && !produit.noeud ? "Housse de carnet de santé pour animaux" :
                        produit.type == "passeport" && produit.noeud ? "Housse de passeport européen pour animaux avec noeud" :
                        produit.type == "passeport" && !produit.noeud ? "Housse de passeport européen pour animaux" :
                        produit.type == "livret" && produit.noeud ? "Housse de livret de famille avec noeud" :
                        produit.type == "livret" && !produit.noeud ? "Housse de livret de famille" :
                        produit.type == "sac-banane" && produit.metadata.sangleInterchangeable && produit.metadata.deuxiemeSangle ? "Sac banane avec 2 sangles" :
                        produit.type == "sac-banane" && produit.metadata.sangleInterchangeable && !produit.metadata.deuxiemeSangle ? "Sac banane avec sangle interchangeable" :
                        produit.type == "sac-banane" && !produit.metadata.sangleInterchangeable ? "Sac banane avec sangle intégrée" :
                        produit.type == "bandana" ? "Bandana" :
                        produit.type == "collier" ? "Collier" :
                        produit.type == "noeud" ? "Noeud" :
                        produit.type == "noeud-noel" ? "Noeud de Noël" :
                        produit.type == "bandana_prenom" ? "Bandana avec prénom" :
                        produit.type == "bandana_texte" ? "Bandana avec texte" :
                        produit.type == "bandana-reversible" ? "Bandana réversible" :
                        produit.type == "bandana-reversible-noel" ? "Bandana réversible de Noël" :
                        produit.type == "bandana-noel" ? "Bandana de Noël" :
                        produit.type == "tote-bag" ? "Tote bag" :
                        produit.type == "balle-fouille" ? "Balle de fouille" :
                        produit.type == "chouchou" ? "Chouchou" :
                        produit.type == "cotons" ? `Cotons lavables par ${ produit.taille == 'lot4' ? '4' : produit.taille == 'lot8' ? '8' : produit.taille == 'lot10' ? '10' : '' }` : ""
                    }}
                </p>

                <div class="columns">
                    <div class="column is-12 padding-top-5">
                        <p class="product-price">
                            {{ produit.prix }} € <span class="product-price-more">(unité)</span>
                        </p>
                        <p v-if="
                            produit.taille &&
                            produit.type != 'cotons' &&
                            produit.type != 'sac-banane' &&
                            produit.type != 'tote-bag' &&
                            produit.type != 'balle-fouille' && 
                            produit.type != 'carnet' &&
                            produit.type != 'passeport' &&
                            produit.type != 'livret'
                        ">
                            <span class="product-details">Taille :</span> {{
                                produit.taille == 'extra-small' ? 'XS' :
                                produit.taille == 'small' ? 'S' :
                                produit.taille == 'small-medium' ? 'S/M' :
                                produit.taille == 'medium' ? 'M' :
                                produit.taille == 'large' ? 'L' :
                                produit.taille == 'extra-large' ? 'XL' : ''
                            }}
                        </p>
                        <div v-if="produit.type != 'carnet' && produit.type != 'passeport' && produit.type != 'livret' && produit.type != 'sac-banane' && produit.type != 'tote-bag' && produit.type != 'balle-fouille' && produit.type != 'bandana-noel'">
                            <p class="product-details-more"><span class="product-details">Tissu :</span> {{produit.tissuNom}}</p>
                        </div>
                        <div v-else-if="produit.type == 'balle-fouille'">
                            <p class="product-details-more"><span class="product-details">Ensemble :</span> {{produit.tissuNom}}</p>
                        </div>
                        <div v-else-if="produit.type == 'bandana-noel'">
                            <p class="product-details-more"><span class="product-details">Personnalisation :</span> {{produit.tissuNom}}</p>
                        </div>
                        <div v-else-if="produit.type == 'sac-banane'">
                            <p class="product-details-more"><span class="product-details">Tissus : </span>{{produit.tissuNom}}</p>
                            <p v-if="!produit.metadata.deuxiemeSangle" class="product-details-more">
                                Sangle avec le même tissu que l'{{produit.metadata.couleurSangle}}
                            </p>
                            <p v-else-if="produit.metadata.deuxiemeSangle" class="product-details-more">
                                <span>Sangle 1 avec le même tissu que l'extérieur</span><br/>
                                <span>Sangle 2 avec le même tissu que l'intérieur</span>
                            </p>
                        </div>
                        <div v-else-if="produit.type == 'tote-bag'" class="product-details-more">
                            Avec photo à envoyer par mail ou sur instagram pour la personnalisation
                        </div>
                        <div v-else>
                            <p class="product-details-more"><span class="product-details">Tissu haut :</span> {{produit.tissuSecondaire}}</p>
                            <p class="product-details-more"><span class="product-details">Tissu bas :</span> {{produit.tissuNom}}</p>
                        </div>
                        <p class="product-details-more" v-if="produit.texte">
                            <span class="product-details">
                                Texte personnalisé
                                <span v-if="(produit.texte && produit.couleurTexte)">{{produit.couleurTexte}}</span>
                                :
                            </span>
                            {{produit.texte}}
                        </p>
                    </div>
                </div>
                <div class="quantite-prix-mobile">
                    <b-numberinput
                        class="is-centered center-block input-prix"
                        min="1"
                        max="9"
                        v-model="productChange.quantite"
                        @input="quantiteChange"
                    />
                    <p class="is-margin-top-10"><span class="product-details">Total :</span> {{
                        produit.quantite * produit.prix
                    }} €
                </p>
                </div>
            </div>
            <div class="column is-2 no-padding-bottom quantite-prix-desktop">
                <b-numberinput
                    min="1"
                    max="9"
                    controls-position="compact"
                    v-model="productChange.quantite"
                    @input="quantiteChange"
                />
            </div>
            <div class="column is-1 no-padding-bottom quantite-prix-desktop" >
                <p>{{
                        produit.quantite * produit.prix
                    }} €
                </p>
            </div>
            <div class="column is-1 no-padding-bottom button-trash-desktop">
                <a v-on:click="removeProduct">
                    <b-icon class="trash-icon" icon="close-thick" size=""></b-icon>
                </a>
            </div>
        </div>
    </div>

</template>


<script>
import { mapState } from 'vuex'

export default {
    name: 'PanierProductView',
    props: ['produit'],
    computed: {
        ...mapState(['tissus','panier']),
        productChange: {
            get: function() {
                return this.produit
            }
        }
    },
    data() {
        return {
            screenSize: window.innerWidth
        }
    },
    methods: {
        removeProduct() {
            const indexProduitExiste = this.panier.produits.findIndex((obj) => obj.key === this.produit.key);
            if (indexProduitExiste != -1) {
                this.panier.produits.splice(indexProduitExiste, 1)
            }
            this.$store.dispatch('updatePriceAndQuantite')
        },
        quantiteChange() {
            this.$store.dispatch('updatePriceAndQuantite')
        }
    },
}
</script>
  
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

$bold-light: 700;
.product-title {
    font-size:18px;
    font-weight: $bold-light;
    margin-bottom:5px;
}

.product-price {
    font-size:16px;
    font-weight: $bold-light;
    color: $grey;
    margin-bottom:10px;
}

.product-price-more {
    font-weight: 500;
    font-size:15px;
}

.product-details {
    font-weight: 550;
}

.product-details-more {
    font-size:14px;
}

.trash-icon {
    color: black !important
}

.no-padding-bottom {
    padding-bottom:0
}

.padding-top-5 {
    padding-top:5px
}

.button-trash-mobile {
    display: none;
}

@media screen and (min-width: 769px) {
    .image-produit {
        border-radius: 10px;
        margin-bottom:25px
    }
}

@media screen and (max-width: 768px) {
    .button-trash-mobile {
        display: block;
    }

    .button-trash-desktop {
        display: none;
    }

    .image-produit {
        max-width:100px;
        border-radius: 10px;
        margin-right:-24px
    }
}

.quantite-prix-mobile {
    display: none;
}

.input-prix {
    max-width: 200px;
}

@media screen and (max-width: 1023px) {
    .quantite-prix-mobile {
        display: block;
    }

    .quantite-prix-desktop {
        display: none;
    }
}

.is-margin-top-10 {
    margin-top:10px
}
</style>
