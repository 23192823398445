<template>
    <div>
        <p class="titre">{{ produit.titre }}</p>
        <p class="prix">
            <span>
                {{ Number.parseFloat(produit.prix[taille]).toFixed(2) }} €
            </span>
        </p>
        <div v-if="!produit.afficher_inactif">
            <div v-if="produit.insta_uniquement == true">
                <div v-for="(description) in produit.descriptions" :key="description">
                    <p class="description">{{ description }}</p>
                </div>
                <div class="bloc-description-insta">
                    <p class="description-insta">Ce produit est disponible à la vente sur instagram ou par mail.</p>
                    <p class="description-insta">Pour me contacter :</p>
                    <li>Instagram : <a class="link-insta" href="https://www.instagram.com/cameli_melo/">@cameli_melo</a></li>
                    <li>Email : <span class="link-insta">camelimelo.creation@gmail.com</span></li>
                </div>
            </div>
            <div v-else>
                <div v-for="(description, index) in produit.descriptions" :key="description">
                    <p class="description" v-if="showMoreDescription && index +1 != produit.descriptions.length">{{ description }}</p>
                    <p class="description" v-if="showMoreDescription && index +1 == produit.descriptions.length">{{ description }} <a class="see-more" @click="showMoreDescription = false">voir moins</a></p>
                    <p class="description" v-else-if="index == 0 && !showMoreDescription">{{ description }}</p>
                    <p class="description" v-else-if="index == 1 && !showMoreDescription">
                        {{ description.length >= 120 ? description.substring(0, 119) + '...' : description }}
                        <a class="see-more" @click="showMoreDescription = true">
                            voir plus
                        </a>
                    </p>
                </div>
            </div>
        </div>
        <div v-else>
            <div v-for="(description, index) in produit.descriptions" :key="description">
                <p class="description" v-if="showMoreDescription && index +1 != produit.descriptions.length">{{ description }}</p>
                    <p class="description" v-if="showMoreDescription && index +1 == produit.descriptions.length">{{ description }} <a class="see-more" @click="showMoreDescription = false">voir moins</a></p>
                    <p class="description" v-else-if="index == 0 && !showMoreDescription">{{ description }}</p>
                    <p class="description" v-else-if="index == 1 && !showMoreDescription">{{ description }}</p>
                    <p class="description" v-else-if="index == 2 && !showMoreDescription">{{ description }}</p>
                    <p class="description" v-else-if="index == 3 && !showMoreDescription">{{ description }}</p>
                    <p class="description" v-else-if="index == 4 && !showMoreDescription">
                        {{ description.length >= 120 ? description.substring(0, 119) + '...' : description }}
                        <a class="see-more" @click="showMoreDescription = true">
                            voir plus
                        </a>
                    </p>
            </div>
            <div class="bloc-description-insta">
                <p class="description-insta">Ce produit n'est plus disponible pour le moment.</p>
                <p class="description-insta">Si vous souhaitez en savoir plus sur sa future disponibilité vous pouvez me contacter sur instagram ou par mail :</p>
                <li>Instagram : <a class="link-insta" href="https://www.instagram.com/cameli_melo/">@cameli_melo</a></li>
                <li>Email : <span class="link-insta">camelimelo.creation@gmail.com</span></li>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProductInformations',
    data() {
        return {
            showMoreDescription: false,
        }
    },
    props: ['produit', 'taille']
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.titre {
    text-align: left;
    font-weight: 750;
    font-size:$text-title-size;
    margin-bottom:10px;
}

.prix {
    text-align: left;
    font-weight: 400;
    font-size:$text-subtitle-size;
    margin-bottom:30px;
}

.entete-description {
    text-align: justify;
    font-weight: 650;
    line-height: 20px;
    margin-bottom:8px;
    margin-top:10px
}

.description {
    text-align: justify;
    line-height: 20px;
    margin-bottom:10px;
}

.see-more {
    color:$primary-color;
    font-weight: 400;
}

.bloc-description-insta {
    margin-top:50px;
}

.description-insta {
    text-align: justify;
    line-height: 20px;
    margin-bottom:5px;
    font-weight: 600;
}

.link-insta {
    color: $primary-color;
    font-weight: 600;
}
</style>
