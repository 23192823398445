<template>
    <div class="hero" style="margin-top:200px; margin-bottom:100px">
        <div class="hero-body is-justify-content-center is-align-items-center" style="padding-top:0">            
            <p class="has-text-centered coming-soon">La page que vous recherchez n'existe pas.</p>
        </div>
        <div class="has-text-centered  hero-body is-align-items-center is-justify-content-center ">
            <b-button class="button is-primary" v-on:click="goToHome()" size="is-large">Page d'accueil</b-button>
        </div>
    </div>
</template>

<script>
import {  logIn } from '@/services/UserService'
import { mapState } from 'vuex'

export default {
    name: 'LoginView',
    data() {
        return {
            email: "",
            password: "",
            messageErreur: "",
            prevRoute: null,
        }
    },
    methods: {
        async login() {
            try {
                const user = await logIn({
                    email: this.email,
                    password: this.password
                })
                this.$store.commit('SET_CURRENT_USER', {
                    id: user.id,
                    email: user.attributes.email,
                    sessionToken: user.attributes.sessionToken,
                })
                if (this.prevRoute.path == '/connexion' || this.prevRoute.path == '/inscription') {
                    this.$router.push('/')
                } else {
                    this.$router.push(this.prevRoute.path)
                }
            }
            catch(e) {
                if (e.message == "Invalid username/password.") {
                    this.messageErreur = "Email ou mot de passe incorrect."
                } else {
                    this.messageErreur = "Erreur interne, veuillez réessayer plus tard."
                }
            }
        },
        goToHome() {
            this.$router.push('/')
        },
        goToInscription() {
            this.$router.push('inscription')
        },
        goToPrecedent() {
            if (this.prevRoute.path == '/connexion' || this.prevRoute.path == '/inscription') {
                this.$router.push('/')
            } else {
                this.$router.push(this.prevRoute.path)
            }
        },
    },
    computed: {
        ...mapState(['currentUser'])
    },
    mounted() {
        if (this.currentUser) {
            this.$router.push('/')
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from
        })
    },
}
</script>

<style lang="scss" scoped>


@import "@/assets/scss/variables.scss";

.coming-soon {
    color: $primary-color;
    font-weight:700;
    font-size:40px;
}

.hero-body {
    padding-top:0
}
</style>