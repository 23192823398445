<template>
    <div class="container">
        <p class="presentation-title has-text-centered">Prêt à craquer ?</p>
        <p class="presentation-description center-block">Tous mes produits sont confectionnés à la main avec amour et passion à Lille !</p>
        <div class="columns">
            <div class="column" v-for="image in images" :key="image.name">
                <img class="center-block presentation-produit-image" :src="require(`@/assets/img/${image.route}`)" alt="exemple de produits">
                <p class="has-text-centered presentation-produit-nom">{{image.name}}</p>
            </div>
        </div>
        <div class="has-text-centered">
            <b-button type="is-primary" tag="router-link"  :to="{ path: '/produits' }">Voir les produits</b-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Products',
    data() {
        return {
            images: [
                {
                    route: "produits/carnet/love-carnets.webp",
                    name: "Housses de carnet de santé"
                },
                {
                    route: "produits/cotons/cotons-1.webp",
                    name: "Cotons"
                },
                {
                    route: "produits/noeud/noeud.webp",
                    name: "Bandanas personnalisés & noeuds"
                },
                {
                    route: "produits/collier/collier-1.webp",
                    name: "Collier"
                },
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.presentation-title {
    font-size:$text-title-size;
    font-weight: 600;
    margin-bottom: 10px;
}

.presentation-description {
    text-align: center;
    margin-bottom: 20px;
}

.presentation-produit-image {
    border-color:white;
    border-radius: 20px;
}

.presentation-produit-nom {
    margin-top: 10px;
}

@media screen and (max-width: 1023px) {
    .container {
        margin-left:30px;
        margin-right:30px;
    }
}

@media screen and (max-width: 768px) {
    .presentation-produit-image {
        max-height: 18rem;
    }
}
</style>
