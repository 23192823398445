import Parse from 'parse'

export async function logIn(user) {
    try {
        let response = await Parse.User.logIn(user.email.toLowerCase(), user.password);

        const query = new Parse.Query(Parse.Role);
        const roles = await query.find()

        for (let role of roles) {
            const roleUsers = role.relation('users')
            role.roleUsers = await roleUsers.query().find()
            const exist = role.roleUsers.find(e => {
                return e.id == response.id
            })
            if (exist) {
                response.role = role.attributes.name
            }
        }
        return response;
    } catch(e) {
        throw new Error(e.message)
    }
}

export async function signIn(user) {
    try {
        const ParseUser = new Parse.User();
        ParseUser.set("username", user.email.toLowerCase());
        ParseUser.set("email", user.email.toLowerCase());
        ParseUser.set("prenom", user.prenom.charAt(0).toUpperCase() + user.prenom.slice(1));
        ParseUser.set("nom", user.nom.toUpperCase());
        ParseUser.set("password", user.password);
        const response = await ParseUser.signUp();

        const query = new Parse.Query(Parse.Role);
        query.equalTo("name", "User");
        const result = await query.first()
        result.getUsers().add(response)
        await result.save()

        await fetch(`https://api.camelimelo.fr/sendInscription`, {
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                email: user.email.toLowerCase(),
                prenom: user.prenom.charAt(0).toUpperCase() + user.prenom.slice(1),
                nom: user.nom.toUpperCase()
            }),
            method: 'POST',
        })
        return response;
    } catch(e) {
        throw new Error(e.message)
    }
}

export async function logOut() {
    try {
        await Parse.User.logOut()
    } catch(e) {
        throw new Error(e.message)
    }
}

export async function contactCameliMelo(body) {
    try {
        const response = await fetch(`https://api.camelimelo.fr/contact`, {
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(body),
            method: 'POST',
        })
        if (response.status != 200) {
            throw new Error(response.status)
        }
        return '';
    } catch(e) {
        throw new Error(e)
    }
}

export async function getOrders(user) {
    if (!user) {
        return []
    }
    const Commande = Parse.Object.extend("commande");
    const query = new Parse.Query(Commande);
    query.equalTo("user", user);
    query.notContainedIn("status", ["en attente de paiement", "annulé"]);
    query.descending('createdAt');
    const results = await query.find();
    return results.map(product => Object.assign(product.attributes));
}
export async function getOrderById(id) {
    if (!Parse.User.current()) {
        return null
    }
    
    try {
        const Commande = Parse.Object.extend("commande");
        const query = new Parse.Query(Commande);
        query.equalTo("user", Parse.User.current());
        query.equalTo("commande_id", id);
        const result = await query.first();
        return result.attributes;
    }
    catch(e) {
        throw new Error(e)
    }
}