<template>
    <div>
        <div class="card panier-details">
            <p style="font-size:18px" class="has-text-weight-semibold">Détail du panier</p>
            <hr>
            <p>
                Sous-total
                <span class="is-pulled-right">
                    {{ Number.parseFloat(panier.tarif).toFixed(2) }} €
                </span>
            </p>
            <p>Frais de livraison estimé <span class="is-pulled-right">{{ Number.parseFloat((montant_livraison)).toFixed(2) }} €</span></p>
            <p style="font-size:15px;">(ou retrait en main propre gratuit)</p>
            <hr>
            <div v-if="!code_promo">
                <div v-if="!addCodePromo">
                    <a class="addCodePromo" @click="addCodePromo = true">Un code promo ?</a>
                </div>
                <div v-else>
                    <p  style="font-weight: 550; margin-bottom:10px">Code promo</p>
                    <b-field v-if="!code_promo" class="label-code-promo">
                        <b-input icon="ticket-percent" v-model="codePromo"></b-input>
                    </b-field>
                    <b-button type="is-primary" expanded @click="defineCodePromo()">Appliquer</b-button>
                    <p v-if="errorCodePromo" class="errorCodePromo">{{ errorCodePromo }}</p>
                </div>

            </div>
            <b-tag v-if="code_promo"
                type="is-primary"
                closable
                size="is-medium"
                aria-close-label="Close tag"
                @close="updateCodePromo()">
                {{code_promo.id}} (-{{ code_promo.percent }}%)
            </b-tag>
            <hr>
            <p v-if="code_promo" style="font-style:italic">
                Réduction 
                <span class="is-pulled-right">
                    - {{ Number.parseFloat(reduction).toFixed(2) }} €
                </span>
            </p>
            <p style="font-weight:600; font-size:17px">Total
                <span class="is-pulled-right" v-if="code_promo">
                    {{ Number.parseFloat((panier.tarif - reduction + montant_livraison)).toFixed(2) }} €
                </span>
                <span class="is-pulled-right" v-else>
                    {{ Number.parseFloat((panier.tarif + montant_livraison)).toFixed(2) }} €
                </span>
            </p>
            <br/>
            <b-button type="is-primary" expanded tag="router-link"  :to="{ path: '/checkout' }">Valider la commande</b-button>
        </div>
    </div>
</template>

  
<script>
import { mapState } from 'vuex'

export default {
    name: 'PanierDetail',
    data() {
        return {
            date: new Date(),
            expireDate: new Date(),
            isTag1Active: true,
            codePromo: "",
            errorCodePromo: "",
            addCodePromo: false,
            montant_livraison: 4.95
        }
    },
    computed: {
        ...mapState(['panier', 'tissus', 'code_promo', 'codes_promo', 'reduction']),
    },
    methods: {
        updateCodePromo() {
            this.$store.commit('SET_CODE_PROMO', null)
        },
        defineCodePromo() {
            this.errorCodePromo = ""
            if (!this.codePromo) {
                return
            }
            const response = this.codes_promo.find(e => { return e.id == this.codePromo })
            if (!response) {
                this.errorCodePromo = "Ce code promo n'existe pas.";
                return
            }
            if (response.expirationDate <= new Date()) {
                this.errorCodePromo = "Ce code promo a expiré."
                return
            }
            this.$store.commit('SET_CODE_PROMO', response)
            this.codePromo = ""
        }
    },
    mounted() {
        var date = new Date();
        date.setDate(date.getDate() + 15);
        this.expireDate = date;
        if (this.code_promo && this.code_promo.expirationDate <= new Date()) {
            this.$store.commit('SET_CODE_PROMO', null)
        }
        const sacBananes = this.panier.produits.find(e => e.type == 'sac-banane')
        const balleFouilles = this.panier.produits.find(e => e.type == 'balle-fouille')
        if (sacBananes || balleFouilles) {
            this.montant_livraison = 4.40
        } else {
            this.montant_livraison = 4.95
        }

    }
}
</script>
  
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.panier-details {
    padding:25px;
    width: 100%;
    background-color: $secondary-color;
}

.tag:not(body).is-primary {
    background-color: $primary-color;
}

.addCodePromo {
    font-weight: 550;
    color: $text-color;
    text-decoration: underline;
}

.errorCodePromo {
    margin-top:5px;
    font-size:14px;
    color:rgb(208, 1, 1);
}
</style>
  