<template>
    <div>
        <div class="container">
            <p class="titre-principal" >Politique de confidentialité</p>

            <p class="titre">Article 1 – Renseignements personnels recueillis</p>
            <p class="description">Les données à caractère personnel qui sont collectées sur ce site sont les suivantes :</p>
            <li>Lors de la création du compte de l'utilisateur : nom, prénom, adresse email</li>
            <li>Lors de la connexion de l'utilisateur au site web, celui-ci enregistre : nom, prénom, données de connexion</li>
            <li>Lors de la validation d'une commande : nom, prenom, adresse postale, adresse email, numero de téléphone, compte instagram</li>
            <li>Les cookies sont utilisés dans le cadre de l'utilisation du site. L'utilisateur a la possibilité de désactiver les cookies à partir des parametres de son navigateur.</li>

            <p class="titre">Article 2 – Consentement</p>
            <p class="description" style="font-weight: 550;">Comment obtenez-vous mon consentement ?</p>
            <p class="description">Lorsque vous nous fournissez vos renseignements personnels pour conclure une transaction, vérifier votre carte de crédit, passer une commande, planifier une livraison ou retourner un achat, nous présumons que vous consentez à ce que nous recueillions vos renseignements et à ce que nous les utilisions à cette fin uniquement.</p>
            <p class="description">Si nous vous demandons de nous fournir vos renseignements personnels pour une autre raison, à des fins de marketing par exemple, nous vous demanderons directement votre consentement explicite, ou nous vous donnerons la possibilité de refuser.</p>
            <p class="description" style="font-weight: 550;">Comment puis-je retirer mon consentement?</p>
            <p class="description">Si après nous avoir donné votre consentement, vous changez d’avis et ne consentez plus à ce que nous puissions vous contacter, recueillir vos renseignements ou les divulguer, vous pouvez nous en aviser en nous contactant à camelimelo.creation@gmail.com.</p>

            <p class="titre">Article 3 – Divulgation</p>
            <p class="description">Les données personnelles peuvent être partagées avec des sociétés tierces, dans les cas suivants :</p>
            <li>Lorsque l'utilisateur utilise les services de paiement, pour la mise en oeuvre de ces services, le site web est en relation avec des sociétes bancaires et financières tierces avec lesquelles elle a passé des contrats</li>
            <li>Si la loi l'exige, le site web peut effectuer la transmission de données pour donner suite aux réclamations presentées contre le site web et se conformément aux procédures administratives et judiciaires</li>
            <li>Si le site web est impliquée dans une opération de fusion, acquisition, cession d'actis ou procédure de redressement judiciaire, elle pourra être amenée à céder ou partager tout ou partie de ses actifs, y compris les données à caractère personnel. Dans ce cas, les utilisateurs seraient informés, avant que les données à caractère personnel ne soient transterées à une tierce partie.</li>
            
            <p class="titre">Article 4 – Age de consentement</p>
            <p class="description">En utilisant ce site, vous déclarez que vous avez au moins l’âge de la majorité dans votre État ou province de résidence, et que vous nous avez donné votre consentement pour permettre à toute personne d’âge mineur à votre charge d’utiliser ce site web.</p>

            <p class="titre">Article 5 – Modification apportées à la présente politique de confidentialité</p>
            <p class="description">Nous nous réservons le droit de modifier la présente politique de confidentialité à tout moment, donc veuillez s’il vous plait la consulter fréquemment. Les changements et les clarifications prendront effet immédiatement après leur publication sur le site web. Si nous apportons des changements au contenu de cette politique, nous vous aviserons ici qu’elle a été mise à jour, pour que vous sachiez quels renseignements nous recueillons, la manière dont nous les utilisons, et dans quelles circonstances nous les divulguons, s’il y a lieu de le faire.</p>
            <p>Si notre boutique fait l’objet d’une acquisition par ou d’une fusion avec une autre entreprise, vos renseignements pourraient être transférés aux nouveaux propriétaires pour que nous puissions continuer à vous vendre des produits.</p>
        </div>
    </div>
</template>

<script>

export default {
    name: 'ConfidentialiteView',
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.titre-principal {
    font-size:25px;
    color: $primary-color;
    text-align: center;
    font-weight:650;
}
.titre {
    font-size: 20px;
    color: $primary-color;
    font-weight:650;
    margin-top:30px;
    margin-bottom:10px;
}

.description {
    text-align:justify
}

.container {
    padding-top:50px;
    padding-bottom:50px
}

@media screen and (max-width: 1023px) {
    .container {
        margin-left:30px;
        margin-right:30px;
    }
}
</style>