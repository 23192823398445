<template>
    <div class="shop-button-mobile">
        <a v-on:click="goToPanier()" class="button-mobile-with-quantite" v-if="panier.quantite">
            <b-icon icon="cart"></b-icon>
            <span class="badge-mobile">({{ panier.quantite }})</span>
        </a>
        <a v-on:click="goToPanier()" class="button-mobile-without-quantite" v-else>
            <b-icon icon="cart-outline"></b-icon>
        </a>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'NavBar',
    computed: {
        ...mapState(['panier'])
    },
    methods: {
        goToPanier() {
            this.$router.push('/panier')
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/scss/variables.scss";

.button-mobile-with-quantite {
  background-color: white;
  color: #4a4a4a;
  text-decoration: none;
  padding: 10.5px 12px;
  position: relative;
  display: inline-block;
}


.button-mobile-without-quantite {
  background-color: white;
  color: #4a4a4a;
  text-decoration: none;
  padding-top: 10.5px;
  margin-right:-10px;
  position: relative;
  display: inline-block;
}

.badge-mobile {
  position: absolute;
  top: 8px;
  border-radius: 50%;
  font-size:18px;
  font-weight: 600;
  color: #4a4a4a;
}

.shop-button-mobile {
  a:hover {
    color: #4a4a4ae7 !important
  }

  .button-with-quantite:hover, .button-mobile-without-quantite:hover {
    background-color: white !important;
  }
}
</style>