<template>
    <div>
        <div class="columns is-multiline caracteristique">
            <div class="column is-12">
                <div class="caracteristique-titre">
                    Ensemble tissus :
                </div>
                <b-field :type="error && !tissuSelected ? 'is-danger' : ''">
                    <b-select v-model="tissuSelected" placeholder="Choisi un tissu" expanded>
                        <option></option>
                        <option v-for="tissu in tissus" :value="tissu" :key="tissu.id">{{tissu.name}}</option>
                    </b-select>
                </b-field>
            </div>
            <div class="column is-6">
                <div class="caracteristique-titre">
                    Sangle interchangeable :
                </div>
                <b-field style="padding-top:5px">
                    <b-switch
                        v-model="sangleInterchangeable"
                        type="is-terracota">
                    </b-switch>
                </b-field>
            </div>
            <div class="column is-6" v-if="!sangleInterchangeable || (sangleInterchangeable && !deuxiemeSangle)">
                <p class="caracteristique-titre">Couleur sangle : </p>
                <b-field>
                    <b-select v-model="couleurSangle" expanded>
                        <option value="exterieur">Tissu extérieur</option>
                        <option value="interieur">Tissu intérieur</option>
                    </b-select>
                </b-field>
            </div>
            <div class="column is-12" v-if="sangleInterchangeable">
                <div class="caracteristique-titre">
                    Deuxième sangle (+13 €) :
                    <a class="see-more">
                        (1 de chaque couleur)
                    </a>
                </div>
                <b-field style="padding-top:5px">
                    <b-switch
                        v-model="deuxiemeSangle"
                        type="is-terracota">
                    </b-switch>
                </b-field>
            </div>
            <div class="column is-7">
                <p class="caracteristique-titre">Quantité : </p>
                <b-field>
                    <b-select v-model="quantite">
                        <option :value=1>1</option>
                        <option :value=2>2</option>
                        <option :value=3>3</option>
                        <option :value=4>4</option>
                        <option :value=5>5</option>
                        <option :value=6>6</option>
                        <option :value=7>7</option>
                        <option :value=8>8</option>
                        <option :value=9>9</option>
                    </b-select>
                </b-field>
            </div>
        </div>
        <b-button type="is-primary" size="is-medium" @click="ajouterPanier()">Ajouter au panier</b-button>
    </div>
</template>

<script>
export default {
    name: 'SacBanane',
    data() {
        return {
            activeTab: 0,
            tissuSelected: "",
            texte: "",
            couleurTexte: "",
            quantite: 1,
            taille: 'sangle-interchangeable',
            error: false,
            sangleInterchangeable: true,
            tissus: [
                {
                    name: "Violine (1)",
                    id: "violine",
                },
                {
                    name: "Bleu (2)",
                    id: "bleu",
                },
                {
                    name: "Terracota (3)",
                    id: "terracota",
                },
                {
                    name: "Kaki (4)",
                    id: "kaki",
                },
                {
                    name: "Teddy (5)",
                    id: "teddy",
                },
                {
                    name: "Beige (6)",
                    id: "Beige",
                },
                {
                    name: "Noir (7)",
                    id: "Noir",
                },
            ],
            deuxiemeSangle: false,
            couleurSangle: "exterieur"
        }
    },
    props: ['type', 'prix'],
    methods: {
        goToInformation(value) {
            this.$emit('goToInformation', value)
        },
        updateActiveTab(valueFromChild) {
            if (this.activeTab != valueFromChild) {
                this.activeTab = valueFromChild
            }
        },
        ajouterPanier() {
            this.error = false
            if (!this.tissuSelected) {
                this.error = true;
                return
            }
            console.log(this.prix)
            let produit = {
                type: this.type,
                tissu: this.tissuSelected.id,
                tissuNom: this.tissuSelected.name,
                quantite: this.quantite,
                taille: this.taille,
                metadata: {
                    deuxiemeSangle: this.deuxiemeSangle,
                    sangleInterchangeable: this.sangleInterchangeable,
                    couleurSangle: this.couleurSangle
                },
                prix: this.deuxiemeSangle ? this.prix["double-sangle"] :  this.prix[this.taille],
            }

            this.$store.commit('ajouterPanier', produit)

            let message = 'Votre produit a été aujouté au panier'
            if (this.quantite > 1) {
                message = 'Vos produits ont été aujoutés au panier'
            }
            this.quantite = 1
            this.tissuSelected = ""
            this.texte = ""
            this.couleurTexte = ""
            this.$buefy.snackbar.open({
                duration: 5000,
                position: 'is-top',
                message: message,
                actionText: 'Voir mon panier',
                onAction: () => {
                    this.$router.push('/panier')
                }
            })

        }
    },
    watch: {
        type() {
            this.taille = 'sangle-interchangeable'
        },
        taille() {
            this.$emit('updatePrice', this.taille)
        },
        sangleInterchangeable() {
            if (this.sangleInterchangeable) {
                this.taille = 'sangle-interchangeable'
            } else {
                this.taille = 'sangle-integree'
                this.deuxiemeSangle = false
            }
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/scss/variables.scss";

.caracteristique {
    padding-top:20px;
}

.caracteristique-titre {
    margin-bottom:5px;
    font-weight: 650;
}

.see-more {
    color:$primary-color;
    font-weight: 400;
}

.notices .snackbar {
    background: $primary-color !important;
}

.notices .snackbar .action .button {
    color: $primary-color !important;
    font-weight: 600;
    text-transform: uppercase;
    background: white !important;
}
</style>
