<template>
    <div class="hero is-fullheight background-login">
        <div class="hero-body is-justify-content-center is-align-items-center" style="padding-top:0">            
            <div class="columns is-flex is-flex-direction-column is-multiline">
                <img @click="goToHome()" class="logo-login center-block" src="@/assets/img/logo/logo-terracota-detoure.webp" alt="logo">
                <div class="box">
                    <a @click="goToPrecedent()" class="link-connexion" style="margin-left:5px">
                        <span class="mdi mdi-chevron-left"></span>
                        <span> Retour</span>
                    </a>
                    <div class="column">
                        <div class="columns">
                            <div class="column is-6">
                                <b-field
                                    :type="error && !this.prenom ? 'is-danger' : ''"
                                    label="Prénom">
                                    <b-input
                                        placeholder="Prénom"
                                        v-model="prenom"
                                        type="prenom"
                                    ></b-input>
                                </b-field>
                            </div>
                            <div class="column is-6">
                                <b-field label="Nom"
                                :type="error && !this.nom ? 'is-danger' : ''"
                                >
                                    <b-input
                                        placeholder="Nom"
                                        v-model="nom"
                                        type="nom"
                                    ></b-input>
                                </b-field>
                            </div>
                        </div>
                        <b-field label="Email"
                        :type="error && !this.email ? 'is-danger' : ''"
                        >
                            <b-input
                                placeholder="Adresse email"
                                class="input-signin"
                                v-model="email"
                                type="email"
                            ></b-input>
                        </b-field>
                        <b-field label="Mot de passe" class="password"
                        :type="error && !this.password ? 'is-danger' : ''"
                        >
                            <b-input
                                placeholder="Mot de passe"
                                :class="errorValiditePassword ? 'input-signin errorPassword' : 'input-signin'"
                                v-model="password"
                                type="password"
                                password-reveal
                                @blur="checkValiditePassword()"
                            ></b-input>
                        </b-field>
                        <p class="text-error-password" v-if="errorValiditePassword">Le mot de passe doit contenir 8 caractères avec au moins une lettre en majuscule et un chiffre.</p>
                        <b-field label="Confirmer mot de passe" class="password"
                        :type="error && !this.confirm_password ? 'is-danger' : ''"
                        >
                            <b-input
                                placeholder="Mot de passe"
                                :class="errorPassword ? 'input-signin errorPassword' : 'input-signin'"
                                v-model="confirm_password"
                                type="password"
                                password-reveal
                                @blur="checkConfirmPassword()"
                            ></b-input>
                        </b-field>
                        <p class="text-error-password" v-if="errorPassword">Le mot de passe renseigné doit être identique à celui du dessus.</p>
                    </div>
                    <div class="column">
                        <b-button class="button is-primary is-fullwidth" type="submit" v-on:click="signin()">M'inscrire</b-button>
                        <p class="text-error has-text-centered" v-if="messageErreur">{{messageErreur}}</p>
                    </div>
                    <div class="has-text-centered">
                        <p class="is-size-7">
                            Déjà un compte ? <a v-on:click="goToLogin()" class="link-connexion">Me connecter</a>
                        </p>
                    </div>
                </div>               
            </div>
        </div>
    </div>
</template>

<script>
import {  signIn } from '@/services/UserService'
import { mapState } from 'vuex'

export default {
    name: 'SigninView',
    data() {
        return {
            email: "",
            password: "",
            confirm_password: "",
            errorPassword: false,
            errorValiditePassword: false,
            messageErreur: "",
            prevRoute: null,
            error: false,
            prenom: "",
            nom: ""
        }
    },
    methods: {
        async signin() {
            this.error = false;
            if (!this.password || !this.confirm_password || !this.email || !this.prenom || !this.nom) {
                this.error = true;
                return;
            }
            if (this.password != this.confirm_password) {
                this.errorPassword = true;
                return;
            }
            this.errorPassword = false;

            const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}/g;
            const found = this.password.match(regex);
            if (!found) {
                this.errorValiditePassword = true;
                return;
            }
            this.errorValiditePassword = false;
            this.messageErreur = ""
            try {
                const userCreate = await signIn({
                    email: this.email,
                    password: this.password,
                    prenom: this.prenom,
                    nom: this.nom,
                })
                this.$store.commit('SET_CURRENT_USER', {
                    id: userCreate.id,
                    email: userCreate.attributes.email,
                    prenom: userCreate.attributes.prenom,
                    nom: userCreate.attributes.nom,
                    role: 'User',
                    sessionToken: userCreate.attributes.sessionToken,
                })
                this.$buefy.toast.open({
                    message: 'Votre compte a été créé avec succès.',
                    type: 'is-success'
                })
                if (this.prevRoute.path == '/connexion' || this.prevRoute.path == '/inscription') {
                    this.$router.push('/')
                } else {
                    this.$router.push(this.prevRoute.path)
                }
            } catch (error) {
                if (error.message == "Cannot sign up user with an empty username.") {
                    this.messageErreur = "Email vide."
                } else if (error.message == "Cannot sign up user with an empty password.") {
                    this.messageErreur = "Mot de passe vide."
                } else if (error.message == "Email address format is invalid.") {
                    this.messageErreur = "Format de l'adresse email invalide."
                } else {
                    this.messageErreur = "Erreur interne, veuillez réessayer plus tard."
                }
            }
        },
        checkConfirmPassword() {
            if (this.password != this.confirm_password) {
                this.errorPassword = true;
            } else {
                this.errorPassword = false;
            }
        },
        checkValiditePassword() {
            const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}/g;
            const found = this.password.match(regex);
            if (!found) {
                this.errorValiditePassword = true;
            } else {
                this.errorValiditePassword = false;
            }
        },
        goToHome() {
            this.$router.push('/')
        },
        goToLogin() {
            this.$router.push('connexion')
        },
        goToPrecedent() {
            if (this.prevRoute.path == '/connexion' || this.prevRoute.path == '/inscription') {
                this.$router.push('/')
            } else {
                this.$router.push(this.prevRoute.path)
            }
        },
    },
    computed: {
        ...mapState(['currentUser'])
    },
    mounted() {
        if (this.currentUser) {
            this.$router.push('/')
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from
        })
    },
}
</script>

<style lang="scss">
@import "@/assets/scss/variables.scss";

.logo-login {
    width: 10rem !important;
    margin-bottom:50px;
    cursor:pointer;
}

.hero-body {
    padding-top:0
}

.label {
    font-weight: 400;
}

.link-connexion {
    color: $primary-color
}

.password {
    .mdi-24px.mdi-set, .mdi-24px.mdi:before {
        font-size: 24px;
        color: $primary-color !important;
    }
}

.input-signin {
    width: 600px;
}

@media screen and (max-width: 769px) {
    .input-signin {
        width: 300px;
    }

    .logo-login {
        margin-top:-100px;
    }
}

.errorPassword {
    input {
        border-color:#f14668!important;
        border-radius:5px;
        border: 0.07rem solid;
    }
}

.text-error-password {
    color:#f14668!important;
    font-size: 0.75rem;
    margin-top: -0.5rem;
    margin-bottom: 0.75rem
}

.text-error {
    margin-top:10px;
    color:#f14668!important;
}

.link-connexion:hover {
    color:$primary-color-hover
}
</style>