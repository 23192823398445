<template>
    <div>
        <div class="header">
            <presentation-header></presentation-header>
        </div>
        <div class="presentation-entreprise">
            <presentation-entreprise></presentation-entreprise>
        </div>
        <div class="presentation-produits">
            <presentation-produits></presentation-produits>
        </div>
        <div class="presentation-camille">
            <presentation-camille></presentation-camille>
        </div>
    </div>
</template>

<script>
import PresentationHeader from "@/components/page/home/PresentationHeader.vue"
import PresentationCamille from "@/components/page/home/PresentationCamille.vue"
import PresentationEntreprise from "@/components/page/home/PresentationEntreprise.vue"
import PresentationProduits from "@/components/page/home/PresentationProduits.vue"

export default {
    name: 'HomeView',
    components: {
        PresentationHeader,
        PresentationCamille,
        PresentationEntreprise,
        PresentationProduits
    },
    metaInfo: {
        title: 'Cameli Melo',
        meta: [
            {'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8'},
            {property: 'og:title', content: 'Cameli Melo'},
            {property: 'og:site_name', content: 'Cameli Melo'},
            {property: 'og:type', content: 'website'},
            {property: 'og:url', content: 'https://camelimelo.fr'},
            {property: 'og:image', content: 'https://camelimelo.fr/img/logo-terracota-detoure.c7cabfbd.png'},
            {property: 'og:description', content: 'Créer selon vos besoins et envies !'},
            {itemprop: 'name', content: 'Cameli Melo'},
            {itemprop: 'description', content: 'Créer selon vos besoins et envies !'},
            {itemprop: 'image', content: 'https://camelimelo.fr/img/logo-terracota-detoure.c7cabfbd.png'},
            {name: 'viewport', content: 'width=device-width, initial-scale=1'},
            {name: 'description', content: 'Créer selon vos besoins et envies !'}
        ]
    } 
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.presentation-entreprise {
    padding-top: 75px;
    padding-bottom:50px;
}

.presentation-produits {
    padding-top:50px;
    padding-bottom:50px;
    background-color: $secondary-color;
}

.presentation-camille {
    padding-top:50px;
    padding-bottom:50px;
}


@media screen and (max-width: 1023px) {
    .presentation-entreprise {
        padding-top: 50px;
    }
}
</style>
