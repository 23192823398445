var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.produit && _vm.produit.type)?_c('div',{staticClass:"product-page"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"return",on:{"click":function($event){return _vm.backToProductPage()}}},[_c('b-icon',{attrs:{"icon":"chevron-left","size":"is-small"}}),_vm._v(" Précédent ")],1),_c('div',{staticClass:"columns"},[(_vm.produit.images && _vm.produit.images.length)?_c('div',{staticClass:"column is-7"},[_c('product-images',{attrs:{"images":_vm.produit.images}})],1):_vm._e(),_c('div',{staticClass:"column is-5"},[_c('product-informations',{attrs:{"produit":_vm.produit,"taille":_vm.taille}}),(
                        (_vm.produit.type == 'bandana' ||
                        _vm.produit.type == 'bandana_prenom' ||
                        _vm.produit.type == 'bandana_texte' ||
                        _vm.produit.type == 'bandana-reversible' ||
                        _vm.produit.type == 'bandana-reversible-noel' ||
                        _vm.produit.type == 'bandana-noel' ||
                        _vm.produit.type == 'noeud' ||
                        _vm.produit.type == 'noeud-noel' ||
                        _vm.produit.type == 'chouchou') && !_vm.produit.afficher_inactif
                    )?_c('bandana',{attrs:{"type":_vm.produit.type,"prix":_vm.produit.prix,"tissus":_vm.produit.tissus},on:{"updatePrice":_vm.updatePrice,"goToInformation":_vm.goToInformation}}):_vm._e(),(
                        (_vm.produit.type == 'sac-banane') && !_vm.produit.afficher_inactif
                    )?_c('sac-banane',{attrs:{"type":_vm.produit.type,"prix":_vm.produit.prix},on:{"updatePrice":_vm.updatePrice,"goToInformation":_vm.goToInformation}}):((_vm.produit.type == 'collier') && !_vm.produit.afficher_inactif)?_c('collier',{attrs:{"type":_vm.produit.type,"prix":_vm.produit.prix,"tissus":_vm.produit.tissus},on:{"updatePrice":_vm.updatePrice,"goToInformation":_vm.goToInformation}}):((_vm.produit.type == 'balle-fouille') && !_vm.produit.afficher_inactif)?_c('fouille',{attrs:{"type":_vm.produit.type,"prix":_vm.produit.prix,"tissus":_vm.produit.tissus},on:{"updatePrice":_vm.updatePrice,"goToInformation":_vm.goToInformation}}):((_vm.produit.type == 'tote-bag') && !_vm.produit.afficher_inactif)?_c('tote-bag',{attrs:{"type":_vm.produit.type,"prix":_vm.produit.prix},on:{"updatePrice":_vm.updatePrice,"goToInformation":_vm.goToInformation}}):((_vm.produit.type == 'cotons') && !_vm.produit.afficher_inactif)?_c('cotons',{attrs:{"type":_vm.produit.type,"prix":_vm.produit.prix,"tissus":_vm.produit.tissus},on:{"updatePrice":_vm.updatePrice,"goToInformation":_vm.goToInformation}}):(
                        (_vm.produit.type == 'livret' ||
                        _vm.produit.type == 'carnet' ||
                        _vm.produit.type == 'passeport') && !_vm.produit.afficher_inactif
                    )?_c('housse',{attrs:{"type":_vm.produit.type,"prix":_vm.produit.prix,"tissus":_vm.produit.tissus},on:{"goToInformation":_vm.goToInformation}}):_c('unique',{attrs:{"type":_vm.produit.type,"prix":_vm.produit.prix},on:{"goToInformation":_vm.goToInformation}})],1)])]),_c('div',{staticClass:"product-more-details"},[_c('div',{staticClass:"container"},[_c('product-more-informations',{attrs:{"id":"information","activeTabFromParent":_vm.activeTab,"type":_vm.produit.type,"descriptions":_vm.produit.descriptions,"produit":_vm.produit,"tissus":_vm.produit.tissus},on:{"updateActiveTab":_vm.updateActiveTab}})],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }