<template>
    <footer class="footer">
        <b-modal
            style="z-index: 999;"
            v-model="openModalCookie"
            has-modal-card
            trap-focus
            :destroy-on-hide="false"
            aria-role="dialog"
            aria-label="Example Modal"
            close-button-aria-label="Close"
            aria-modal>
            <div class="modal-card" style="width: auto">
                <header class="modal-card-head">
                    <p class="modal-card-title">Modifier les préférences</p>
                    <button
                        type="button"
                        class="delete"
                        @click="openModalCookie = false"/>
                </header>
                <section class="modal-card-body">
                    <p>Ce site utilise des cookies.</p>
                    <p>Nous utilisons des cookies pour mémoriser vos préférences.</p>
                </section>
                <footer class="modal-card-foot" >
                    <div style="margin-left:auto;">
                        <b-button
                            label="Refuser les cookies"
                            @click="accepterCookie(false)"
                            type="is-terracota" inverted />
                        <b-button
                            label="Accepter les cookies"
                            type="is-primary"
                            @click="accepterCookie(true)"
                            />
                    </div>
                </footer>
            </div>
        </b-modal>
        <div class="content has-text-centered">
            <p>
                <a class="titre-footer" href="https://www.instagram.com/cameli_melo">
                    Cameli Melo
                    <b-icon icon="instagram" class="instagram-footer"></b-icon>
                </a>
            </p>
            <p class="link-footer-desktop">
                <a class="button-footer" @click="goTo('/condition-generale-de-vente')">CGV</a>
                <a class="button-footer" @click="goTo('/confidentialite')">Confidentialité</a>
                <a class="button-footer" @click="goTo('/mentions-legales')">Mentions légales</a>
                <a class="button-footer" @click="goTo('/retour-et-remboursement')">Retour & remboursement</a>
                <a class="button-footer" @click="openModalCookie = true">Cookies</a>
                <a class="button-footer" @click="goTo('/contact')">Me contacter</a>
            </p>
            <div class="link-footer-mobile">
                <p>
                    <a class="button-footer" @click="goTo('/condition-generale-de-vente')">CGV</a>
                    <a class="button-footer" @click="goTo('/confidentialite')">Confidentialité</a>
                    <a class="button-footer" @click="goTo('/mentions-legales')">Mentions légales</a>
                </p>
                <p>
                    <a class="button-footer" @click="goTo('/retour-et-remboursement')">Retour & remboursement</a>
                    <a class="button-footer" @click="openModalCookie = true">Cookies</a>
                </p>
                <p><a class="button-footer" @click="goTo('/contact')">Me contacter</a></p>
            </div>
        </div>
    </footer>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'FooterPage',
    methods: {
        goTo(dir) {
            this.$router.push(`${dir}`)
        },
        accepterCookie(value) {
            this.$store.commit('SET_COOKIE', value);
            this.openModalCookie = false;
        } 
    },
    computed: {
        ...mapState(['cookie'])
    },
    data() {
        return {
            openModalCookie: false,
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/scss/variables.scss";

.footer {
  background-color: $secondary-color;
  box-shadow: inset 0 0 0 1000px $secondary-color ;
}

.titre-footer {
    color:#5c5c5c !important;
    font-weight: 650;
}

.instagram-footer {
  color:#5c5c5c !important;
  cursor: pointer;
}

.button-footer {
    color: $primary-color;
    margin-right:15px;
    margin-left:15px;
    font-size:14px;
}

.button-footer:hover {
    color: $primary-color-hover
}

.link-footer-mobile {

    display: none;

    .button-footer {
        margin-right:10px;
        margin-left:10px;
    }

    p {
        margin-bottom: 2px !important;
    }
}

@media screen and (max-width:768px) {
    .link-footer-mobile {
        display:block;
    }

    .link-footer-desktop {
        display:none
    }
}
</style>