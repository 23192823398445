<template>
    <div>
        <div class="container container_confirmation" v-if="commande">
            <p class="description_principale has-text-weight-bold" style="margin-top:50px">Numéro de commande : <span class="primary-color">{{ commande.numero_commande }}</span></p>
            <p class="description_secondaire has-text-weight-semibold">Status de la commande : <span class="primary-color">{{ commande.status.charAt(0).toUpperCase() + commande.status.slice(1) }}</span></p>
            <hr/>
            <p class="has-text-weight-bold">Récapitulatif :</p>
            <div class="recap_commande">
                <div v-for="produit in commande.produits" :key="produit.key">
                    <div class="columns  is-vcentered is-mobile" >
                        <div class="column no-padding-bottom is-8" style="padding-left:30px">
                            <p class="product-title">
                                {{
                                    produit.type == "carnet" ? "Housse de carnet de santé pour animaux" :
                                    produit.type == "passeport" ? "Housse de passeport européen pour animaux" :
                                    produit.type == "livret" ? "Housse de livret de famille" :
                                    produit.type == "bandana" ? "Bandana" :
                                    produit.type == "collier" ? "Collier" :
                                    produit.type == "noeud" ? "Noeud" :
                                    produit.type == "noeud-noel" ? "Noeud de Noël" :
                                    produit.type == "bandana_prenom" ? "Bandana avec prénom" :
                                    produit.type == "bandana_texte" ? "Bandana avec texte" :
                                    produit.type == "bandana-reversible" ? "Bandana reversible" :
                                    produit.type == "bandana-reversible-noel" ? "Bandana reversible de Noël" :
                                    produit.type == "bandana-noel" ? "Bandana de Noël" :
                                    produit.type == "chouchou" ? "Chouchou" :
                                    produit.type == "tote-bag" ? "Tote bag" :
                                    produit.type == "balle-fouille" ? "Balle de fouille" :
                                    produit.type == "cotons" ? `Cotons lavables par ${ produit.taille == 'lot4' ? '4' : produit.taille == 'lot8' ? '8' : produit.taille == 'lot10' ? '10' : '' }` :
                                    produit.type == "sac-banane" && produit.metadata.sangleInterchangeable && produit.metadata.deuxiemeSangle ? "Sac banane avec 2 sangles" :
                                    produit.type == "sac-banane" && produit.metadata.sangleInterchangeable && !produit.metadata.deuxiemeSangle ? "Sac banane avec sangle interchangeable" :
                                    produit.type == "sac-banane" && !produit.metadata.sangleInterchangeable ? "Sac banane avec sangle intégrée" : ""
                                }}
                            </p>
                            <p v-if="
                                produit.taille &&
                                produit.type != 'cotons' &&
                                produit.type != 'sac-banane' &&
                                produit.type != 'tote-bag' &&
                                produit.type != 'balle-fouille' && 
                                produit.type != 'carnet' &&
                                produit.type != 'passeport' &&
                                produit.type != 'livret'
                            ">
                                <span class="product-details">Taille :</span> {{
                                    produit.taille == 'extra-small' ? 'XS' :
                                    produit.taille == 'small' ? 'S' :
                                    produit.taille == 'small-medium' ? 'S/M' :
                                    produit.taille == 'medium' ? 'M' :
                                    produit.taille == 'large' ? 'L' :
                                    produit.taille == 'extra-large' ? 'XL' : ''
                                }}
                            </p>
                            <p><span class="product-details">Quantité :</span> {{ produit.quantite }}</p>
                            <div v-if="produit.type != 'carnet' && produit.type != 'passeport' && produit.type != 'livret' && produit.type != 'sac-banane' && produit.type != 'tote-bag' && produit.type != 'balle-fouille' && produit.type != 'bandana-noel'">
                                <p><span class="product-details">Tissu :</span> {{produit.tissuNom}}</p>
                            </div>
                            <div v-else-if="produit.type == 'balle-fouille'">
                                <p><span class="product-details">Ensemble :</span> {{produit.tissuNom}}</p>
                            </div>
                            <div v-else-if="produit.type == 'bandana-noel'">
                                <p><span class="product-details">Personnalisation :</span> {{produit.tissuNom}}</p>
                            </div>
                            <div v-else-if="produit.type == 'tote-bag'">

                            </div>
                            <div v-else-if="produit.type == 'sac-banane'">
                                <p class="product-details-more"><span class="product-details">Tissus : </span>{{produit.tissuNom}}</p>
                                <p v-if="!produit.metadata.deuxiemeSangle" class="product-details-more">
                                    Sangle avec le même tissu que l'{{produit.metadata.couleurSangle}}
                                </p>
                                <p v-else-if="produit.metadata.deuxiemeSangle" class="product-details-more">
                                    <span>Sangle 1 avec le même tissu que l'extérieur</span><br/>
                                    <span>Sangle 2 avec le même tissu que l'intérieur</span>
                                </p>
                            </div>
                            <div v-else>
                                <p><span class="product-details">Tissu haut :</span> {{produit.tissuSecondaire}}</p>
                                <p><span class="product-details">Tissu bas :</span> {{produit.tissuNom}}</p>
                            </div>
                            <p v-if="produit.texte">
                                <span class="product-details">
                                    Texte personnalisé
                                    <span v-if="(produit.texte && produit.couleurTexte)">{{produit.couleurTexte}}</span>
                                    :
                                </span>
                                {{produit.texte}}
                            </p>
                        </div>
                        <div class="column is-4">
                            <p class="is-pulled-right has-text-weight-bold">{{ Number.parseFloat(produit.prix * produit.quantite).toFixed(2) }} €</p>
                        </div>
                    </div>
                </div>
                <p class="is-pulled-right has-text-weight-bold">Sous-total : {{ Number.parseFloat(commande.tarif).toFixed(2) }} €</p>
                <br/>
                <p class="is-pulled-right has-text-weight-bold" style="font-style:italic" v-if="commande.code_promo">{{commande.code_promo.id}} (-{{ commande.code_promo.percent }}%) : - {{ Number.parseFloat(commande.reduction).toFixed(2) }} €</p>
                <br/>
                <p class="is-pulled-right has-text-weight-bold">Expédition : {{ commande.livraison && !commande.mondial_relay ? '4.95 €' : commande.livraison && commande.mondial_relay ? '4.40 €' : 'GRATUIT' }}</p>
                <br/>
                <p v-if="!commande.code_promo" class="is-pulled-right has-text-weight-bold primary-color">Total : {{ Number.parseFloat(commande.tarif_avec_livraison).toFixed(2) }} €</p>
                <p v-else class="is-pulled-right has-text-weight-bold primary-color">Total : {{ Number.parseFloat(commande.tarif_avec_livraison - commande.reduction).toFixed(2) }} €</p>    
            </div>
            <hr/>
            <div class="information_livraison columns">
                <div class="column is-6">
                    <div v-if="commande.livraison && !commande.mondial_relay">
                        <p class="description_confirmation has-text-weight-bold">Adresse de livraison :</p>
                        <p>{{ commande.prenom }} {{ commande.nom }}</p>
                        <p>{{ commande.adresse }} <span v-if="commande.complement">{{ commande.complement }}</span></p>
                        <p>{{ commande.code_postal }} - {{ commande.ville.toUpperCase() }}</p>
                        <p>France</p>
                    </div>
                    <div v-else-if="commande.livraison && commande.mondial_relay">
                        <p class="description_confirmation has-text-weight-bold">Retrait en point relais :</p>
                        <p>{{ commande.mondial_relay.Nom }} - {{ commande.mondial_relay.Ville }}</p>
                        <p>Au nom de : {{ commande.prenom }} {{ commande.nom }}</p>
                    </div>
                    <div v-else>
                        <p class="description_confirmation has-text-weight-bold">Retrait en main propre au :</p>
                        <p>2 rue alexandre dumas</p>
                        <p>59155 - FACHES THUMESNIL</p>
                        <p>FRANCE</p>
                    </div>
                </div>
                <hr/>
                <div class="column is-6">
                    <p class="description_confirmation has-text-weight-bold">Information de facturation :</p>
                    <p style="margin-bottom:10px;">Réglement par carte bancaire</p>
                    <p>{{ commande.prenom }} {{ commande.nom }}</p>
                    <p>{{ commande.adresse }} <span v-if="commande.complement">{{ commande.complement }}</span></p>
                    <p>{{ commande.code_postal }} - {{ commande.ville.toUpperCase() }}</p>
                    <p>France</p>
                    <p>{{ commande.telephone }}</p>
                    <p v-if="commande.instagram">{{ commande.instagram }}</p>
                </div>
            </div>
            <hr/>
            <p>Besoin d'aide ? Contactez-moi !</p>
            <li>Instagram : <a class="primary-color" href="https://www.instagram.com/cameli_melo/">@cameli_melo</a></li>
            <li>Email : <span class="primary-color">camelimelo.creation@gmail.com</span></li>
        </div>
    </div>
</template>
  
<script>
import {  getOrderById } from '@/services/UserService'
import { Parse } from 'parse/lib/browser/Parse';

export default {
    name: 'CommandeDetailsView',
    data() {
        return {
            commande: null
        }
    },
    async mounted() {
        if (!Parse.User.current()) {
            this.$router.push(`/`)
            return;
        }
        this.commande = await getOrderById(this.$route.params.id)
    }
}
</script>
  
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

$bold-light: 700;

.container {
    padding-left:15%;
    padding-right:15%;
}

.description_confirmation {
    margin-bottom:10px;
    text-align:justify
}

.recap_commande {
    background-color:$secondary-color;
    padding:15px;
    border-radius:10px;
    margin-top:25px;
    padding-bottom:50px;
}

hr {
    margin-top:25px;
    margin-bottom:25px;
    color: $primary-color;
    background-color: $primary-color;
}

.description_principale {
    font-size:18px
}

.description_secondaire {
    font-size:15px
}

.container_confirmation {
    margin-bottom:50px;
}

.primary-color {
    color: $primary-color;
}

.product-title {
    font-size:18px;
    font-weight: $bold-light;
    margin-bottom:5px;
}
</style>
