<template>
    <div class="container">
        <div class="columns is-multiline">
            <div class="column is-6">
                <p class="presentation-title">Cameli Melo, c’est quoi ? C’est avant tout du plaisir !</p>
                <p class="presentation-description">Suite à de nombreuses demandes, j’ai décidé de franchir le pas en créant mon site internet afin de pouvoir commercialiser mes petites confections. </p>
                <p class="presentation-description">Les articles sont confectionnés à la commande. Vous pouvez les personnaliser entièrement en choisissant les tissus et les options supplémentaires.</p>
                <p class="presentation-description">Tous les produits sur le site sont donc en édition limitée. En fonction des saisons, des événements, de vos envies, l’approvisionnement de mes tissus peut varier. </p>
                <p class="presentation-description">Ma devise, créer selon vos besoins et envies !</p>
            </div>
            <div class="column is-6 container-image">
                <img class="center-block presentation-image" src="@/assets/img/transforme/presentation-machine.webp" alt="présentation de Cameli Melo">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PresentationEntreprise'
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.presentation-title {
    font-size:$text-title-size;
    font-weight: 600;
    margin-bottom: 25px;
}

.presentation-description {
    margin-bottom: 20px;
    text-align: justify;
}

.presentation-image {
    max-width: 30rem;
}

.container-image {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and  (max-width: 1023px) {
    .container {
        margin-left: 30px;
        margin-right: 30px;
    }
}

@media screen and (max-width: 955px) {
    .presentation-image {
        max-width: 23rem;
    }
}

@media screen and (max-width: 768px) {
    .presentation-title {
        font-size:$text-title-mobile-size;
    }

    .presentation-image {
        max-width: 18rem;
    }
}
</style>
