import Vue from 'vue'
import Vuex from 'vuex'
import {v4 as uuidv4} from 'uuid';
import { getCategories, getProduits } from '../services/ProduitService'

Vue.use(Vuex)

const initPanier = {
  produits: [],
  quantite: 0,
  tarif: 0,
  fraisLivraison: 0
}

export default new Vuex.Store({
  state: {
    codes_promo: [{
      id: "ANNIV10",
      value: "iY20yIji",
      percent: 10,
      expirationDate: new Date("05/02/2023")
    },
    {
      id: "CABOT10",
      value: "8nsnsF6J",
      percent: 10,
      expirationDate: new Date("09/25/2023")
    }],
    reduction: 0,
    code_promo: null,
    cookie: null,
    currentUser: null,
    panier: Object.assign(initPanier),
    categories: [],
    produits: [],
    products: {
        carnet: {
          image1:"carnet/carnet-naya.webp",
          image2:"carnet/carnet-de-sante-nymeria.webp",
          imgs: [
            "carnet/carnet-naya.webp",
            "carnet/love-carnets.webp",
            "carnet/carnet-bordeau.webp",
            "carnet/carnet-locky.webp",
            "carnet/carnet-taiga.webp",
            "carnet/carnet-de-sante-nymeria.webp",
            "carnet/carnet-de-sante-plume.webp",
            "carnet/carnet-de-sante-pixelle.webp",
            "carnet/carnet-de-sante-snowki.webp",
            "carnet/carnet-de-sante-stella.webp",
          ],
          descriptions: [
            "Le carnet de santé est l'élément indispensable à garder à portée de main tout au long de la vie de votre animal de compagnie. ",
            "Pour le protéger, je vous propose une housse élégante et personnalisée qui fera son petit effet à chaque fois que vous la sortirez du sac.",
            "Rendez votre protège carnet unique en choisissant deux tissus, la couleur du liseré, et la couleur du flex thermocollant pour l’inscription du prénom.",
            "Si vous hésitez sur le choix du liseré et de la couleur du flex, faites-moi confiance !",
            "Compatible au format classique des carnets de santé : 21 x 15 cm (si autre taille, contactez moi par mail ou sur instagram)."
          ],
          couleurs: [
            {
              name: "Blanc pois dorés",
              img: "blanc-pois-dores.webp",
              id: "blanc-pois-dores"
            },
            {
              name: "Bleu ciel pois dorés",
              img: "bleu-ciel-pois-dores.webp",
              id: "bleu-ciel-pois-dores"
            },
            {
              name: "Bleu indigo pois dorés",
              img: "bleu-indigo-pois-dores.webp",
              id: "bleu-indigo-pois-dores"
            },
            {
              name: "Bois de rose pois dorés",
              img: "bois-de-rose-pois-dores.webp",
              id: "bois-de-rose-pois-dores"
            },
            {
              name: "Bordeau pois dorés",
              id: 'bordeau-pois-dores',
              img: "bordeau-pois-dores.webp"
            },
            {
              name: "Corail pois dorés",
              img: "corail-pois-dores.webp",
              id: "corail-pois-dores"
            },
            {
              name: "Rose pâle pois dorés",
              img: "rose-pale-pois-dores.webp",
              id: "rose-pale-pois-dores"
            },
            {
              name: "Terracota pois dorés",
              img: "terracota-pois-dores.webp",
              id: "terracota-pois-dores"
            },
            {
              name: "Vert menthe pois dorés",
              img: "vert-menthe-pois-dores.webp",
              id: "vert-menthe-pois-dores"
            },
            {
              name: "Vert sauge pois dorés",
              img: "vert-sauge-pois-dores.webp",
              id: "vert-sauge-pois-dores"
            },
            {
              name: "Velour bleu ciel",
              img: "velour-bleu-ciel.webp",
              id: "velour-bleu-ciel"
            },
            {
              name: "Velour cappuccino",
              img: "velour-cappuccino.webp",
              id: "velour-cappuccino"
            },
            {
              name: "Velour rose",
              img: "velour-rose.webp",
              id: "velour-rose"
            },
            {
              name: "Velour rouge",
              img: "velour-rouge.webp",
              id: "velour-rouge"
            },
            {
              name: "Velour vert forêt",
              img: "velour-vert-foret.webp",
              id: "velour-vert-foret"
            },
            {
              name: "Velour vieux rose",
              img: "velour-vieux-rose.webp",
              id: "velour-vieux-rose"
            },
            {
              name: "Eventails girly",
              img: "eventails-girly.webp",
              id: "eventails-girly"
            },
            {
              name: "Eventails bleu",
              img: "eventails-bleu.webp",
              id: "eventails-bleu"
            },
            {
              name: "Eventails noir",
              img: "eventails-noir.webp",
              id: "eventails-noir"
            },
            {
              name: "Feuillage bleu turquoise",
              img: "feuillage-bleu-turquoise.webp",
              id: "feuillage-bleu-turquoise"
            },
            {
              name: "Cowboy rouge",
              img: "cowboy-rouge.webp",
              id: "cowboy-rouge"
            },
            {
              name: "Rose pâquerettes",
              img: "rose-paquerettes.webp",
              id: "rose-paquerettes"
            },
            {
              name: "Léopard",
              img: "leopard.webp",
              id: "leopard"
            },
          ],
          type:"carnet",
          title:"Housse pour carnet de santé pour animaux",
          price:{
            normal: 24,
            avec_noeud: 25
          },
          poids: 50,
          id_stripe: {
            normal: 'price_1MXRczA8BrUF7Csj0b55sz4d',
            avec_noeud: 'price_1MXRcvA8BrUF7CsjaOLHPgn6'
          }
        },
        passeport: {
          id_stripe: {
            normal: 'price_1MXRcsA8BrUF7CsjrVYKAeQG',
            avec_noeud: 'price_1MXRcpA8BrUF7CsjoO6b9S17'
          },
          image1:"passeport/passeport.webp",
          image2:"passeport/passeport-atchi.webp",
          imgs: [
            "passeport/passeport.webp",
            "passeport/passeport-atchi.webp"
          ],
          descriptions: [
            "Vous avez l’esprit voyageur et aimez vagabonder avec notre animal de compagnie ? Vous disposez forcément d’un passeport européen pour animaux de compagnie.",
            "Pour le protéger, je vous propose une housse élégante et personnalisée qui fera son petit effet à chacune de vos excursions et à chaque passage chez le vétérinaire.",
            "La housse est conçue et adaptée aux mesures du passeport pour animaux de compagnie (15 x 10 cm, si autre taille contactez moi par mail ou instagram).",
            "Si vous hésitez sur le choix du liseré et de la couleur du flex, faites-moi confiance !"
          ],
          couleurs: [
            {
              name: "Blanc pois dorés",
              img: "blanc-pois-dores.webp",
              id: "blanc-pois-dores"
            },
            {
              name: "Bleu ciel pois dorés",
              img: "bleu-ciel-pois-dores.webp",
              id: "bleu-ciel-pois-dores"
            },
            {
              name: "Bleu indigo pois dorés",
              img: "bleu-indigo-pois-dores.webp",
              id: "bleu-indigo-pois-dores"
            },
            {
              name: "Bois de rose pois dorés",
              img: "bois-de-rose-pois-dores.webp",
              id: "bois-de-rose-pois-dores"
            },
            {
              name: "Bordeau pois dorés",
              id: 'bordeau-pois-dores',
              img: "bordeau-pois-dores.webp"
            },
            {
              name: "Corail pois dorés",
              img: "corail-pois-dores.webp",
              id: "corail-pois-dores"
            },
            {
              name: "Rose pâle pois dorés",
              img: "rose-pale-pois-dores.webp",
              id: "rose-pale-pois-dores"
            },
            {
              name: "Terracota pois dorés",
              img: "terracota-pois-dores.webp",
              id: "terracota-pois-dores"
            },
            {
              name: "Vert menthe pois dorés",
              img: "vert-menthe-pois-dores.webp",
              id: "vert-menthe-pois-dores"
            },
            {
              name: "Vert sauge pois dorés",
              img: "vert-sauge-pois-dores.webp",
              id: "vert-sauge-pois-dores"
            },
            {
              name: "Velour bleu ciel",
              img: "velour-bleu-ciel.webp",
              id: "velour-bleu-ciel"
            },
            {
              name: "Velour cappuccino",
              img: "velour-cappuccino.webp",
              id: "velour-cappuccino"
            },
            {
              name: "Velour rose",
              img: "velour-rose.webp",
              id: "velour-rose"
            },
            {
              name: "Velour rouge",
              img: "velour-rouge.webp",
              id: "velour-rouge"
            },
            {
              name: "Velour vert forêt",
              img: "velour-vert-foret.webp",
              id: "velour-vert-foret"
            },
            {
              name: "Velour vieux rose",
              img: "velour-vieux-rose.webp",
              id: "velour-vieux-rose"
            },
            {
              name: "Eventails girly",
              img: "eventails-girly.webp",
              id: "eventails-girly"
            },

            {
              name: "Eventails bleu",
              img: "eventails-bleu.webp",
              id: "eventails-bleu"
            },
            {
              name: "Eventails noir",
              img: "eventails-noir.webp",
              id: "eventails-noir"
            },
            {
              name: "Feuillage bleu turquoise",
              img: "feuillage-bleu-turquoise.webp",
              id: "feuillage-bleu-turquoise"
            },
            {
              name: "Cowboy rouge",
              img: "cowboy-rouge.webp",
              id: "cowboy-rouge"
            },
            {
              name: "Rose pâquerettes",
              img: "rose-paquerettes.webp",
              id: "rose-paquerettes"
            },
            {
              name: "Léopard",
              img: "leopard.webp",
              id: "leopard"
            },
          ],
          type:"passeport",
          title:"Housse pour passeport européen pour animaux",
          price:{
            normal: 17.5,
            avec_noeud: 18.5
          },
          poids: 40
        },
        livret: {
          image1:"livret/livret-1.webp",
          image2:"livret/livret-1.webp",
          imgs: [
            "livret/livret-1.webp",
          ],
          descriptions: [
            "Le livret de famille est lié aux moments clés de la vie, mais surtout retrace l’histoire de VOTRE famille (naissance, mariage, etc...).",
            "Pour le conserver précieusement et le protéger, je vous propose une housse élégante et personnalisée.",
            "Cette housse peut être une idée de cadeau originale pour de jeunes mariés. N’hésitez pas à me contacter pour la personnaliser.",
            "Rendez votre protège carnet unique en choisissant deux tissus, la couleur du liseré, et la couleur du flex thermocollant pour l’inscription du prénom.",
            "Si vous hésitez sur le choix du liseré et de la couleur du flex, faites-moi confiance !"
          ],
          couleurs: [
            {
              name: "Blanc pois dorés",
              img: "blanc-pois-dores.webp",
              id: "blanc-pois-dores"
            },
            {
              name: "Bleu ciel pois dorés",
              img: "bleu-ciel-pois-dores.webp",
              id: "bleu-ciel-pois-dores"
            },
            {
              name: "Bleu indigo pois dorés",
              img: "bleu-indigo-pois-dores.webp",
              id: "bleu-indigo-pois-dores"
            },
            {
              name: "Bois de rose pois dorés",
              img: "bois-de-rose-pois-dores.webp",
              id: "bois-de-rose-pois-dores"
            },
            {
              name: "Bordeau pois dorés",
              id: 'bordeau-pois-dores',
              img: "bordeau-pois-dores.webp"
            },
            {
              name: "Corail pois dorés",
              img: "corail-pois-dores.webp",
              id: "corail-pois-dores"
            },
            {
              name: "Rose pâle pois dorés",
              img: "rose-pale-pois-dores.webp",
              id: "rose-pale-pois-dores"
            },
            {
              name: "Terracota pois dorés",
              img: "terracota-pois-dores.webp",
              id: "terracota-pois-dores"
            },
            {
              name: "Vert menthe pois dorés",
              img: "vert-menthe-pois-dores.webp",
              id: "vert-menthe-pois-dores"
            },
            {
              name: "Vert sauge pois dorés",
              img: "vert-sauge-pois-dores.webp",
              id: "vert-sauge-pois-dores"
            },
            {
              name: "Velour bleu ciel",
              img: "velour-bleu-ciel.webp",
              id: "velour-bleu-ciel"
            },
            {
              name: "Velour cappuccino",
              img: "velour-cappuccino.webp",
              id: "velour-cappuccino"
            },
            {
              name: "Velour rose",
              img: "velour-rose.webp",
              id: "velour-rose"
            },
            {
              name: "Velour rouge",
              img: "velour-rouge.webp",
              id: "velour-rouge"
            },
            {
              name: "Velour vert forêt",
              img: "velour-vert-foret.webp",
              id: "velour-vert-foret"
            },
            {
              name: "Velour vieux rose",
              img: "velour-vieux-rose.webp",
              id: "velour-vieux-rose"
            },
            {
              name: "Eventails girly",
              img: "eventails-girly.webp",
              id: "eventails-girly"
            },
            {
              name: "Eventails bleu",
              img: "eventails-bleu.webp",
              id: "eventails-bleu"
            },
            {
              name: "Eventails noir",
              img: "eventails-noir.webp",
              id: "eventails-noir"
            },
            {
              name: "Feuillage bleu turquoise",
              img: "feuillage-bleu-turquoise.webp",
              id: "feuillage-bleu-turquoise"
            },
            {
              name: "Cowboy rouge",
              img: "cowboy-rouge.webp",
              id: "cowboy-rouge"
            },
            {
              name: "Rose pâquerettes",
              img: "rose-paquerettes.webp",
              id: "rose-paquerettes"
            },
            {
              name: "Léopard",
              img: "leopard.webp",
              id: "leopard"
            },
          ],
          type:"livret",
          title:"Housse pour livret de famille",
          price:{
            normal: 20,
            avec_noeud: 21
          },
          poids: 50,
          id_stripe: {
            normal: 'price_1MprxJA8BrUF7CsjxYQvbAYv',
            avec_noeud: 'price_1MptGRA8BrUF7Csj4lfyG6Sf'
          }
        },
        noeud: {
          id_stripe: { 
            'extra-small': 'price_1MXRdOA8BrUF7CsjeUyGgJKS',
            'small': 'price_1MXRdKA8BrUF7CsjdVwX7MOV',
            'medium': 'price_1MXRdGA8BrUF7CsjcCY2QWPv',
            'large': 'price_1MXRdBA8BrUF7CsjHS55qlOR',
            'extra-large': 'price_1MXRd6A8BrUF7CsjEvy9gPgu'
          },
          image1:"noeud/noeud-2.webp",
          image2:"noeud/noeud-terracota.webp",
          imgs: [
            "noeud/noeud-2.webp",
            "noeud/noeud.webp",
            "noeud/noeud-terracota.webp",
          ],
          descriptions: [
            "Envie de sublimer votre chien lors d’un évènement (anniversaire, mariage, soirée entre amis) ou tout simplement au quotidien ? N’hésitez plus et optez pour le noeud papillon ! Au delà d’être chic, c’est l’élément idéal pour habiller votre animal sans le gêner.",
            "Glissez le noeud papillon sur le collier de votre animal grâce à l’attache pression, et hop le tour est joué. Rien de plus simple pour être le plus beau en soirée !",
            "Ce produit est disponible en 5 tailles, dans des motifs et couleurs variés.",
            "Vous êtes une dogmum ou une dogcat, vous souhaitez être assorti à votre animal. Optez  pour l’option chouchou."
          ],
          couleurs: [
            {
              name: "Blanc pois dorés",
              img: "blanc-pois-dores.webp",
              id: "blanc-pois-dores"
            },
            {
              name: "Bleu ciel pois dorés",
              img: "bleu-ciel-pois-dores.webp",
              id: "bleu-ciel-pois-dores"
            },
            {
              name: "Bleu indigo pois dorés",
              img: "bleu-indigo-pois-dores.webp",
              id: "bleu-indigo-pois-dores"
            },
            {
              name: "Bois de rose pois dorés",
              img: "bois-de-rose-pois-dores.webp",
              id: "bois-de-rose-pois-dores"
            },
            {
              name: "Bordeau pois dorés",
              id: 'bordeau-pois-dores',
              img: "bordeau-pois-dores.webp"
            },
            {
              name: "Corail pois dorés",
              img: "corail-pois-dores.webp",
              id: "corail-pois-dores"
            },
            {
              name: "Rose pâle pois dorés",
              img: "rose-pale-pois-dores.webp",
              id: "rose-pale-pois-dores"
            },
            {
              name: "Terracota pois dorés",
              img: "terracota-pois-dores.webp",
              id: "terracota-pois-dores"
            },
            {
              name: "Vert menthe pois dorés",
              img: "vert-menthe-pois-dores.webp",
              id: "vert-menthe-pois-dores"
            },
            {
              name: "Vert sauge pois dorés",
              img: "vert-sauge-pois-dores.webp",
              id: "vert-sauge-pois-dores"
            },
            {
              name: "Velour bleu ciel",
              img: "velour-bleu-ciel.webp",
              id: "velour-bleu-ciel"
            },
            {
              name: "Velour cappuccino",
              img: "velour-cappuccino.webp",
              id: "velour-cappuccino"
            },
            {
              name: "Velour rose",
              img: "velour-rose.webp",
              id: "velour-rose"
            },
            {
              name: "Velour rouge",
              img: "velour-rouge.webp",
              id: "velour-rouge"
            },
            {
              name: "Velour vert forêt",
              img: "velour-vert-foret.webp",
              id: "velour-vert-foret"
            },
            {
              name: "Velour vieux rose",
              img: "velour-vieux-rose.webp",
              id: "velour-vieux-rose"
            },
            {
              name: "Eventails girly",
              img: "eventails-girly.webp",
              id: "eventails-girly"
            },
            {
              name: "Eventails bleu",
              img: "eventails-bleu.webp",
              id: "eventails-bleu"
            },
            {
              name: "Eventails noir",
              img: "eventails-noir.webp",
              id: "eventails-noir"
            },
            {
              name: "Feuillage bleu turquoise",
              img: "feuillage-bleu-turquoise.webp",
              id: "feuillage-bleu-turquoise"
            },
            {
              name: "Cowboy rouge",
              img: "cowboy-rouge.webp",
              id: "cowboy-rouge"
            },
            {
              name: "Rose pâquerettes",
              img: "rose-paquerettes.webp",
              id: "rose-paquerettes"
            },
            {
              name: "Léopard",
              img: "leopard.webp",
              id: "leopard"
            },
          ],
          type:"noeud",
          title:"Noeud papillon",
          price:{
            'extra-small': 11,
            'small': 11.5,
            'medium': 12,
            'large': 12.5,
            'extra-large': 13
          },
          poids: 30
        },
        bandana: {
          id_stripe: { 
            'extra-small': 'price_1MXReDA8BrUF7CsjgsU5K6Le',
            'small': 'price_1MXReAA8BrUF7Csjkzw3Sagw',
            'medium': 'price_1MXRe7A8BrUF7CsjIP1lpcB3',
            'large': 'price_1MXRe4A8BrUF7Csj1BfRCjxO',
            'extra-large': 'price_1MXReUA8BrUF7CsjGM1dy6Ea'
          },
          image1:"bandana/bandana-plume.webp",
          image2:"bandana/bandana-cowboy.webp",
          imgs: [
            "bandana/bandana-plume.webp",
            "bandana/bandana-cowboy.webp",
            "bandana/bandana-bleu-fleur.webp",
            "bandana/bandana-chien.webp",
            "bandana/bandana-paquerettes.webp"
          ],
          descriptions: [
            "Accessoire tendance, le bandana ne manquera pas de sublimer le style de votre chien en balade ou lors d’un événement. C’est l’élément idéal pour habiller votre animal simplement, sans le gêner, mais tout en sortant du lot.",
            "Glissez le bandana sur le collier de votre animal, et hop le tour est joué. Rien de plus simple !",
            "Ce produit est disponible en 5 tailles. L’idéal est que la longueur du bandana fasse la moitié du tour de cou de votre animal. Pour sélectionner votre taille, référez-vous au guide des tailles.",
            "Vous êtes une dogmum ou une dogcat, vous souhaitez être assorti à votre animal. Optez pour l’option chouchou."
          ],
          couleurs: [
            {
              name: "Blanc pois dorés",
              img: "blanc-pois-dores.webp",
              id: "blanc-pois-dores"
            },
            {
              name: "Bleu ciel pois dorés",
              img: "bleu-ciel-pois-dores.webp",
              id: "bleu-ciel-pois-dores"
            },
            {
              name: "Bleu indigo pois dorés",
              img: "bleu-indigo-pois-dores.webp",
              id: "bleu-indigo-pois-dores"
            },
            {
              name: "Bois de rose pois dorés",
              img: "bois-de-rose-pois-dores.webp",
              id: "bois-de-rose-pois-dores"
            },
            {
              name: "Bordeau pois dorés",
              id: 'bordeau-pois-dores',
              img: "bordeau-pois-dores.webp"
            },
            {
              name: "Corail pois dorés",
              img: "corail-pois-dores.webp",
              id: "corail-pois-dores"
            },
            {
              name: "Rose pâle pois dorés",
              img: "rose-pale-pois-dores.webp",
              id: "rose-pale-pois-dores"
            },
            {
              name: "Terracota pois dorés",
              img: "terracota-pois-dores.webp",
              id: "terracota-pois-dores"
            },
            {
              name: "Vert menthe pois dorés",
              img: "vert-menthe-pois-dores.webp",
              id: "vert-menthe-pois-dores"
            },
            {
              name: "Vert sauge pois dorés",
              img: "vert-sauge-pois-dores.webp",
              id: "vert-sauge-pois-dores"
            },
            {
              name: "Velour bleu ciel",
              img: "velour-bleu-ciel.webp",
              id: "velour-bleu-ciel"
            },
            {
              name: "Velour cappuccino",
              img: "velour-cappuccino.webp",
              id: "velour-cappuccino"
            },
            {
              name: "Velour rose",
              img: "velour-rose.webp",
              id: "velour-rose"
            },
            {
              name: "Velour rouge",
              img: "velour-rouge.webp",
              id: "velour-rouge"
            },
            {
              name: "Velour vert forêt",
              img: "velour-vert-foret.webp",
              id: "velour-vert-foret"
            },
            {
              name: "Velour vieux rose",
              img: "velour-vieux-rose.webp",
              id: "velour-vieux-rose"
            },
            {
              name: "Eventails girly",
              img: "eventails-girly.webp",
              id: "eventails-girly"
            },
            {
              name: "Eventails bleu",
              img: "eventails-bleu.webp",
              id: "eventails-bleu"
            },
            {
              name: "Eventails noir",
              img: "eventails-noir.webp",
              id: "eventails-noir"
            },
            {
              name: "Feuillage bleu turquoise",
              img: "feuillage-bleu-turquoise.webp",
              id: "feuillage-bleu-turquoise"
            },
            {
              name: "Cowboy rouge",
              img: "cowboy-rouge.webp",
              id: "cowboy-rouge"
            },
            {
              name: "Rose pâquerettes",
              img: "rose-paquerettes.webp",
              id: "rose-paquerettes"
            },
            {
              name: "Léopard",
              img: "leopard.webp",
              id: "leopard"
            },
          ],
          type:"bandana",
          title:"Bandana",
          price:{ 
            'extra-small': 6,
            'small': 7,
            'medium': 8,
            'large': 9,
            'extra-large': 10
          },
          poids: 30
        },
        bandana_prenom: {
          id_stripe: { 
            'extra-small': 'price_1MXRdxA8BrUF7CsjwPlKPdxU',
            'small': 'price_1MXRduA8BrUF7CsjU3HLNufn',
            'medium': 'price_1MXRdmA8BrUF7CsjbNnNXNx7',
            'large': 'price_1MXRdhA8BrUF7CsjC5rPRK7S',
            'extra-large': 'price_1MXRdRA8BrUF7CsjH66dDmhl'
          },
          image1:"bandana/bandana-chouchou-stella.webp",
          image2:"bandana/bandana-stella.webp",
          imgs: [
            "bandana/bandana-chouchou-stella.webp",
            "bandana/bandana-stella.webp",
            "bandana/stella-bandana.webp"

          ],
          descriptions: [
            "Accessoire tendance, le bandana ne manquera pas de sublimer le style de votre chien en balade ou lors d’un événement. C’est l’élément idéal pour habiller votre animal simplement, sans le gêner, mais tout en sortant du lot.",
            "Je vous propose de personnaliser votre bandana avec le prénom de votre animal. Choisissez la couleur du flex thermocollant pour personnaliser votre prénom.",
            "Glissez le bandana sur le collier de votre animal, et hop le tour est joué. Rien de plus simple !",
            "Ce produit est disponible en 5 tailles. L’idéal est que la longueur du bandana fasse la moitié du tour de cou de votre animal. Pour sélectionner votre taille, référez-vous au guide des tailles.",
            "Vous êtes une dogmum ou une dogcat, vous souhaitez être assorti à votre animal. Optez pour l’option chouchou."
          ],
          couleurs: [
            {
              name: "Velour bleu ciel",
              img: "velour-bleu-ciel.webp",
              id: "velour-bleu-ciel"
            },
            {
              name: "Velour cappuccino",
              img: "velour-cappuccino.webp",
              id: "velour-cappuccino"
            },
            {
              name: "Velour rose",
              img: "velour-rose.webp",
              id: "velour-rose"
            },
            {
              name: "Velour rouge",
              img: "velour-rouge.webp",
              id: "velour-rouge"
            },
            {
              name: "Velour vert forêt",
              img: "velour-vert-foret.webp",
              id: "velour-vert-foret"
            },
            {
              name: "Velour vieux rose",
              img: "velour-vieux-rose.webp",
              id: "velour-vieux-rose"
            },
            {
              name: "Eventails girly",
              img: "eventails-girly.webp",
              id: "eventails-girly"
            },
            {
              name: "Eventails bleu",
              img: "eventails-bleu.webp",
              id: "eventails-bleu"
            },
            {
              name: "Eventails noir",
              img: "eventails-noir.webp",
              id: "eventails-noir"
            },
            {
              name: "Feuillage bleu turquoise",
              img: "feuillage-bleu-turquoise.webp",
              id: "feuillage-bleu-turquoise"
            },
            {
              name: "Cowboy rouge",
              img: "cowboy-rouge.webp",
              id: "cowboy-rouge"
            },
            {
              name: "Rose pâquerettes",
              img: "rose-paquerettes.webp",
              id: "rose-paquerettes"
            },
            {
              name: "Léopard",
              img: "leopard.webp",
              id: "leopard"
            },
          ],
          type:"bandana_prenom",
          title:"Bandana avec prénom",
          price:{
            'extra-small': 8,
            'small': 9,
            'medium': 10,
            'large': 11,
            'extra-large': 12
          },
          poids: 30
        },
        bandana_texte: {
          id_stripe: { 
            'extra-small': 'price_1MXRclA8BrUF7Csj6Wo3uMYq',
            'small': 'price_1MXRchA8BrUF7CsjevtlroxO',
            'medium': 'price_1MXRcdA8BrUF7Csj1FNAEPZh',
            'large': 'price_1MXRcaA8BrUF7CsjMeDCJkoE',
            'extra-large': 'price_1MXRcWA8BrUF7Csj3y8gCdeY'
          },
          image1:"noeud/noeud.webp",
          image2:"bandana/bandana-love.webp",
          imgs: ["noeud/noeud.webp", "bandana/bandana-love.webp"],
          descriptions: [
            "Accessoire tendance, le bandana ne manquera pas de sublimer le style de votre chien en balade ou lors d’un événement. C’est l’élément idéal pour habiller votre animal simplement, sans le gêner, mais tout en sortant du lot.",
            "Alors, pourquoi ne pas utiliser le look de votre animal pour passer des messages ?",
            "Ce produit vous permet d’exprimer un message au travers l’accessoire favori de votre animal.",
            "Une annonce de grossesse, de mariage ou tout simplement une envie de souhaiter un joyeux anniversaire, les messages peuvent être nombreux. N’hésitez pas à me faire part de votre souhait de personnalisation.",
            "Glissez le bandana sur le collier de votre animal, et hop le tour est joué. Rien de plus simple !",
            "Ce produit est disponible en 5 tailles. L’idéal est que la longueur du bandana fasse la moitié du tour de cou de votre animal. Pour sélectionner votre taille, référez-vous au guide des tailles.",
            "Vous êtes une dogmum ou une dogcat, vous souhaitez être assorti à votre animal. Optez pour l’option chouchou."
          ],
          couleurs: [
            {
              name: "Velour bleu ciel",
              img: "velour-bleu-ciel.webp",
              id: "velour-bleu-ciel"
            },
            {
              name: "Velour cappuccino",
              img: "velour-cappuccino.webp",
              id: "velour-cappuccino"
            },
            {
              name: "Velour rose",
              img: "velour-rose.webp",
              id: "velour-rose"
            },
            {
              name: "Velour rouge",
              img: "velour-rouge.webp",
              id: "velour-rouge"
            },
            {
              name: "Velour vert forêt",
              img: "velour-vert-foret.webp",
              id: "velour-vert-foret"
            },
            {
              name: "Velour vieux rose",
              img: "velour-vieux-rose.webp",
              id: "velour-vieux-rose"
            },
            {
              name: "Eventails girly",
              img: "eventails-girly.webp",
              id: "eventails-girly"
            },
            {
              name: "Eventails bleu",
              img: "eventails-bleu.webp",
              id: "eventails-bleu"
            },
            {
              name: "Eventails noir",
              img: "eventails-noir.webp",
              id: "eventails-noir"
            },
            {
              name: "Feuillage bleu turquoise",
              img: "feuillage-bleu-turquoise.webp",
              id: "feuillage-bleu-turquoise"
            },
            {
              name: "Cowboy rouge",
              img: "cowboy-rouge.webp",
              id: "cowboy-rouge"
            },
            {
              name: "Rose pâquerettes",
              img: "rose-paquerettes.webp",
              id: "rose-paquerettes"
            },
            {
              name: "Léopard",
              img: "leopard.webp",
              id: "leopard"
            },
          ],
          type:"bandana_texte",
          title:"Bandana avec texte",
          price: {
            'extra-small': 8,
            'small': 9,
            'medium': 10,
            'large': 11,
            'extra-large': 12
          },
          poids: 30
        },
        collier: {
          id_stripe: { 
            'extra-small': 'price_1MpsDPA8BrUF7Csjw1KpD86K',
            'small': 'price_1MpsE6A8BrUF7Csj7lQwHq5n',
            'small-medium': 'price_1MpsEYA8BrUF7Csj1wLjhfe9',
            'medium': 'price_1MpsEvA8BrUF7CsjdsdBH5dI',
            'large': 'price_1MpsFQA8BrUF7CsjLLEWZoFU',
            'extra-large': 'price_1MpsFiA8BrUF7CsjAU1cZCKa'
          },
          image1:"collier/collier-1.webp",
          image2:"collier/collier-4.webp",
          imgs: [
            "collier/collier-1.webp",
            "collier/collier-4.webp",
            "collier/collier-6.webp",
            "collier/collier-2.webp",
            "collier/collier-5.webp",
            "collier/collier-3.webp",

          ],
          descriptions: [
            "Envie d’ajouter une touche de style à votre animal ? Cameli Melo vous propose une gamme de colliers dans plusieurs coloris et différentes tailles.",
            "Les colliers sont fabriqués à la main à partir de divers tissus, une sangle en polypropylène résistant et de haute qualité, et une bouclerie en laiton. Ces matières sont sélectionnées afin de garantir la solidité de votre collier.",
            "Référez-vous au guide des tailles pour choisir au mieux la taille qui conviendra à votre chien.",
            "Puisque nos meilleurs amis à quatre pattes méritent aussi d'être stylés, n'hésitez pas à me contacter afin de travailler ensemble pour créer votre collier idéal."
          ],
          couleurs: [
            {
              name: "Velour bleu ciel",
              img: "velour-bleu-ciel.webp",
              id: "velour-bleu-ciel"
            },
            {
              name: "Velour cappuccino",
              img: "velour-cappuccino.webp",
              id: "velour-cappuccino"
            },
            {
              name: "Velour rose",
              img: "velour-rose.webp",
              id: "velour-rose"
            },
            {
              name: "Velour rouge",
              img: "velour-rouge.webp",
              id: "velour-rouge"
            },
            {
              name: "Velour vert forêt",
              img: "velour-vert-foret.webp",
              id: "velour-vert-foret"
            },
            {
              name: "Velour vieux rose",
              img: "velour-vieux-rose.webp",
              id: "velour-vieux-rose"
            },
            {
              name: "Eventails girly",
              img: "eventails-girly.webp",
              id: "eventails-girly"
            },
            {
              name: "Eventails bleu",
              img: "eventails-bleu.webp",
              id: "eventails-bleu"
            },
            {
              name: "Eventails noir",
              img: "eventails-noir.webp",
              id: "eventails-noir"
            },
            {
              name: "Feuillage bleu turquoise",
              img: "feuillage-bleu-turquoise.webp",
              id: "feuillage-bleu-turquoise"
            },
            {
              name: "Cowboy rouge",
              img: "cowboy-rouge.webp",
              id: "cowboy-rouge"
            },
            {
              name: "Rose pâquerettes",
              img: "rose-paquerettes.webp",
              id: "rose-paquerettes"
            },
            {
              name: "Léopard",
              img: "leopard.webp",
              id: "leopard"
            },
          ],
          type:"collier",
          title:"Collier",
          price:{ 
            'extra-small': 26,
            'small': 28,
            'small-medium': 30,
            'medium': 31,
            'large': 32,
            'extra-large': 33
          },
          poids: 30
        },
        chouchou: {
          id_stripe: { 
            'medium': 'price_1MVgVnA8BrUF7CsjjWHbQpYR',
            'extra-small': 'price_1MVgVuA8BrUF7Csj1ZLiutp5'
          },
          image1:"chouchou/chouchoux-camille-2.webp",
          image2:"chouchou/chouchoux-camille-3.webp",
          imgs: [
            "chouchou/chouchoux-camille-2.webp",
            "chouchou/chouchoux-camille-3.webp",
            "bandana/bandana-cowboy.webp",
            "bandana/bandana-plume.webp"
          ],
          couleurs: [
            {
              name: "Blanc pois dorés",
              img: "blanc-pois-dores.webp",
              id: "blanc-pois-dores"
            },
            {
              name: "Bleu ciel pois dorés",
              img: "bleu-ciel-pois-dores.webp",
              id: "bleu-ciel-pois-dores"
            },
            {
              name: "Bleu indigo pois dorés",
              img: "bleu-indigo-pois-dores.webp",
              id: "bleu-indigo-pois-dores"
            },
            {
              name: "Bois de rose pois dorés",
              img: "bois-de-rose-pois-dores.webp",
              id: "bois-de-rose-pois-dores"
            },
            {
              name: "Bordeau pois dorés",
              id: 'bordeau-pois-dores',
              img: "bordeau-pois-dores.webp"
            },
            {
              name: "Corail pois dorés",
              img: "corail-pois-dores.webp",
              id: "corail-pois-dores"
            },
            {
              name: "Rose pâle pois dorés",
              img: "rose-pale-pois-dores.webp",
              id: "rose-pale-pois-dores"
            },
            {
              name: "Terracota pois dorés",
              img: "terracota-pois-dores.webp",
              id: "terracota-pois-dores"
            },
            {
              name: "Vert menthe pois dorés",
              img: "vert-menthe-pois-dores.webp",
              id: "vert-menthe-pois-dores"
            },
            {
              name: "Vert sauge pois dorés",
              img: "vert-sauge-pois-dores.webp",
              id: "vert-sauge-pois-dores"
            },
            {
              name: "Velour bleu ciel",
              img: "velour-bleu-ciel.webp",
              id: "velour-bleu-ciel"
            },
            {
              name: "Velour cappuccino",
              img: "velour-cappuccino.webp",
              id: "velour-cappuccino"
            },
            {
              name: "Velour rose",
              img: "velour-rose.webp",
              id: "velour-rose"
            },
            {
              name: "Velour rouge",
              img: "velour-rouge.webp",
              id: "velour-rouge"
            },
            {
              name: "Velour vert forêt",
              img: "velour-vert-foret.webp",
              id: "velour-vert-foret"
            },
            {
              name: "Velour vieux rose",
              img: "velour-vieux-rose.webp",
              id: "velour-vieux-rose"
            },
            {
              name: "Eventails girly",
              img: "eventails-girly.webp",
              id: "eventails-girly"
            },
            {
              name: "Eventails bleu",
              img: "eventails-bleu.webp",
              id: "eventails-bleu"
            },
            {
              name: "Eventails noir",
              img: "eventails-noir.webp",
              id: "eventails-noir"
            },
            {
              name: "Feuillage bleu turquoise",
              img: "feuillage-bleu-turquoise.webp",
              id: "feuillage-bleu-turquoise"
            },
            {
              name: "Cowboy rouge",
              img: "cowboy-rouge.webp",
              id: "cowboy-rouge"
            },
            {
              name: "Rose pâquerettes",
              img: "rose-paquerettes.webp",
              id: "rose-paquerettes"
            },
            {
              name: "Léopard",
              img: "leopard.webp",
              id: "leopard"
            },
          ],
          descriptions: [
            "Envie de réaliser de jolie coiffure tout en ajoutant une touche de couleur et de féminité ? Le chouchou est fait pour vous ! Cet accessoire tendance finira parfaitement chacune de vos coiffures.",
            "Il peut aussi faire office de bijou à porter au poignet afin d’accompagner toutes vos tenues.",
            "Le petit plus ? Si vous êtes une dogmom, c’est l’élément indispensable pour être assorti aux accessoires de votre poilu.",
            "Ce produit existe en deux tailles afin de satisfaire les plus grands comme les plus petits."
          ],
          type:"chouchou",
          title:"Chouchou",
          price:{ 
            'medium': 5,
            'extra-small': 4.5
          },
          poids: 20
        },
        cotons: {
          id_stripe: { 
            'lot4': 'price_1Mps9MA8BrUF7CsjDZHNXW67',
            'lot8': 'price_1Mps9kA8BrUF7CsjiEwuaVZ8',
            'lot10': 'price_1MpsAAA8BrUF7CsjgnMC6URO'
          },
          image1:"cotons/cotons-1.webp",
          image2:"cotons/cotons-2.webp",
          imgs: [
            "cotons/cotons-1.webp",
            "cotons/cotons-2.webp"
          ],
          couleurs: [
            {
              name: "Blanc pois dorés",
              img: "blanc-pois-dores.webp",
              id: "blanc-pois-dores"
            },
            {
              name: "Bleu ciel pois dorés",
              img: "bleu-ciel-pois-dores.webp",
              id: "bleu-ciel-pois-dores"
            },
            {
              name: "Bleu indigo pois dorés",
              img: "bleu-indigo-pois-dores.webp",
              id: "bleu-indigo-pois-dores"
            },
            {
              name: "Bois de rose pois dorés",
              img: "bois-de-rose-pois-dores.webp",
              id: "bois-de-rose-pois-dores"
            },
            {
              name: "Bordeau pois dorés",
              id: 'bordeau-pois-dores',
              img: "bordeau-pois-dores.webp"
            },
            {
              name: "Corail pois dorés",
              img: "corail-pois-dores.webp",
              id: "corail-pois-dores"
            },
            {
              name: "Rose pâle pois dorés",
              img: "rose-pale-pois-dores.webp",
              id: "rose-pale-pois-dores"
            },
            {
              name: "Terracota pois dorés",
              img: "terracota-pois-dores.webp",
              id: "terracota-pois-dores"
            },
            {
              name: "Vert menthe pois dorés",
              img: "vert-menthe-pois-dores.webp",
              id: "vert-menthe-pois-dores"
            },
            {
              name: "Vert sauge pois dorés",
              img: "vert-sauge-pois-dores.webp",
              id: "vert-sauge-pois-dores"
            },
            {
              name: "Velour bleu ciel",
              img: "velour-bleu-ciel.webp",
              id: "velour-bleu-ciel"
            },
            {
              name: "Velour cappuccino",
              img: "velour-cappuccino.webp",
              id: "velour-cappuccino"
            },
            {
              name: "Velour rose",
              img: "velour-rose.webp",
              id: "velour-rose"
            },
            {
              name: "Velour rouge",
              img: "velour-rouge.webp",
              id: "velour-rouge"
            },
            {
              name: "Velour vert forêt",
              img: "velour-vert-foret.webp",
              id: "velour-vert-foret"
            },
            {
              name: "Velour vieux rose",
              img: "velour-vieux-rose.webp",
              id: "velour-vieux-rose"
            },
            {
              name: "Eventails girly",
              img: "eventails-girly.webp",
              id: "eventails-girly"
            },
            {
              name: "Eventails bleu",
              img: "eventails-bleu.webp",
              id: "eventails-bleu"
            },
            {
              name: "Eventails noir",
              img: "eventails-noir.webp",
              id: "eventails-noir"
            },
            {
              name: "Feuillage bleu turquoise",
              img: "feuillage-bleu-turquoise.webp",
              id: "feuillage-bleu-turquoise"
            },
            {
              name: "Cowboy rouge",
              img: "cowboy-rouge.webp",
              id: "cowboy-rouge"
            },
            {
              name: "Rose pâquerettes",
              img: "rose-paquerettes.webp",
              id: "rose-paquerettes"
            },
            {
              name: "Léopard",
              img: "leopard.webp",
              id: "leopard"
            },
          ],
          descriptions: [
            "Le lot de cotons lavables est l’indispensable de vos soins, du quotidien de vos bambins mais aussi de vos animaux de compagnie ! D’une douceur extrême, les cotons réutilisables permettent de nettoyer la peau, démaquiller, enlever les impuretés, etc...",
            "Une idée de cadeau sympa et original pour une maîtresse, un anniversaire, une fête des mères, et j’en passe ! Ce cadeau est utile et fait toujours plaisir, aux petits comme aux grands.",
            "C’est aussi un beau geste écoresponsable, pour la planète et votre porte monnaie. Vive le zéro déchet ;) ",
          ],
          type:"cotons",
          title:"Cotons lavables",
          price:{ 
            'lot4': 6,
            'lot8': 10,
            'lot10': 11.5
          },
          poids: 20
        },
    },
    tissus: [
      {
        id: 'bordeau-pois-dores',
        name: "Bordeau pois dorés",
        img: "bordeau-pois-dores.webp"
      },
      {
        name: "Terracota pois dorés",
        img: "terracota-pois-dores.webp",
        id: "terracota-pois-dores"
      },
      {
        name: "Corail pois dorés",
        img: "corail-pois-dores.webp",
        id: "corail-pois-dores"
      },
      {
        name: "Rose pâle pois dorés",
        img: "rose-pale-pois-dores.webp",
        id: "rose-pale-pois-dores"
      },
      {
        name: "Vert menthe pois dorés",
        img: "vert-menthe-pois-dores.webp",
        id: "vert-menthe-pois-dores"
      },
      {
        name: "Velour rose",
        img: "velour-rose.webp",
        id: "velour-rose"
      },
      {
        name: "Velour rouge",
        img: "velour-rouge.webp",
        id: "velour-rouge"
      },
      {
        name: "Eventails girly",
        img: "eventails-girly.webp",
        id: "eventails-girly"
      },
      {
        name: "Eventails bleu",
        img: "eventails-bleu.webp",
        id: "eventails-bleu"
      },
      {
        name: "Feuillage bleu turquoise",
        img: "feuillage-bleu-turquoise.webp",
        id: "feuillage-bleu-turquoise"
      },
      {
        name: "Cowboy rouge",
        img: "cowboy-rouge.webp",
        id: "cowboy-rouge"
      },
      {
        name: "Rose pâquerettes",
        img: "rose-paquerettes.webp",
        id: "rose-paquerettes"
      },
      {
        name: "Léopard",
        img: "leopard.webp",
        id: "leopard"
      },
    ],
    commande: null,
  },
  mutations: {
    SET_PRODUITS(state, produits) {
      state.produits = produits;
      localStorage.setItem('produits', JSON.stringify(state.produits));
    },
    SET_CATEGORIES(state, categories) {
      state.categories = categories;
      localStorage.setItem('categories', JSON.stringify(state.categories));
    },
    SET_COOKIE(state, value) {
      state.cookie = {
        date: Math.floor(Date.now() / 1000),
        accept: value
      }
      localStorage.setItem('cookie', JSON.stringify(state.cookie));
    },
    SET_CURRENT_USER(state, value) {
      state.currentUser = value
      localStorage.setItem('user', JSON.stringify(state.currentUser));
    },
    SET_COMMANDE(state, value) {
      state.commande = value
      localStorage.setItem('commande', JSON.stringify(state.commande));
    },
    ajouterPanier(state, value) {
      state.panier.quantite += value.quantite
      state.panier.tarif += value.quantite * value.prix
      let produit = state.panier.produits.find(produit => {
        return produit.type == value.type &&
          produit.tissu == value.tissu &&
          produit.texte == value.texte &&
          produit.couleurTexte == value.couleurTexte &&
          produit.tissuSeconédary == value.tissuSecondary &&
          produit.taille == value.taille &&
          produit.noeud == value.noeud &&
          produit.metadata == value.metadata
      })
      if (!produit) {
        value.key = uuidv4()
        const produitFromDB = state.produits.find(e => { return value.type == e.type })
        value.id_stripe = ['carnet', 'passeport', 'livret'].includes(value.type) && value.noeud ?
          produitFromDB.id_stripe.avec_noeud : 
          ['sac-banane'].includes(value.type) && value.metadata && value.metadata.deuxiemeSangle ?
            produitFromDB.id_stripe["double-sangle"] :
            ['bandana-reversible-noel'].includes(value.type) && value.texte ?
              produitFromDB.id_stripe[value.taille + '-texte'] :
              produitFromDB.id_stripe[value.taille]
        state.panier.produits.push(value)
      } else {
        produit.quantite += value.quantite
      }
      localStorage.setItem('panier', JSON.stringify(state.panier));
    },
    cleanPanier(state) {
      state.panier = Object.assign(initPanier),
      localStorage.setItem('panier', JSON.stringify(state.panier));
      localStorage.setItem('commande', null);
    },
    cleanCommande(state) {
      state.commande = null,
      localStorage.setItem('commande', null);
    },
    SET_CODE_PROMO(state, value) {
      state.code_promo = value
      state.reduction = 0
      if (state.code_promo) {
        state.panier.produits.forEach(produit => {
          if (produit.type != "sac-banane") {
            state.reduction = state.reduction + (produit.quantite * produit.prix * 0.1)
          }
        })
      }
    },
    async initialiseStore(state) {
      if (localStorage.getItem('commande')) {
        state.commande = JSON.parse(localStorage.getItem('commande'))
      }

      const yesterday = Math.floor(Date.now() / 1000) - 86400
      let cookie = null;
      if (localStorage.getItem('cookie')) {
        cookie = JSON.parse(localStorage.getItem('cookie'))
        state.cookie = (cookie.date < yesterday) && !cookie.accept ? null : cookie
        localStorage.setItem('cookie', JSON.stringify(state.cookie));
      }
      
      if (localStorage.getItem('user')) {
        state.currentUser = JSON.parse(localStorage.getItem('user'))
        if (!cookie && cookie.date < yesterday) {
          state.currentUser = null;
          localStorage.setItem('user', JSON.stringify(state.currentUser));
        }
      }

      if (localStorage.getItem('panier')) {
        state.panier = JSON.parse(localStorage.getItem('panier'))
        if (cookie && cookie.date < yesterday) {
          state.panier = Object.assign(state.panier)
          localStorage.setItem('panier', JSON.stringify(state.panier));
        }
      }

      if (localStorage.getItem('produits')) {
        state.produits = JSON.parse(localStorage.getItem('produits'))
      }

      if (localStorage.getItem('categories')) {
        state.categories = JSON.parse(localStorage.getItem('categories'))
      }
		}
  },
  actions: {
    setProduits({ commit }, newValue) {
      commit("SET_PRODUITS", newValue);
    },
    updatePriceAndQuantite({ state }) {
      const { produits } = state.panier;
      state.panier.quantite = produits.reduce((totalQuantite, produit) => totalQuantite + produit.quantite, 0);
      state.panier.tarif = produits.reduce((totalTarif, produit) => totalTarif + (produit.quantite * produit.prix), 0);
      state.reduction = 0
      if (state.code_promo) {
        state.panier.produits.forEach(produit => {
          if (produit.type != "sac-banane") {
            state.reduction = state.reduction + (produit.quantite * produit.prix * 0.1)
          }
        })
      }
      localStorage.setItem('panier', JSON.stringify(state.panier));
    },
    async loadProducts({ commit }) {
      commit("SET_PRODUITS", await getProduits())
      commit("SET_CATEGORIES", await getCategories())
    }
  },
  modules: {
  },
  getters: {
    produits: (state) => {
      return state.produits;
    }
  }
})

// https://www.mikestreety.co.uk/blog/vue-js-using-localstorage-with-the-vuex-store/
