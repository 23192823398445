<template>
    <div class="columns">
        <div class="column is-2">
            <img class="image-recap" style="border-radius:5px" v-if="(produit.type == 'carnet')" src="@/assets/img/produits/carnet/love-carnets.webp">
            <img class="image-recap" style="border-radius:5px" v-else-if="(produit.type == 'passeport')" src="@/assets/img/produits/passeport/passeport.webp">
            <img class="image-recap" style="border-radius:5px" v-else-if="(produit.type == 'livret')" src="@/assets/img/produits/livret/livret-1.webp">
            <img class="image-recap" style="border-radius:5px" v-else-if="(produit.type == 'bandana') || (produit.type == 'bandana_prenom') || (produit.type == 'bandana_texte')" src="@/assets/img/produits/bandana/bandanas-4.webp">
            <img class="image-recap" style="border-radius:5px" v-else-if="(produit.type == 'bandana-reversible')" src="@/assets/img/produits/bandana/bandana-reversible-1.webp">
            <img class="image-recap" style="border-radius:5px" v-else-if="(produit.type == 'bandana-reversible-noel')" src="@/assets/img/produits/bandana/noel/bandanas.webp">
            <img class="image-recap" style="border-radius:5px" v-else-if="(produit.type == 'bandana-noel')" src="@/assets/img/produits/noeud/noeud.webp">
            <img class="image-recap" style="border-radius:5px" v-else-if="(produit.type == 'noeud-noel')" src="@/assets/img/produits/noeud/noel/noeuds.webp">
            <img class="image-recap" style="border-radius:5px" v-else-if="(produit.type == 'collier')" src="@/assets/img/produits/collier/collier-1.webp">
            <img class="image-recap" style="border-radius:5px" v-else-if="(produit.type == 'chouchou')" src="@/assets/img/produits/chouchou/chouchoux-camille-2.webp">
            <img class="image-recap" style="border-radius:5px" v-else-if="(produit.type == 'cotons')" src="@/assets/img/produits/cotons/cotons-1.webp">
            <img class="image-recap" style="border-radius:5px" v-else-if="(produit.type == 'noeud')" src="@/assets/img/produits/noeud/noeud-2.webp">
            <img class="image-recap" style="border-radius:5px" v-else-if="(produit.type == 'sac-banane')" src="@/assets/img/produits/banane/sac-banane-7.webp">
            <img class="image-recap" style="border-radius:5px" v-else-if="(produit.type == 'tote-bag')" src="@/assets/img/produits/tote-bag/tote-bag-1.webp">
            <img class="image-recap" style="border-radius:5px" v-else-if="(produit.type == 'balle-fouille')" src="@/assets/img/produits/balle-fouille/balle-fouille-3.webp">
        </div>
        <div class="column is-10">
            <p>
                <span style="font-weight: 600;">{{
                    produit.type == "carnet" ? `Housse de carnet de santé pour animaux ${produit.noeud ? 'avec noeud': ''}` :
                    produit.type == "passeport" ? `Housse de passeport européen pour animaux${produit.noeud ? 'avec noeud': ''}` :
                    produit.type == "livret" ? `Housse de livret de famille ${produit.noeud ? 'avec noeud': ''}` :
                    produit.type == "bandana" ? `Bandana (${produit.taille == 'extra-small' ? 'XS' :
                                produit.taille == 'small' ? 'S' :
                                produit.taille == 'small-medium' ? 'S/M' :
                                produit.taille == 'medium' ? 'M' :
                                produit.taille == 'large' ? 'L' :
                                produit.taille == 'extra-large' ? 'XL' : ''})` :
                    produit.type == "bandana-reversible" ? `Bandana réversible (${produit.taille == 'extra-small' ? 'XS' :
                                produit.taille == 'small' ? 'S' :
                                produit.taille == 'small-medium' ? 'S/M' :
                                produit.taille == 'medium' ? 'M' :
                                produit.taille == 'large' ? 'L' :
                                produit.taille == 'extra-large' ? 'XL' : ''})` :
                    produit.type == "bandana-reversible-noel" ? `Bandana réversible de Noël (${produit.taille == 'extra-small' ? 'XS' :
                                produit.taille == 'small' ? 'S' :
                                produit.taille == 'small-medium' ? 'S/M' :
                                produit.taille == 'medium' ? 'M' :
                                produit.taille == 'large' ? 'L' :
                                produit.taille == 'extra-large' ? 'XL' : ''})` :
                    produit.type == "bandana-noel" ? `Bandana de Noël (${produit.taille == 'extra-small' ? 'XS' :
                                produit.taille == 'small' ? 'S' :
                                produit.taille == 'small-medium' ? 'S/M' :
                                produit.taille == 'medium' ? 'M' :
                                produit.taille == 'large' ? 'L' :
                                produit.taille == 'extra-large' ? 'XL' : ''})` :
                    produit.type == "collier" ? `Collier (${produit.taille == 'extra-small' ? 'XS' :
                                produit.taille == 'small' ? 'S' :
                                produit.taille == 'small-medium' ? 'S/M' :
                                produit.taille == 'medium' ? 'M' :
                                produit.taille == 'large' ? 'L' :
                                produit.taille == 'extra-large' ? 'XL' : ''})` :
                    produit.type == "noeud" ? `Noeud (${produit.taille == 'extra-small' ? 'XS' :
                                produit.taille == 'small' ? 'S' :
                                produit.taille == 'small-medium' ? 'S/M' :
                                produit.taille == 'medium' ? 'M' :
                                produit.taille == 'large' ? 'L' :
                                produit.taille == 'extra-large' ? 'XL' : ''})` :
                    produit.type == "noeud-noel" ? `Noeud de Noël (${produit.taille == 'extra-small' ? 'XS' :
                                produit.taille == 'small' ? 'S' :
                                produit.taille == 'small-medium' ? 'S/M' :
                                produit.taille == 'medium' ? 'M' :
                                produit.taille == 'large' ? 'L' :
                                produit.taille == 'extra-large' ? 'XL' : ''})` :
                    produit.type == "bandana_prenom" ? `Bandana avec prénom (${produit.taille == 'extra-small' ? 'XS' :
                                produit.taille == 'small' ? 'S' :
                                produit.taille == 'small-medium' ? 'S/M' :
                                produit.taille == 'medium' ? 'M' :
                                produit.taille == 'large' ? 'L' :
                                produit.taille == 'extra-large' ? 'XL' : ''})` :
                    produit.type == "bandana_texte" ? `Bandana avec texte (${produit.taille == 'extra-small' ? 'XS' :
                                produit.taille == 'small' ? 'S' :
                                produit.taille == 'small-medium' ? 'S/M' :
                                produit.taille == 'medium' ? 'M' :
                                produit.taille == 'large' ? 'L' :
                                produit.taille == 'extra-large' ? 'XL' : ''})` :
                    produit.type == "chouchou" ? `Chouchou ${produit.taille == 'medium' ? 'adulte' : 'enfant'}` :
                    produit.type == "tote-bag" ? `Tote bag` :
                    produit.type == "balle-fouille" ? `Balle de fouille` :
                    produit.type == "cotons" ? `Cotons lavables par ${ produit.taille == 'lot4' ? '4' : produit.taille == 'lot8' ? '8' : produit.taille == 'lot10' ? '10' : '' }` :
                    produit.type == "sac-banane" && produit.metadata.sangleInterchangeable && produit.metadata.deuxiemeSangle ? "Sac banane avec 2 sangles" :
                        produit.type == "sac-banane" && produit.metadata.sangleInterchangeable && !produit.metadata.deuxiemeSangle ? "Sac banane avec sangle interchangeable" :
                        produit.type == "sac-banane" && !produit.metadata.sangleInterchangeable ? "Sac banane avec sangle intégrée" : ""
                }}</span>
                <span class="is-pulled-right">
                {{
                    Number.parseFloat(produit.quantite * produit.prix).toFixed(2)
                }} €</span>
            </p>
            <p>Quantité : {{ produit.quantite }}</p>
            <p v-if="!produit.tissuSecondaire && produit.type != 'sac-banane' && produit.type != 'tote-bag' && produit.type != 'balle-fouille' && produit.type != 'bandana-noel'">Tissu : {{produit.tissuNom}}</p>
            <div v-else-if="produit.type == 'tote-bag'">
            </div>
            <div v-else-if="produit.type == 'balle-fouille'">
                Ensemble : {{produit.tissuNom}}
            </div>
            <div v-else-if="produit.type == 'bandana-noel'">
                Ensemble : {{produit.tissuNom}}
            </div>
            <div v-else>
                <p>Tissu haut : {{produit.tissuSecondaire}} <span v-if="produit.texte"></span></p>
                <p>Tissu bas : {{produit.tissuNom}} <span v-if="produit.texte"></span></p>
            </div>
            <div v-if="produit.type == 'sac-banane'">
                <p class="product-details-more"><span class="product-details">Tissus : </span>{{produit.tissuNom}}</p>
                <p v-if="!produit.metadata.deuxiemeSangle" class="product-details-more">
                    Sangle avec le même tissu que l'{{produit.metadata.couleurSangle}}
                </p>
                <p v-else-if="produit.metadata.deuxiemeSangle" class="product-details-more">
                    <span>Sangle 1 avec le même tissu que l'extérieur</span><br/>
                    <span>Sangle 2 avec le même tissu que l'intérieur</span>
                </p>
            </div>
            <p v-if="produit.texte">Texte : {{ produit.texte }}</p>
        </div>   
    </div>
</template>


<script>
export default {
    name: 'CheckoutView',
    props: ['produit'],
}
</script>
  
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

$bold-light: 700;
@media screen and (max-width:723px) {
    .image-recap {
        display: none;
    }
}
</style>
