<template>
    <div>
        <div class="odd">
            <div class="container">
                <p class="title has-text-centered">Produits</p>
            </div>
        </div>
        <div v-for="categorie in categories" :key="categorie.key" :class="categorie.css">
            <div class="container">
                <p class="titre-secondaire">{{ categorie.nom }}</p>
                <div v-if="categorie.description" class="description">
                    <p>{{ categorie.description }}</p>
                </div>
                <div class="columns is-multiline">
                    <div class="column is-3" v-for="produit in produits.filter(e => { return e.categorie == categorie.key })" :key="produit.type">
                        <product-card :produit="produit"></product-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import ProductCard from '@/components/page/products/ProductCard.vue';
import { mapState } from 'vuex'

export default {
    components: {
        ProductCard,
    },
    name: 'ProductsView',
    computed: {
        ...mapState(['categories', 'produits'])
    },
}
</script>
  
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.title {
    padding-bottom:30px;
    color: $primary-color;
    font-weight: 750;
    font-size:40px;
}

.titre-secondaire {
    font-size:$text-title-size;
    font-weight: 550;
    margin-bottom: 20px;
}

.even {
    padding-top:50px;
    padding-bottom:15px;
    background-color: $secondary-color;
}

.odd {
    padding-top:50px;
    padding-bottom:15px;
}

@media screen and (max-width:768px) {
    .titre-secondaire {
        text-align: center;
    }
}

@media screen and  (max-width: 1023px) {
    .container {
        margin-left: 30px;
        margin-right: 30px;
    }
}

.description {
    text-align: justify;
    line-height: 20px;
    margin-bottom:25px;
    font-size: 18px;
}

</style>
