<template>
    <div class="main_contact">
        <div class="container">
            <p class="title has-text-centered">Contact</p>
            <div class="columns">
                <div class="column is-5">
                    <div class="box">
                        <p class="description_contact">Pour tout renseignement ou demande en tout genre vous pouvez me contacter sur Instagram ou par email. Je me ferai un plaisir de vous répondre afin de vous aider au mieux.</p>
                        <p class=""><span class="has-text-weight-bold">Email :</span> camelimelo.creation@gmail.com</p>
                        <p class="description_contact"><span class="has-text-weight-bold">Instagram :</span> @cameli_melo</p>
                        <p class="">Vous pouvez également soumettre votre demande via ce formulaire.</p>
                    </div>
                </div>
                <div class="column is-7">
                    <b-field
                        :type="error && !this.email ? 'is-danger' : ''"
                        class="email"
                        label="Email">
                        <b-input type="email"
                            v-model="email">
                        </b-input>
                    </b-field>
                    <b-field
                        :type="error && !this.objet ? 'is-danger' : ''"
                        class="objet"
                        label="Objet de votre demande">
                        <b-input type="objet"
                            v-model="objet">
                        </b-input>
                    </b-field>
                    <b-field
                        :type="error && !this.content ? 'is-danger' : ''"
                        label="Message">
                        <b-input v-model="content" minlength="10" maxlength="1000" type="textarea"></b-input>
                    </b-field>
                    <b-button
                        class="is-pulled-right"
                        style="width: 150px;"
                        label="Envoyer"
                        @click="envoyer()"
                        type="is-primary" />
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import {contactCameliMelo} from '@/services/UserService'

export default {
    name: 'ContactView',
    data() {
        return {
            objet: "",
            email: "",
            content: "",
            error: false,
        }
    },
    methods: {
        async envoyer() {
            try {
                this.error = false;
                if (!this.email || !this.objet || !this.content) {
                    this.error = true;
                }
                await contactCameliMelo({
                    from: this.email,
                    objet: this.objet,
                    content: this.content
                })
                this.email = ""
                this.objet = ""
                this.content = ""
                this.$buefy.toast.open({
                    duration: 5000,
                    message: 'Votre email a bien été envoyé.',
                    type: 'is-success'
                })
            } catch(e) {
                this.$buefy.toast.open({
                    duration: 5000,
                    message: 'Une erreur est survenue, veuillez réessayer plus tard ou prendre contact directement sur instagram ou par email.',
                    type: 'is-danger'
                })
            }
            
        }
    }
}
</script>
  
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.main_contact {
    padding-top:50px;
}

.email {
    max-width: 400px;
}


@media screen and (max-width: 1023px) {
    .container {
        margin-left:30px;
        margin-right:30px;
    }
}

.box {
    background-color: $secondary-color;
}
.title {
    color: $primary-color;
    font-weight: 750;
    font-size:40px;
}

.description_contact  {
    margin-bottom:25px;
}

.box {
    padding:30px;
}
</style>
  