<template>
  <div class="shop-button-desktop">
      <a v-on:click="goToPanier()" class="button-with-quantite" v-if="panier.quantite">
          <b-icon icon="cart"></b-icon>
          <span class="badge">({{ panier.quantite }})</span>
      </a>
      <a v-on:click="goToPanier()" class="button-without-quantite" v-else>
        <b-icon icon="cart-outline" class="icon-navbar"></b-icon>
        <span class="badge">(0)</span>
      </a>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'NavBar',
  computed: {
      ...mapState(['panier'])
  },
  methods: {
      goToPanier() {
          this.$router.push('/panier')
      }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/variables.scss";

.button-with-quantite {
background-color: white;
color: #4a4a4a;
text-decoration: none;
padding-top: 10.5px;
position: relative;
display: inline-block;
margin-right:50px
}


.button-without-quantite {
background-color: white;
color: #4a4a4a;
text-decoration: none;
padding-top: 10.5px;
margin-right:-10px;
position: relative;
display: inline-block;
margin-right:50px
}

.badge {
  position: absolute;
  top: 9px;
  border-radius: 50%;
  font-size:17px;
  font-weight: 600;
  margin-left:3px;
  color: #4a4a4a;
}

.shop-button-desktop {
  a:hover {
    color: #4a4a4ae7 !important;
    .badge {
      color: #4a4a4ae7 !important
    }
  }

  .button-with-quantite:hover, .button-mobile-without-quantite:hover {
    background-color: white !important;
  }
}

.icon-navbar {
.mdi-24px.mdi:before {
  font-size: 28px;
}
}
</style>
