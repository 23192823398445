import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
// import VueHtml2pdf from 'vue-html2pdf'
import Parse from 'parse'
import Meta from 'vue-meta';
import ProductDetailsViewVue from './views/page/ProductDetailsView.vue'
// import { getProduitsType } from './services/ProduitService';

Parse.initialize(
  "pwWLWYFJ5y7pQyTPeeMYdhxdEH95sg",
  "LfpmQZHzG*^us+dfWSC*xPZgwBC*4G"
);
Parse.serverURL = 'https://db.camelimelo.fr/parse';

Vue.config.productionTip = false

Vue.use(Buefy)
Vue.use(Meta);

new Vue({
  router,
  store,
  async beforeCreate() {
		this.$store.commit('initialiseStore');
	},
  render: h => h(App),
  async created() {
    const currentRoute = this.$route.path
    // if (!this.$store.getters.produits.length || this.$store.getters.produits && this.$store.getters.produits.length == 0) {
      await this.$store.dispatch('loadProducts')
    // } else {
    //   const produitsType = await getProduitsType()
    //   const currentProduitsType = this.$store.getters.produits.map(produit => produit.type)
    //   if (arrayDifference(currentProduitsType, produitsType)) {
    //     await this.$store.dispatch('loadProducts')
    //   }
    // }
    await this.fetchProductRoutesFromDatabase(currentRoute);
  },
  methods: {
    async fetchProductRoutesFromDatabase(currentRoute) {
      try {
        const produits = this.$store.getters.produits;
        produits.forEach(result => {
          const route = {
            path: `/produits/${result.type}`,
            name: `${result.type}`,
            component: ProductDetailsViewVue
          };
          this.$router.addRoute(route);
        });
        if (currentRoute != '/') {
          this.$router.replace(currentRoute);
        }
      } catch (error) {
        console.error('Error fetching product routes:', error);
      }
    }
  }
}).$mount('#app')

// function arrayDifference(array1, array2) {
//   const diff1 = array1.filter(item => !array2.includes(item));
//   const diff2 = array2.filter(item => !array1.includes(item));
//   const array = diff1.concat(diff2)
//   return array.length > 0 ? true : false
// }