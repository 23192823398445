<template>
    <div>
        <div class="container">
            <p class="titre-principal">Conditions générales de vente et d'utilisation</p>

            <p class="titre">Préambule</p>
            <p class="description">Les présentes conditions générales de vente s'appliquent à toutes les ventes conclues sur le site Internet Cameli Melo.</p>
            <p class="description">Le site Internet https://www.camelimelo.fr est un service de :</p>
            <li>L'entreprise individuelle Cameli Melo</li>
            <li>Située 2 Rue Alexandre Dumas, appartement 213, 59155 Faches-Thumesnil, France</li>
            <li>Adresse URL du site : https://www.camelimelo.fr</li>
            <li>Email : camelimelo.creation@gmail.com</li>
            <p class="description">Le site Internet Cameli Melo commercialise les produits suivants : Accessoires vestimentaires pour hommes, femmes et animaux</p>
            <p class="description">Le client déclare avoir pris connaissance et avoir accepté les conditions générales de vente antérieurement à la passation de sa commande. La validation de la commande vaut donc acceptation des conditions générales de vente.</p>

            <p class="titre">Article 1 - Principes</p>
            <p class="description">Les présentes conditions générales expriment l'intégralité des obligations des parties. En ce sens, l'acheteur est réputé les accepter sans réserve.</p>
            <p class="description">Les présentes conditions générales de vente s'appliquent à l'exclusion de toutes autres conditions, et notamment celles applicables pour les ventes en magasin ou au moyen d'autres circuits de distribution et de commercialisation.</p>
            <p class="description">Elles sont accessibles sur le site internet Cameli Melo et prévaudront, le cas échéant, sur toute autre version ou tout autre document contradictoire.</p>
            <p class="description">Le vendeur et l'acheteur conviennent que les présentes conditions générales régissent exclusivement leur relation. Le vendeur se réserve le droit de modifier ponctuellement ses conditions generales. Elles seront applicables des leur mise en ligne.</p>
            <p class="description">Si une condition de vente venait à faire défaut, elle serait considérée être régie par les usages en vigueur dans le secteur de la vente à distance dont les sociétés ont leur siège en France.</p>
            <p class="description">Les présentes conditions générales de vente sont valables jusqu'au 31/12/2023.</p>

            <p class="titre">Article 2 - Contenu</p>
            <p class="description">Les présentes conditions générales ont pour objet de définir les droits et obligations des parties dans le cadre de la vente en ligne de biens proposés par le vendeur à l'acheteur, à partir du site internet Cameli Melo.</p>
            <p class="description">Les présentes conditions ne concernent que les achats effectués sur le site de Cameli Melo et livrés exclusivement en métropole ou en Corse.</p>
            <p class="description">Pour toute livraison dans les DOM-TOM ou à l'etranger, il convient d'adresser un message à l'adresse e-mail suivante : camelimelo.creation@gmail.com</p>
            <p class="description">Ces achats concernent les produits suivants : Accessoires vestimentaires pour hommes, femmes et animaux</p>

            <p class="titre">Article 3 - Informations précontractuelles</p>
            <p class="description">L'acheteur reconnaît avoir eu communication, préalablement à la passation de sa commande et à la conclusion du contrat, d'une manière lisible et compréhensible, des présentes conditions générales de vente et de toutes les informations listées à l'article L. 221-5 du code de la consommation.</p>
            <p class="description">Sont transmises à l'acheteur, de manière claire et compréhensible, les informations suivantes :</p>
            <li>Les caractéristiques essentielles du bien</li>
            <li>Le prix du bien et/ou le mode de calcul du prix</li>
            <li>S'il y a lieu, tous les frais supplementaires de transport, de livraison ou d'affranchissement et tous les autres frais eventuels exigibles</li>
            <li>En l'absence d'exécution immédiate du contrat, la date ou le délai auquel le vendeur s'engage à livrer le bien, quel que soit son prix</li>
            <li>Les informations relatives à l'identité du vendeur, à ses coordonnées postales, téléphoniques et électroniques, et à ses activités, celles relatives aux garanties légales, aux fonctionnalités du contenu numérique et, le cas échéant, à son interopérabilité, à l'existence et aux modalités de mise en oeuvre des garanties et autres conditions contractuelles</li>

            <p class="titre">Article 4 - La commande</p>
            <p class="description">L'acheteur a la possibilité de passer sa commande en ligne, à partir du catalogue en ligne et au moyen du formulaire qui y figure, pour tout produit, dans la limite des stocks disponibles.</p>
            <p class="description">L'acheteur sera informé de toute indisponibilité du produit ou du bien commandé.</p>
            <p class="description">Pour que la commande soit validée, l'acheteur devra accepter, en cliquant à l'endroit indiqué, les présentes conditions générales. Il devra aussi choisir l'adresse et le mode de livraison, et enfin valider le mode de paiement.</p>
            <p class="description">La vente sera considérée comme définitive :</p>
            <li>Après l'envoi à l'acheteur de la confirmation de l'acceptation de la commande par le vendeur par courrier électronique</li>
            <li>Après encaissement par le vendeur de l'intégralité du prix.</li>
            <p class="description">Toute commande vaut acceptation des prix et de la description des produits disponibles à la vente. Toute contestation sur ce point interviendra dans le cadre d'un éventuel échange et des garanties ci-dessous mentionnées.</p>
            <p class="description">Dans certains cas, notamment défaut de paiement, adresse erronée ou autre problème sur le compte de l'acheteur, le vendeur se réserve le droit de bloquer la commande de l'acheteur jusqu'à la résolution du problème.</p>
            <p class="description">Pour toute question relative au suivi d'une commande, l'acheteur peut envoyer un mail au vendeur a l'adresse mail suivante : camelimelo.creation@gmail.com</p>

            <p class="titre">Article 5 - Signature électronique</p>
            <p class="description">La fourniture en ligne du numéro de carte bancaire de l'acheteur et la validation finale de la commande vaudront preuve de l'accord de l'acheteur :</p>
            <li>Exigibilite des sommes dues au titre du bon de commande</li>
            <li>Signature et acception expresse de toutes les opérations effectuées.</li>
            <p class="description">En cas d'utilisation frauduleuse de la carte bancaire, l'acheteur est invité, dès le constat de cette utilisation, à contacter le vendeur par email à l'adresse mail suivante : camelimelo.creation@gmail.com</p>

            <p class="titre">Article 6 - Confirmation de commande</p>
            <p class="description">Le vendeur fournit à l'acheteur une confirmation de commande, par messagerie électronique.</p>

            <p class="titre">Article 7 - Preuve de la transaction</p>
            <p class="description">Les registres informatises, conservés dans les systèmes informatiques du vendeur dans des conditions raisonnables de sécurite, seront considérés comme les preuves des communications, des commandes et des paiements intervenus entre les parties. L'archivage des bons de commande et des factures est effectué sur un support fiable et durable pouvant être produit à titre de preuve.</p>
            
            <p class="titre">Article 8 - Informations sur les produits</p>
            <p class="description">Les produits régis par les présentes conditions générales sont ceux qui figurent sur le site internet du vendeur et qui sont indiqués comme vendus et expédiés par le vendeur. Ils sont proposés dans la limite des stocks disponibles.</p>
            <p class="description">Les produits sont décrits et présentés avec la plus grande exactitude possible. Toutefois, si des erreurs ou omissions ont pu se produire quant à cette présentation, la responsabilité du vendeur ne pourrait être engagée.</p>
            <p class="description">Les photographies des produits ne sont pas contractuelles.</p>

            <p class="titre">Article 9 - Prix</p>
            <p class="description">Le vendeur se réserve le droit de modifier ses prix à tout moment mais s'engage à appliquer les tarifs en vigueur indiqués au moment de la commande. sous réserve de disponibilité à cette date.</p>
            <p class="description">Les prix sont indiqués en euros. Ils ne tiennent pas compte des frais de livraison, factures en supplément, et indiqués avant la validation de la commande.</p>
            <p class="description">Si une ou plusieurs taxes ou contributions, notamment environnementales, venaient à être créées ou modifiées, en hausse comme en baisse, ce changement pourra être répercuté sur le prix de vente des produits.</p>

            <p class="titre">Article 10 - Mode de paiement</p>
            <p class="description">Il s'agit d'une commande avec obligation de paiement, ce qui signifie que la passation de la commande implique un règlement de l'acheteur.</p>
            <p class="description">Pour régler sa commande, l'acheteur dispose, à son choix, de l'ensemble des modes de paiement mis à sa disposition par le vendeur et listés sur le site du vendeur. L'acheteur garantit au vendeur qu'il dispose des autorisations éventuellement nécessaires pour utiliser le mode de paiement chois par lui, lors de la validation du bon de commande. Le vendeur se réserve le droit de suspendre toute gestion de commande et toute livraison en cas de refus d'autorisation de paiement par carte bancaire de la part des organismes officiellement accrédités ou en cas de non-paiement. Le vendeur se réserve notamment le droit de refuser d'effectuer une livraison ou d'honorer une commande émanant d'un acheteur qui n'aurait pas réglé totalement ou partiellement une commande précedente ou avec lequel un litige de paiement serait en cours d'administration.</p>
            <p class="description">Le paiement du prix s'effectue en totalite au jour de la commande, selon les modalites suivantes :</p>
            <li>Carte bancaire</li>

            <p class="titre">Article 11 - Exclusion de garanties et limitation de responsabilité</p>
            <p class="description">Nous ne garantissons ni ne prétendons en aucun cas que votre utilisation de notre Service sera ininterrompue, rapide, sécurisée ou sans erreur.</p>
            <p class="description">Nous ne garantissons pas que les résultats qui pourraient être obtenus par le biais de l’utilisation du Service seront exacts ou fiables.</p>
            <p class="description">Vous acceptez que de temps à autre, nous puissions supprimer le Service pour des périodes de temps indéfinies ou annuler le Service à tout moment, sans vous avertir au préalable.</p>
            <p class="description">Vous convenez expressément que votre utilisation du Service, ou votre incapacité à utiliser celui-ci, est à votre seul risque. Le Service ainsi que tous les produits et services qui vous sont fournis par le biais du Service sont (sauf mention expresse du contraire de notre part) fournis "tels quels" et "selon la disponibilité" pour votre utilisation, et ce sans représentation, sans garanties et sans conditions d'aucune sorte, expresses ou implicites, y compris toutes les garanties implicites de commercialisation ou de qualité marchande, d’adaptation à un usage particulier, de durabilité, de titre et d’absence de contrefaçon.</p>
            <p class="description">Cameli Melo ne peut en aucun cas être tenu responsable de toute blessure, perte, réclamation, ou de dommages directs, indirects, accessoires, punitifs, spéciaux, ou dommages consécutifs de quelque nature qu’ils soient, incluant mais ne se limitant pas à la perte de profits, de revenus, d’économies, de données, aux coûts de remplacement ou tous dommages similaires, qu’ils soient contractuels, délictuels (même en cas de négligence), de responsabilité stricte ou autre, résultant de votre utilisation de tout service ou produit provenant de ce Service, ou quant à toute autre réclamation liée de quelque manière que ce soit à votre utilisation du Service ou de tout produit, incluant mais ne se limitant à toute erreur ou omission dans tout contenu, ou à toute perte ou tout dommage de toute sorte découlant de l’utilisation du Service ou de tout contenu (ou produit) publié, transmis, ou autrement rendu disponible par le biais du Service, même si vous avez été avertis de la possibilité qu’ils surviennent. Parce que certains États ou certaines juridictions ne permettent pas d’exclure ou de limiter la responsabilité quant aux dommages consécutifs ou accessoires, notre responsabilité sera limitée dans la mesure maximale permise par la loi.</p>

            <p class="titre">Article 12 - Droit de rétractation</p>

            <p class="sous-titre">Application du droit de rétractation</p>
            <p class="description">Conformément aux dispositions du code de la consommation, l'acheteur dispose d'un délai de 14 jours à compter de la date de livraison de sa commande, pour retourner tout article ne lui convenant pas et demander l'échange ou le remboursement sans pénalité, à l'exception des frais de retour qui restent a la charge de l'acheteur.</p>
            <p class="description">Les retours sont à effectuer dans leur état d'origine et complets (emballage, accessoires, notice...) permettant leur recommercialisation à l'état neuf accompagnés de leur numéro de commande.</p>
            <p class="description">Les produits endommagés, salis ou incomplets ne sont pas repris.</p>
            <p class="description">Le droit de retractation peut etre exerce en ligne, à l'aide du formulaire de rétractation disponible sur ce site internet. Dans ce cas, un accusé de réception sur un support durable sera immédiatement communiqué à l'acheteur. Tout autre mode de déclaration de rétractation est accepté. Il doit être dénué d'ambiguïté et exprimer la volonté de se rétracter.</p>
            <p class="description">En cas d'exercice du droit de rétractation dans le délai susvisé, sont remboursés le prix du ou des produit(s) acheté(s) et les frais de livraison sont rembourses.</p>
            <p class="description">Les frais de retour sont a la charge de l'acheteur.</p>
            <p class="description">L'échange (sous réserve de disponibilité) ou le remboursement sera effectué dans un délai de 14 jours hors produits personnalisés (non échangeable) , et au plus tard, dans le délai de 14 jours à compter de la réception, par le vendeur, des produits retournés par l'acheteur dans les conditions prévues ci-dessus</p>

            <p class="sous-titre">Exceptions</p>
            <p class="description">Selon l'article L221-28 du Code de la consommation, le droit de rétractation ne peut pas être exercé pour les contrats :</p>
            <li>De fourniture de biens dont le prix dépend de fluctuations sur le marche financier echappant au controle du professionnel et susceptibles de se produire pendant le délai de rétractation</li>
            <li>De fourniture de biens confectionnés selon les spécifications du consommateur ou nettement personnalisés</li>
            <li>De fourniture de biens susceptibles de se détériorer ou de se perimer rapidement</li>
            <li>De fourniture de biens qui ont été descellés par le consommateur après la livraison et qui ne peuvent être renvoyés pour des raisons d'hygiène ou de protection de la santé</li>

            <p class="titre">Article 13 - Propriété intellectuelle</p>
            <p class="description">Le contenu du site internet reste la propriété du vendeur, seul titulaire des droits de propriété intellectuelle sur ce contenu.</p>
            <p class="description">Les acheteurs s'engagent à ne faire aucun usage de ce contenu. Toute reproduction totale ou partielle de ce contenu est strictement interdite et est susceptible de constituer un délit de contrefacon.</p>

            <p class="titre">Article 14 - Informatiques et Libertés</p>
            <p class="description">Les données nominatives fournies par l'acheteur sont nécessaires au traitement de sa commande et à l'établissement des factures.</p>
            <p class="description">Elles peuvent etre communiquées aux partenaires du vendeur chargés de l'exécution. du traitement. de la gestion et du paiement des commandes.</p>
            <p class="description">L'acheteur dispose d'un droit d'accès permanent, de modification, de rectification et d'opposition s'agissant des informations le concernant. Ce droit peut être exercé dans les conditions et selon les modalités définies sur le site Cameli Melo.</p>

            <p class="titre">Article 15 - Loi applicable</p>
            <p class="description">Les présentes conditions générales sont soumises à l'application du droit français. Le tribunal compétent est le tribunal judiciaire.</p>
            <p class="description">Il en est ainsi pour les règles de fond comme pour les règles de forme. En cas de litige ou de réclamation, l'acheteur s'adressera en priorité au vendeur pour obtenir une solution amiable.</p>


            <p class="titre">Article 16 - Protection des données personnelles</p>
            <p class="sous-titre">Données collectées</p>
            <p class="description">Les données à caractère personnel qui sont collectées sur ce site sont les suivantes :</p>
            <li>Lors de la création du compte de l'utilisateur : nom, prénom, adresse email</li>
            <li>Lors de la connexion de l'utilisateur au site web, celui-ci enregistre : nom, prénom, données de connexion</li>
            <li>Lors de la validation d'une commande : nom, prenom, adresse postale, adresse email, numero de téléphone, compte instagram</li>
            <li>Les cookies sont utilisés dans le cadre de l'utilisation du site. L'utilisateur a la possibilité de désactiver les cookies à partir des parametres de son navigateur.</li>

            <p class="sous-titre">Utilisation des données personnelles</p>
            <p class="description">Les données personnelles collectees auprès des utilisateurs ont pour obiectif la mise à disposition des services du site web, leur amélioration et le maintien d'un environnement sécurisé. Plus précisément, les utilisations sont les suivantes :</p>
            <li>Acces et utilisation du site web par l'utilisateur</li>
            <li>Gestion du fonctionnement et optimisation du site web</li>
            <li>Organisation des conditions d'utilisation des Services de paiement</li>
            <li>Vérification, identification et authentification des données transmises par l'utilisateur</li>

            <p class="sous-titre">Partage des données personnelles avec des tiers</p>
            <p class="description">Les données personnelles peuvent être partagées avec des sociétés tierces, dans les cas suivants :</p>
            <li>Lorsque l'utilisateur utilise les services de paiement, pour la mise en oeuvre de ces services, le site web est en relation avec des sociétes bancaires et financières tierces avec lesquelles elle a passé des contrats</li>
            <li>Si la loi l'exige, le site web peut effectuer la transmission de données pour donner suite aux réclamations presentées contre le site web et se conformément aux procédures administratives et judiciaires</li>
            <li>Si le site web est impliquée dans une opération de fusion. acquisition, cession d'actis ou procédure de redressement judiciaire, elle pourra être amenée à céder ou partager tout ou partie de ses actifs, y compris les données à caractère personnel. Dans ce cas, les utilisateurs seraient informés, avant que les données à caractère personnel ne soient transterées à une tierce partie.</li>

            <p class="sous-titre">Sécurité et confidentialité</p>
            <p class="description">Le site web met en oeuvre des mesures organisationnelles, techniques, logicielles et physiques en matière de sécurité du numérique pour protéger les données personnelles contre les altérations, destructions et accès non autorisés. Toutefois, il est à signaler qu'internet n'est pas un environnement complètement sécurisé et le site web ne peut pas garantir la sécurité de la transmission ou du stockage des informations sur internet.</p>

            <p class="sous-titre">Mise en oeuvre des droits des utilisateurs</p>
            <p class="description">En application de la réglementation applicable aux données à caractère personnel, les utilisateurs disposent des droits suivants, qu'ils peuvent exercer en faisant leur demande à l'adresse suivante : camelimelo.creation@gmail.com.</p>
            <li>Le droit d'accès : ils peuvent exercer leur droit d'accès, pour connaître les données personnelles les concernant. Dans ce cas, avant la mise en œuvre de ce droit, le site web peut demander une preuve de l'identité de l'utilisateur afin d'en vérifier l'exactitude.</li>
            <li>Le droit de rectification : si les données à caractère personnel détenues par le site web sont inexactes, ils peuvent demander la mise à jour des informations.</li>
            <li>Le droit de suppression des données : les utilisateurs peuvent demander la suppression de leurs données à caractère personnel, conformément aux lois applicables en matière de protection des données.</li>
            <li>Le droit à la limitation du traitement : les utilisateurs peuvent de demander au site web de limiter le traitement des données personnelles conformément aux hypothèses prévues par le RGPD.</li>
            <li>Le droit de s'opposer au traitement des données : les utilisateurs peuvent s'opposer à ce que ses données soient traitées conformément aux hypothèses prévues par le RGPD.</li>
            <li>Le droit à la portabilité : ils peuvent réclamer que le site web leur remette les données personnelles qui lui sont fournies pour les transmettrea un nouveau site web.</li>
            
            <p class="sous-titre">Evolution de la présente clause</p>
            <p class="description">Le site web se réserve le droit d'apporter toute modification à la présente clause relative à la protection des données à caractère personnel à tout moment. Si une modification est apportée à la présente clause de protection des données à caractère personnel, le site web s'engage à publier la nouvelle version sur son site. Le site web informera également les utilisateurs de la modification par messagerie électronique, dans un délai minimum de 15 jours avant la date d'effet. Si l'utilisateur n'est pas d'accord avec les termes de la nouvelle rédaction de la clause de protection des données à caractère personnel, il a la possibilité de supprimer son compte.</p>
        </div>
    </div>
</template>

<script>

export default {
    name: 'ConditionGeneraleView',
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";

.titre-principal {
    font-size:25px;
    color: $primary-color;
    text-align: center;
    font-weight:650;
}
.titre {
    font-size: 20px;
    color: $primary-color;
    font-weight:650;
    margin-top:30px;
    margin-bottom:10px;
}

.sous-titre {
    font-size: 17px;
    font-weight:550;
    margin-top:15px;
    margin-bottom:10px;
    text-decoration: underline;
}

.description {
    text-align:justify
}

.container {
    padding-top:50px;
    padding-bottom:50px
}

@media screen and (max-width: 1023px) {
    .container {
        margin-left:30px;
        margin-right:30px;
    }
}
</style>