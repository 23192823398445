<template>
    <div>
        <div class="container">
            <p class="titre-principal" >Retour et remboursement</p>

            <p class="description">Toutes les commandes effectuées sur le site Cameli Melo bénéficient d'un délai de retour de 14 jours à compter de la date de livraison de la commande.</p>
            <br/>
            <p class="description">Pour pouvoir bénéficier d’un retour, votre article doit être inutilisé et dans le même état où vous l’avez reçu. Il doit être également dans l’emballage d’origine.</p>
            <p class="description">Les articles personnalisés ne peuvent pas être retournés.</p>
            <br/>
            <p class="description">Pour effectuer un retour vous devez faire la demande à l'adresse email suivante accompagné du numéro de commande : camelimelo.creation@gmail.com. Une réponse vous sera alors faite pour vous confirmer le retour et les instructions à suivre.</p>
            <p class="description">Les retours sont à effectuer dans leur état d'origine et complets permettant leur recommercialisation à l'état neuf. Les produits endommagés, salis ou incomplets ne sont pas repris.</p>
            <br/>
            <p class="description">Les frais d'expéditions ne sont pas remboursés et les frais de retour sont à la charge de l'acheteur.</p>
            <p class="description">Le remboursement sera effectué dans un délai de 14 jours à compter de la réception des produits retournés par l'acheteur dans les conditions prévues ci-dessus.</p>
        </div>
    </div>
</template>


<script>
export default {
    name: 'RetourRemboursementView',
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.titre-principal {
    margin-bottom:30px;
    font-size:25px;
    color: $primary-color;
    text-align: center;
    font-weight:650;
}
.description {
    text-align:justify
}

.container {
    padding-top:50px;
    padding-bottom:50px
}

@media screen and (max-width: 1023px) {
    .container {
        margin-left:30px;
        margin-right:30px;
    }
}
</style>