<template>
    <div>
        <div class="container">
            <p class="titre-principal">Mentions Légales</p>
            <p class="description">Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour la confiance en l'économie numérique, il est précisé aux utilisateurs du site Cameli Melo l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi.</p>
            
            <p class="titre">Edition du site </p>
            <p class="description">Le présent site, accessible à l’URL https://camelimelo.fr, est édité par :</p>
            <p class="description">Camille BELLOTTI, résidant 2 rue alexandre dumas, appartement 213, 59155 FACHES THUMESNIL, de nationalité Française (France), né(e) le 25/04/1997,  ainsi qu'au R.M. sous le numéro 947 623 666 RM 59.</p>
            <p class="description">SIRET : 94762366600015.</p>

            <p class="titre">Hébergement</p>
            <p class="description">Le Site est hébergé par la société OVH SAS, situé 2 rue Kellermann - BP 80157 - 59053 Roubaix Cedex 1, (contact téléphonique ou email : 1007).</p>
            
            <p class="titre">Directeur de publication </p>
            <p class="description">Le Directeur de la publication du Site est Cyril BELLOTTI.</p>

            <p class="titre">Nous contacter </p>
            <p class="description">Par email : bellotticyril@gmail.com</p>
            <p class="description">Par courrier : 2 rue alexandre dumas, appartement 213, 59155 FACHES THUMESNIL</p>

            <p class="titre">Données personnelles</p>
            <p class="description">Le traitement de vos données à caractère personnel est régi par notre Charte du respect de la vie privée, disponible depuis la section "Confidentialité", conformément au Règlement Général sur la Protection des Données 2016/679 du 27 avril 2016 («RGPD»).</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MentionsLegalesView',
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.titre-principal {
    font-size:25px;
    color: $primary-color;
    text-align: center;
    font-weight:650;
    margin-bottom:30px
}
.titre {
    font-size: 20px;
    color: $primary-color;
    font-weight:650;
    margin-top:30px;
    margin-bottom:10px;
}

.description {
    text-align:justify
}

.container {
    padding-top:50px;
    padding-bottom:50px
}

@media screen and (max-width: 1023px) {
    .container {
        margin-left:30px;
        margin-right:30px;
    }
}
</style>