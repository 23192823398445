<template>
    <div v-if="produit && produit.type" class="product-page">  
        <div class="container">
            <div class="return" v-on:click="backToProductPage()">
                <b-icon icon="chevron-left" size="is-small"></b-icon>
                Précédent
            </div>
            <div class="columns">
                <div class="column is-7" v-if="produit.images && produit.images.length">
                    <product-images :images="produit.images"/>
                </div>
                <div class="column is-5">
                    <product-informations :produit="produit" :taille="taille"/>
                    <bandana
                        v-if="
                            (produit.type == 'bandana' ||
                            produit.type == 'bandana_prenom' ||
                            produit.type == 'bandana_texte' ||
                            produit.type == 'bandana-reversible' ||
                            produit.type == 'bandana-reversible-noel' ||
                            produit.type == 'bandana-noel' ||
                            produit.type == 'noeud' ||
                            produit.type == 'noeud-noel' ||
                            produit.type == 'chouchou') && !produit.afficher_inactif
                        "
                        :type="produit.type"
                        :prix="produit.prix"
                        :tissus="produit.tissus"
                        @updatePrice="updatePrice"
                        @goToInformation="goToInformation"
                    />
                    <sac-banane
                        v-if="
                            (produit.type == 'sac-banane') && !produit.afficher_inactif
                        "
                        :type="produit.type"
                        :prix="produit.prix"
                        @updatePrice="updatePrice"
                        @goToInformation="goToInformation"
                    />
                    <collier
                        v-else-if="(produit.type == 'collier') && !produit.afficher_inactif"
                        :type="produit.type"
                        :prix="produit.prix"
                        :tissus="produit.tissus"
                        @updatePrice="updatePrice"
                        @goToInformation="goToInformation"
                    />
                    <fouille
                        v-else-if="(produit.type == 'balle-fouille') && !produit.afficher_inactif"
                        :type="produit.type"
                        :prix="produit.prix"
                        :tissus="produit.tissus"
                        @updatePrice="updatePrice"
                        @goToInformation="goToInformation"
                    />
                    <tote-bag
                        v-else-if="(produit.type == 'tote-bag') && !produit.afficher_inactif"
                        :type="produit.type"
                        :prix="produit.prix"
                        @updatePrice="updatePrice"
                        @goToInformation="goToInformation"
                    />
                    <cotons
                        v-else-if="(produit.type == 'cotons') && !produit.afficher_inactif"
                        :type="produit.type"
                        :prix="produit.prix"
                        :tissus="produit.tissus"
                        @updatePrice="updatePrice"
                        @goToInformation="goToInformation"
                    />
                    <housse
                        v-else-if="
                            (produit.type == 'livret' ||
                            produit.type == 'carnet' ||
                            produit.type == 'passeport') && !produit.afficher_inactif
                        "
                        :type="produit.type"
                        :prix="produit.prix"
                        :tissus="produit.tissus"
                        @goToInformation="goToInformation"
                    />
                    <unique
                        v-else
                        :type="produit.type"
                        :prix="produit.prix"
                        @goToInformation="goToInformation"
                    >
                    </unique>
                </div>
            </div>
        </div>
        <div class="product-more-details">
            <div class="container">
                <product-more-informations
                    id="information"
                    :activeTabFromParent='activeTab'
                    @updateActiveTab="updateActiveTab"
                    :type="produit.type"
                    :descriptions="produit.descriptions"
                    :produit="produit"
                    :tissus='produit.tissus'
                />
            </div>
        </div>
    </div>
</template>

<script>
import ProductMoreInformations from '@/components/page/product-details/ProductMoreInformations.vue'
import ProductInformations from '@/components/page/product-details/ProductInformations.vue'
import ProductImages from '@/components/page/product-details/ProductImages.vue'
import Bandana from '@/components/page/product-details/product-type/Bandana.vue'
import SacBanane from '@/components/page/product-details/product-type/SacBanane.vue'
import Collier from '@/components/page/product-details/product-type/Collier.vue'
import Fouille from '@/components/page/product-details/product-type/Fouille.vue'
import Housse from '@/components/page/product-details/product-type/Housse.vue'
import Cotons from '@/components/page/product-details/product-type/Cotons.vue'
import Unique from '@/components/page/product-details/product-type/Unique.vue'
import ToteBag from '@/components/page/product-details/product-type/ToteBag.vue'

export default {
    components: {
        ProductMoreInformations,
        ProductInformations,
        ProductImages,
        Bandana,
        SacBanane,
        Collier,
        Cotons,
        Housse,
        Fouille,
        Unique,
        ToteBag
    },
    name: 'ProductDetailsView',
    data() {
        return {
            produit: {},
            activeTab: 0,
            taille: 'extra-small'
        }
    },
    computed: {
        produits: {
            get() {
                return this.$store.getters["produits"];
            }
        }
    },
    mounted() {
        this.loadProduct(this.$route.name)
    },
    watch: {
        $route (to, from) {
            if (from.name == to.name) {
                return;
            }
            this.taille = 'extra-small'
            this.activeTab = 0
            this.loadProduct(to.name)
        }
    },
    methods: {
        async loadProduct(routeName) {
            if (routeName == 'carnet' || routeName == 'passeport' || routeName == 'livret' || routeName == 'balle-fouille' || routeName == "tote-bag") {
                this.taille = 'normal'
            }
            if (routeName == 'cotons') {
                this.taille = 'lot4'
            }
            if (routeName == 'sac-banane') {
                this.taille = 'sangle-interchangeable'
            }
            this.produit = this.produits.find(produit => {
                return produit.type == routeName
            })
            if (this.produit.insta_uniquement) {
                this.taille = 'normal'
            }
        },
        goToInformation(value) {
            this.activeTab = value
        },
        updateActiveTab(valueFromChild) {
            if (this.activeTab != valueFromChild) {
                this.activeTab = valueFromChild
            }
        },
        backToProductPage() {
            this.$router.push(`/produits`)
        },
        updatePrice(value) {
            this.taille = value
        }
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.product-page {
    padding-top:50px
}

.product-more-details {
    background-color: $secondary-color;
    padding-top:20px;
    margin-top:30px;
    padding-bottom:50px
}

.return {
    cursor: pointer;
    font-weight: 500;
    line-height: 25px;
    font-size: 15px;
    margin-bottom:18px;
}


@media screen and  (max-width: 1407px) {
    .product-page {
        padding-top:25px
    }
}


@media screen and  (max-width: 1023px) {
    .container {
        margin-left: 30px;
        margin-right: 30px;
    }
}
</style>
