<template>
    <div class="container">
        <div class="columns is-multiline">
            <div class="column is-6 container-image desktop">
                <img class="presentation-image" src="@/assets/img/transforme/presentation-camille.webp" alt="présentation de Camille">
            </div>
            <div class="column is-6">
                <p class="presentation-title">Qui se cache derrière Cameli Melo ?</p>
                <p class="presentation-description">Hello, moi c’est Camille, ou plutôt Cam pour les intimes. J’ai 25 ans, j’habite dans la métropole lilloise et je fais 1m54 (oui oui ce détail est très important … enfin, c’est ce que j’ai fini par croire puisque depuis mon plus jeune âge on me taquine sur ma taille). </p>
                <p class="presentation-description">Blague à part, depuis l’enfance j’aime utiliser mes petites mains pour créer des choses diverses et variées, mais malheureusement, par manque de temps pour mes études, j’ai laissé ma créativité de côté. </p>
                <p class="presentation-description">Depuis que ma chienne Stella a rejoint ma vie, j’ai retrouvé la motivation de faire des choses qui me plaisent. </p>
                <p class="presentation-description">En décembre 2021, le père noël m’a apporté sous le sapin une jolie machine à coudre. Vous imaginez bien la suite de l’histoire, j’ai tout de suite accroché ! J’adore pouvoir confectionner moi-même selon mes humeurs et mes envies.</p>
                <p class="presentation-description">C’est pour cela que j’ai décidé de créer mon compte instagram pour vous partager un méli mélo de mes créations.</p>
                <p class="presentation-description">C’est ainsi que commence l’aventure Cameli Melo !</p>
            </div>
            <div class="column is-6 container-image mobile">
                <img class="presentation-image center-block" src="@/assets/img/transforme/presentation-camille.webp" alt="présentation de Camille">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PresentationCamille'
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.presentation-title {
    font-size:$text-title-size;
    font-weight: 600;
    margin-bottom: 25px;
}

.presentation-description {
    margin-bottom: 20px;
    text-align: justify;
}

.presentation-image {
    max-height: 32rem;
}

.container-image {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobile {
    display:none;
}

@media screen and (max-width: 1023px) {
    .container {
        margin-left:30px;
        margin-right:30px;
    }
}

@media screen and (max-width: 768px) {
    .desktop {
        display:none;
    }

    .mobile {
        display:block;
    }
}
</style>
