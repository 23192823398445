<template>
    <div>
        <div class="container">
            <p class="title has-text-centered">Mon panier</p>
            <div v-if="panier.quantite">
                <div class="column-panier-desktop">
                    <div class="columns">
                        <div class="column is-9">
                            <div class="columns panier-header-columns">
                                <div class="column is-8 remove-padding-bottom">
                                    <p>Produits</p>
                                </div>
                                <div class="column is-2 remove-padding-bottom">
                                    <p>Quantité</p>
                                </div>
                                <div class="column is-2 remove-padding-bottom">
                                    <p>Prix total</p>
                                </div>
                            </div>
                            <hr>
                            <div class="remove-padding-bottom" v-for="produit in panier.produits" :key="produit.key">
                                <panier-product :produit="produit" />
                                <hr/>
                            </div>
                        </div>
                        <div class="column is-3">
                            <panier-detail />
                        </div>
                    </div>
                </div>
                <div class="column-panier-mobile">
                    <div class="columns">
                        <div class="column is-5">
                            <panier-detail />
                        </div>
                        <br/>
                        <div class="column is-7">
                            <div class="columns">
                                <div class="column panier-header-columns" >
                                    <p>Produits</p>
                                </div>
                            </div>
                            <div class="remove-padding-bottom" v-for="produit in panier.produits" :key="produit.key">
                                <panier-product :produit="produit" />
                                <hr/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <p class="has-text-centered panier-empty-text">Vous ne disposez d'aucun produit dans votre panier pour le moment</p>
                <b-button class="go-to-product-button is-primary" tag="router-link" :to="{ path: '/produits' }">Voir les produits</b-button>
            </div>
        </div>
    </div>
</template>
  
<script>
import { mapState } from 'vuex'
import PanierProduct from '@/components/page/panier/PanierProduct.vue';
import PanierDetail from '@/components/page/panier/PanierDetail.vue';
import { commandeAnnule } from '@/services/PaiementService'

export default {
    name: 'PanierView',
    components: {
        PanierProduct,
        PanierDetail,
    },
    computed: {
        ...mapState(['panier', 'commande'])
    },
    mounted() {
        if (this.commande) {
            commandeAnnule(this.commande.reference.objectId)
            this.$store.commit('cleanCommande')
        }
    }
}
</script>
  
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.column-panier-mobile {
    display:none;
}

@media screen and (max-width: 1023px) {
    .container {
        margin-left:25px;
        margin-right: 25px
    }

    .column-panier-desktop {
        display: none
    }

    .column-panier-mobile {
        display:block;
    }
}

.title {
    padding-top:50px;
    padding-bottom:30px;
    color: $primary-color;
    font-weight: 750;
    font-size:40px;
}

hr {
    margin-top:20px;
}

.column-panier-mobile {
    hr {
        margin-top:25px
    }
}

.panier-empty-text {
    font-size:20px;
    font-weight: 700;
}

.go-to-product-button {
    margin-top:25px;
    margin-bottom:50px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 250px;
}

.remove-padding-bottom {
    padding-bottom:0;
}

.panier-header-columns {
    font-weight: 700;
}
</style>
  