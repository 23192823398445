<template>
    <a class="user-button">
        <div class="user-container">
            <b-icon icon="account-circle" class="user-icon icon-navbar"></b-icon>
        </div>
    </a>
</template>

<script>
export default {
    name: 'UserButton',
    methods: {
        goToUser() {
            this.$router.push('/profil')
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/scss/variables.scss";

.user-icon {
  padding-top: 20px;
  color: #4a4a4a;
}


.user-icon:hover {
  color: #4a4a4ae7 !important;
}

.user-button:hover {
    cursor:pointer;
}
</style>
