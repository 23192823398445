<template>
    <div class="product-card center-block" v-on:click="goToProductDetail(produit.type)">
        <div class="product-images">
            <img v-if="produit.images_presentation.face.includes('http')" class="product-image" :src="produit.images_presentation.face">
            <img v-else class="product-image" :src="require(`@/assets/img/produits/${produit.images_presentation.face}`)">
            <img v-if="produit.images_presentation.verso.includes('http')" class="product-image-hover" :src="produit.images_presentation.verso">
            <img v-else class="product-image-hover" :src="require(`@/assets/img/produits/${produit.images_presentation.verso}`)">
        </div>
        <div class="product-content">
            <p class="product-title">{{produit.titre_navbar}}</p>
            <p class="product-price" v-if="Object.values(produit.prix).length == 1">{{produit.prix_minimum}} €</p>
            <p class="product-price" v-else>à partir de {{produit.prix_minimum}} €</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProductCard',
    props: ['produit'],
    methods: {
        goToProductDetail(type) {
            this.$router.push(`/produits/${type}`)
        }
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.product-card {
    border-radius: 20px;
}

.product-images {
    position: relative;
    padding-bottom:325px
}

@media screen and (max-width: 1407px) {
    .product-images {
        padding-bottom:275px
    }
}

@media screen and (max-width: 1215px) {
    .product-images {
        padding-bottom:225px
    }
}

@media screen and (max-width: 968px) {
    .product-images {
        padding-bottom:210px
    }
}

@media screen and (max-width: 935px) {
    .product-images {
        padding-bottom:200px
    }
}

@media screen and (max-width: 875px) {
    .product-images {
        padding-bottom:190px
    }
}

@media screen and (max-width: 850px) {
    .product-images {
        padding-bottom:180px
    }
}

@media screen and (max-width: 825px) {
    .product-images {
        padding-bottom:170px
    }
}

@media screen and (max-width: 800px) {
    .product-images {
        padding-bottom:160px
    }
}

.product-image {
    position:absolute;
    -webkit-transition: 1s;
    transition: 1s;
    border-radius: 20px;
}

.product-image-hover {
    position:absolute;
    opacity: 0;
    -webkit-transition: 1s;
    transition: 1s;
    border-radius: 20px;
}

.product-card:hover {
    cursor: pointer;
    box-shadow: 1px 1px 5px $grey-light; 
    transition: ease .5s;

    .product-image {
        opacity: 0;
        pointer-events: none; 
        border-radius: 20px 20px 0 0 ;
    }

    .product-image-hover {
        opacity: 1;
        pointer-events: none; 
        border-radius: 20px 20px 0 0 ;
    }
}

.product-content {
    padding: 10px;
    position: relative;
    text-align: center;
    font-size:20px;
    font-weight: 700;
}

.product-price {
    color: $primary-color;
    font-weight: 750;
    font-size:17px;
}

@media screen and (max-width: 768px) {
    .product-card {
        max-width: 250px;
    }

    .product-images {
        padding-bottom:250px
    }
}
</style>
