import Parse from "parse"

export async function getProduits() {
  try {
    const Produit = Parse.Object.extend("produit")
    const produitQuery = new Parse.Query(Produit);
    produitQuery.equalTo("actif", true);
    produitQuery.ascending("ordre")
    const results = await produitQuery.find();
    const produits = await Promise.all(results.map(produit => formatImagesProduit(produit)))
    // produits.push({
    //   "descriptions": [
    //     "Il n’y a pas de temps idéal pour rayonner et avoir la classe en balade. Qui dit changement de saison, dit changement de tenue. Continuer à sublimer le style de votre chien avec ma nouvelle collection d’automne de bandanas réversibles !",
    //     "Le bandana est l’accessoire idéal pour habiller votre animal simplement, sans le gêner, mais tout en sortant du lot. Le côté réversible de cette nouvelle collection vous permet de changer de style en un claquement de doigt. Simple, rapide et efficace. ",
    //     "Glissez le bandana sur le collier de votre animal, et hop le tour est joué. Rien de plus simple !", "Ce produit est disponible en 5 tailles. L’idéal est que la longueur du bandana fasse la moitié du tour de cou de votre animal. Pour sélectionner votre taille, référez-vous au guide des tailles.",
    //     "Vous êtes une dogmum ou une dogcat, vous souhaitez être assorti à votre animal. Optez pour l’option chouchou. "
    //   ],
    //   "caracteristiques": [
    //     "Les bandanas Cameli Melo sont à glisser dans le collier de votre animal.",
    //     "Une ouverture entre 2 et 4 cm est prévue à cet effet en fonction de la taille choisie."
    //   ],
    //   "prix": {
    //     "extra-small": 8,
    //     "small": 9,
    //     "medium": 10,
    //     "large": 11,
    //     "extra-large": 12,
    //     "extra-small-texte": 8,
    //     "small-texte": 11,
    //     "medium-texte": 12,
    //     "large-texte": 13,
    //     "extra-large-texte": 14
    //   },
    //   "id_stripe": {
    //     "extra-small": "price_1NydXFA8BrUF7CsjQ6p3l1ws",
    //     "small": "price_1NydXNA8BrUF7CsjJw4DFpRW",
    //     "medium": "price_1NydXWA8BrUF7CsjXDPS3BbY",
    //     "large": "price_1NydXeA8BrUF7Csj1ohqWUpy",
    //     "extra-large": "price_1NydXpA8BrUF7CsjMA9YtHfJ",
    //     "extra-small-texte": "erge",
    //     "small-texte": "egr",
    //     "medium-texte": "gegr",
    //     "large-texte": "tgrtg",
    //     "extra-large-texte": "tttt"
    //   },
    //   "images": [
    //     "bandana/bandana-reversible-1.webp",
    //     "bandana/banadana-reversible-2.webp",
    //     "bandana/reversible-violine.webp",
    //     "bandana/reversible-vert.webp",
    //     "bandana/bandana-reversible-1.webp"
    //   ],
    //   "tissus":[
    //     {"name":"Terracotta","id":"terracotta"},
    //     {"name":"Vert","id":"vert"},
    //     {"name":"Vieux Rose","id":"vieux-rose"},
    //     {"name":"Violine","id":"violine"},
    //     {"name":"Marron","id":"marron"}
    //   ],
    //   "images_presentation":{
    //     "face":"bandana/bandana-reversible-1.webp",
    //     "verso": "bandana/banadana-reversible-2.webp",
    //     "caracteristique": "transforme/multi-bandana.webp"
    //   },
    //   "guide_taille": {
    //     "textes_droite": [
    //       "Pour choisir la bonne taille, prenez la mesure du tour de cou de votre animal à l’aide d’un mètre ruban.",
    //       "L’idéal est que la longueur du bandana fasse la moitié du tour de cou de votre animal. Si votre animal a les poils longs ou qu’il se situe entre deux tailles, choisissez plutôt la taille au-dessus.",
    //       "Ces différentes tailles vous permettent de trouver une taille adaptée à chaque type de chien."
    //     ],
    //     "tableau": {
    //       "colonnes": ["Taille", "Longueur x Hauteur"],
    //       "lignes": [
    //         ["XS", "15 cm x 9 cm"],
    //         ["S", "20 cm x 13 cm"],
    //         ["M", "25 cm x 16 cm"],
    //         ["L", "30 cm x 18 cm"],
    //         ["XL", "35 cm x 22 cm"]
    //       ] 
    //     },
    //     "image": "transforme/dimension-bandana.webp" 
    //   },
    //   "type": "bandana-reversible-noel",
    //   "titre": "Bandana réversible",
    //   "actif": true,
    //   "categorie": "collection_noel",
    //   "ordre": 0,
    //   "titre_navbar": "Bandana réversible",
    //   "prix_minimum": 8,
    //   "afficher_inactif": false,
    //   "createdAt": "2023-10-07T16:24:31.125Z",
    //   "updatedAt": "2023-10-07T16:46:49.586Z"
    // })
    // produits.push({
    //   "descriptions": [
    //     "Il n’y a pas de temps idéal pour rayonner et avoir la classe en balade. Qui dit changement de saison, dit changement de tenue. Continuer à sublimer le style de votre chien avec ma nouvelle collection d’automne de bandanas réversibles !",
    //     "Le bandana est l’accessoire idéal pour habiller votre animal simplement, sans le gêner, mais tout en sortant du lot. Le côté réversible de cette nouvelle collection vous permet de changer de style en un claquement de doigt. Simple, rapide et efficace. ",
    //     "Glissez le bandana sur le collier de votre animal, et hop le tour est joué. Rien de plus simple !", "Ce produit est disponible en 5 tailles. L’idéal est que la longueur du bandana fasse la moitié du tour de cou de votre animal. Pour sélectionner votre taille, référez-vous au guide des tailles.",
    //     "Vous êtes une dogmum ou une dogcat, vous souhaitez être assorti à votre animal. Optez pour l’option chouchou. "
    //   ],
    //   "caracteristiques": [
    //     "Les bandanas Cameli Melo sont à glisser dans le collier de votre animal.",
    //     "Une ouverture entre 2 et 4 cm est prévue à cet effet en fonction de la taille choisie."
    //   ],
    //   "prix": {
    //     "extra-small": 8,
    //     "small": 9,
    //     "medium": 10,
    //     "large": 11,
    //     "extra-large": 12,
    //     "extra-small-texte": 8,
    //     "small-texte": 11,
    //     "medium-texte": 12,
    //     "large-texte": 13,
    //     "extra-large-texte": 14
    //   },
    //   "id_stripe": {
    //     "extra-small": "price_1NydXFA8BrUF7CsjQ6p3l1ws",
    //     "small": "price_1NydXNA8BrUF7CsjJw4DFpRW",
    //     "medium": "price_1NydXWA8BrUF7CsjXDPS3BbY",
    //     "large": "price_1NydXeA8BrUF7Csj1ohqWUpy",
    //     "extra-large": "price_1NydXpA8BrUF7CsjMA9YtHfJ",
    //     "extra-small-texte": "erge",
    //     "small-texte": "egr",
    //     "medium-texte": "gegr",
    //     "large-texte": "tgrtg",
    //     "extra-large-texte": "tttt"
    //   },
    //   "images": [
    //     "bandana/bandana-reversible-1.webp",
    //     "bandana/banadana-reversible-2.webp",
    //     "bandana/reversible-violine.webp",
    //     "bandana/reversible-vert.webp",
    //     "bandana/bandana-reversible-1.webp"
    //   ],
    //   "tissus":[
    //     {"name":"Terracotta","id":"terracotta"},
    //     {"name":"Vert","id":"vert"},
    //     {"name":"Vieux Rose","id":"vieux-rose"},
    //     {"name":"Violine","id":"violine"},
    //     {"name":"Marron","id":"marron"}
    //   ],
    //   "images_presentation":{
    //     "face":"bandana/bandana-reversible-1.webp",
    //     "verso": "bandana/banadana-reversible-2.webp",
    //     "caracteristique": "transforme/multi-bandana.webp"
    //   },
    //   "guide_taille": {
    //     "textes_droite": [
    //       "Pour choisir la bonne taille, prenez la mesure du tour de cou de votre animal à l’aide d’un mètre ruban.",
    //       "L’idéal est que la longueur du bandana fasse la moitié du tour de cou de votre animal. Si votre animal a les poils longs ou qu’il se situe entre deux tailles, choisissez plutôt la taille au-dessus.",
    //       "Ces différentes tailles vous permettent de trouver une taille adaptée à chaque type de chien."
    //     ],
    //     "tableau": {
    //       "colonnes": ["Taille", "Longueur x Hauteur"],
    //       "lignes": [
    //         ["XS", "15 cm x 9 cm"],
    //         ["S", "20 cm x 13 cm"],
    //         ["M", "25 cm x 16 cm"],
    //         ["L", "30 cm x 18 cm"],
    //         ["XL", "35 cm x 22 cm"]
    //       ] 
    //     },
    //     "image": "transforme/dimension-bandana.webp" 
    //   },
    //   "type": "noeud-noel",
    //   "titre": "Noeud",
    //   "actif": true,
    //   "categorie": "collection_noel",
    //   "ordre": 0,
    //   "titre_navbar": "Noeud",
    //   "prix_minimum": 8,
    //   "afficher_inactif": false,
    //   "createdAt": "2023-10-07T16:24:31.125Z",
    //   "updatedAt": "2023-10-07T16:46:49.586Z"
    // })
    return produits
  }
  catch (e) {
    throw new Error("Error in getProduits function: " + e.message)
  }
}

export async function getProduitsType() {
  try {
    const Produit = Parse.Object.extend("produit")
    const produitQuery = new Parse.Query(Produit);
    produitQuery.equalTo("actif", true); ``
    produitQuery.select("type");
    const results = await produitQuery.find();
    return results.map(produit => produit.get("type"));
  }
  catch (e) {
    throw new Error("Error in getProduits function: " + e.message)
  }
}

export async function getCategories() {
  try {
    const Categorie = Parse.Object.extend("categorie");
    const categorieQuery = new Parse.Query(Categorie);
    categorieQuery.ascending("ordre")
    const results = await categorieQuery.find();
    const categories = results.map(categorie => categorie.attributes)
    // categories.push({
    //   css: "odd",
    //   key: "collection_noel",
    //   nom: "Collection de Noël",
    //   ordre: 4
    // })
    return categories
  }
  catch (e) {
    throw new Error("Error in getCategories function: " + e.message)
  }
}

export async function getImages() {
  try {
    const Image = Parse.Object.extend("image");
    const imageQuery = new Parse.Query(Image);
    const results = await imageQuery.find();
    return results.map(image => image.attributes)
  } catch (e) {
    throw new Error("Error in getImages function: " + e.message)
  }
}

export async function getImagesByProduit(produit) {
  try {
    const Image = Parse.Object.extend("image");
    const imageQuery = new Parse.Query(Image);
    imageQuery.equalTo("produit", produit);
    const results = await imageQuery.find();
    return results.map(image => image.attributes)
  } catch (e) {
    throw new Error("Error in getImages function: " + e.message)
  }
}

async function formatImagesProduit(produit) {
  const updatedProduit = { ...produit.attributes }
  const imagesProduit = await getImagesByProduit(updatedProduit.type)
  if (!updatedProduit.images_presentation) {
    if (imagesProduit.length === 1) {
      updatedProduit.images_presentation = {
        face: imagesProduit[0].image._url,
        verso: imagesProduit[0].image._url,
      };
    } else if (imagesProduit.length >= 2) {
      updatedProduit.images_presentation = {
        face: imagesProduit[0].image._url,
        verso: imagesProduit[1].image._url,
      };
    }
  }
  imagesProduit.forEach(e => {
    if (!updatedProduit.images || updatedProduit.images.length == 0) {
      updatedProduit.images = []
    }
    updatedProduit.images.push(e.image._url)
  })
  return updatedProduit
}