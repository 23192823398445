<template>
    <div>
        <div class="container box-1">
            <div class="columns">
                <div class="column is-3">
                    <img class="picto center-block" src="@/assets/img/picto/picto-machine.webp" alt="">
                    <p>Confection entre 1 à 3 semaines suivant les produits</p>
                </div>
                <div class="column is-3">
                    <img class="picto center-block" src="@/assets/img/picto/picto-personnalisation-avec-noeud.webp" alt="">
                    <p>Produits personnalisés</p>
                </div>
                <div class="column is-3">
                    <img class="picto center-block" src="@/assets/img/picto/picto-cb.webp" alt="">
                    <p>Paiement sécurisé</p>
                </div>
                <div class="column is-3">
                    <img class="picto center-block" src="@/assets/img/picto/picto-retrait.webp" alt="">
                    <p>Retrait gratuit à Faches Thumesnil</p>
                </div>
            </div>
        </div>
        <div class="container box-2">
            <div class="columns is-mobile is-multiline">
                <div class="column is-6">
                    <img class="picto center-block" src="@/assets/img/picto/picto-machine.webp" alt="">
                    <p>Confection entre 1 à 3 semaines suivant les produits</p>
                </div>
                <div class="column is-6">
                    <img class="picto center-block" src="@/assets/img/picto/picto-personnalisation-avec-noeud.webp" alt="">
                    <p>Produits personnalisés</p>
                </div>
                <div class="column is-6">
                    <img class="picto center-block" src="@/assets/img/picto/picto-cb.webp" alt="">
                    <p>Paiement sécurisé</p>
                </div>
                <div class="column is-6">
                    <img class="picto center-block" src="@/assets/img/picto/picto-retrait.webp" alt="">
                    <p>Retrait gratuit à Faches Thumesnil</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'InformationsView',
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";
.container {
    padding-top:50px;
    padding-bottom:50px;
}
.picto {
    max-width: 150px;
}

p {
    margin-top:15px;
    text-align: center;
}

.box-2 {
    display: none
}

@media screen and (max-width:768px) {
    .box-1 {
        display: none
    }

    .box-2 {
        display: block
    }

    .container {
        margin-left: 5px;
        margin-right: 5px
    }
}
</style>