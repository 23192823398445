<template>
    <div class="hero is-fullheight background-login">
        <div class="hero-body is-justify-content-center is-align-items-center" style="padding-top:0">            
            <div class="columns is-flex is-flex-direction-column">
                <img @click="goToHome()" class="logo-login center-block" src="@/assets/img/logo/logo-terracota-detoure.webp" alt="logo">
                <div class="box">
                    <a @click="goToPrecedent()" class="link-connexion" style="margin-left:5px">
                        <span class="mdi mdi-chevron-left"></span>
                        <span> Retour</span>
                    </a>
                    <div class="column">
                        <b-field label="Email">
                            <b-input placeholder="Adresse email" class="input-login" v-model="email"></b-input>
                        </b-field>
                    </div>
                    <div class="column">
                        <b-field label="Mot de passe">
                            <b-input placeholder="Mot de passe" class="input-login" v-model="password" type="password"></b-input>
                        </b-field>
                        <p><a href="forget.html" class="is-size-7 link-connexion">Mot de passe oublié ?</a></p>
                    </div>
                    <div class="column">
                        <b-button class="button is-primary is-fullwidth" type="submit" v-on:click="login()">Connexion</b-button>
                        <p class="text-error has-text-centered" v-if="messageErreur">{{messageErreur}}</p>
                    </div>
                    <div class="has-text-centered">
                        <p class="is-size-7">
                            Tu n'as pas encore de compte ? <a v-on:click="goToInscription()" class="link-connexion">M'inscrire</a>
                        </p>
                    </div>
                </div>               
            </div>
        </div>
    </div>
</template>

<script>
import {  logIn } from '@/services/UserService'
import { mapState } from 'vuex'

export default {
    name: 'LoginView',
    data() {
        return {
            email: "",
            password: "",
            messageErreur: "",
            prevRoute: null,
        }
    },
    methods: {
        async login() {
            try {
                const user = await logIn({
                    email: this.email,
                    password: this.password
                })
                this.$store.commit('SET_CURRENT_USER', {
                    id: user.id,
                    email: user.attributes.email,
                    role: user.role,
                    sessionToken: user.attributes.sessionToken,
                })
                if (this.prevRoute.path == '/connexion' || this.prevRoute.path == '/inscription') {
                    this.$router.push('/')
                } else {
                    this.$router.push(this.prevRoute.path)
                }
            }
            catch(e) {
                if (e.message == "Invalid username/password.") {
                    this.messageErreur = "Email ou mot de passe incorrect."
                } else {
                    this.messageErreur = "Erreur interne, veuillez réessayer plus tard."
                }
            }
        },
        goToHome() {
            this.$router.push('/')
        },
        goToInscription() {
            this.$router.push('inscription')
        },
        goToPrecedent() {
            if (this.prevRoute.path == '/connexion' || this.prevRoute.path == '/inscription') {
                this.$router.push('/')
            } else {
                this.$router.push(this.prevRoute.path)
            }
        },
    },
    computed: {
        ...mapState(['currentUser'])
    },
    mounted() {
        if (this.currentUser) {
            this.$router.push('/')
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from
        })
    },
}
</script>

<style lang="scss">
@import "@/assets/scss/variables.scss";

.logo-login {
    width: 10rem !important;
    margin-bottom:50px;
    cursor:pointer;
}

.hero-body {
    padding-top:0
}

.label {
    font-weight: 400;
}

.link-connexion {
    color: $primary-color
}

.background-login {
    background-color: $secondary-color;
}

.link-connexion:hover {
    color:$primary-color-hover
}

.input-login {
    min-width: 420px;
}

@media screen and (max-width: 769px) {
    .input-login {
        min-width: 300px;
    }
}
</style>