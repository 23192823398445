<template>
    <div>
        <b-tabs type="is-toggle" class="products-menu" position="is-centered" v-model="activeTab" :multiline="true" style="max-width: 100%;">
            <b-tab-item class="product-menu-description" label="Description">
                <div class="columns">
                    <div class="column is-7">
                        <div v-for="(description, index) in descriptions" :key="index">
                            <p class="description">{{ description }}</p>
                        </div>
                        <p class="description" style="font-weight: 650; margin-top:20px" v-if="produit.caracteristiques && produit.caracteristiques.length">Caractéristiques : </p>
                        <li v-for="caracteristique in produit.caracteristiques" :key="caracteristique">{{ caracteristique }}</li>
                        <br v-if="produit.caracteristiques && produit.caracteristiques.length"/>
                        <p class="description">Tous les accessoires sont soigneusement cousus à la main, de légères variations de taille ainsi qu’un placement de motif différent présenté en photo sont à prévoir. Ainsi, chaque article est unique !</p>
                        <p class="description" style="font-weight: 650; margin-top:20px" v-if="produit.entretiens && produit.entretiens.length">Conseils d’entretien :</p>
                        <p class="description" v-for="entretien in produit.entretiens" :key="entretien">{{ entretien }}</p>
                    </div>
                    <div class="column is-5 container-image">
                        <img class="center-block" v-if="produit.images_presentation.caracteristique" :src="require(`@/assets/img/${produit.images_presentation.caracteristique}`)" alt="">
                    </div>
                </div>
            </b-tab-item>

            <b-tab-item class="product-menu-couleur" label="Couleurs & tissus" v-if="tissusSort && tissusSort.length">
                <div class="columns is-multiline is-centered" :class="screenSize >= 769 ? '' : 'is-mobile'">
                    <div class="column" :class="screenSize >= 769 ? 'is-1' : 'is-4'" v-for="tissu in tissusSort" :key="tissu.id">
                        <img class="tissu tissu-img center-block" :src="require(`@/assets/img/echantillon/${tissu.id}.webp`)" alt="">
                        <p class="tissu-name">{{tissu.name}}</p>
                    </div>
                </div>
            </b-tab-item>

            <b-tab-item class="product-menu-guide-taille" label="Guilde des tailles" v-if="produit.guide_taille">
                <div class="columns">
                    <div class="column is-6">
                        <img v-if="produit.guide_taille.image" style="border-radius:10px" :src="require(`@/assets/img/${produit.guide_taille.image}`)" alt="">
                        <div v-if="produit.guide_taille.textes_gauche && produit.guide_taille.textes_gauche.length">
                            <p class="description" v-for="texte in produit.guide_taille.textes_gauche" :key="texte">{{ texte }}</p>
                        </div>
                    </div>
                    <div class="column is-6">
                        <div v-if="produit.guide_taille.textes_droite && produit.guide_taille.textes_droite.length">
                            <p class="description" v-for="texte in produit.guide_taille.textes_droite" :key="texte">{{ texte }}</p>
                        </div>
                        <table style="margin-left: auto; margin-right: auto;" v-if="produit.guide_taille.tableau">
                            <tr>
                                <th v-for="colonne in produit.guide_taille.tableau.colonnes" :key="colonne">{{ colonne }}</th>
                            </tr>
                            <tr v-for="ligne in produit.guide_taille.tableau.lignes" :key="ligne[0]">
                                <td v-for="value in ligne" :key="value">{{ value }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </b-tab-item>

            <b-tab-item class="product-menu-guide-taille" label="Délai">
                <p>Le délai de confection est de 1 à 3 semaines suivant le type de produit et le nombre de demande actuel. Pour plus d'informations vous pouvez me contacter sur instagram (@cameli_melo) ou par mail (camelimelo.creation@gmail.com).</p>
                <p>Les produits sont confectionnés à la main et sur demande, il n'y a donc aucun produit prêt à l'avance sauf exception.</p>
            </b-tab-item>
        </b-tabs>
    </div>
</template>

<script>
export default {
    name: 'ProductMoreInformations',
    data() {
        return {
            activeTab: 0,
            screenSize: window.innerWidth,
            tissusSort: null
        }
    },
    props: {
        activeTabFromParent: {type: Number},
        method: { type: Function },
        tissus: {type: Array},
        type: {type: String},
        descriptions: {type: Array},
        produit: {type:Object}
    },
    watch: {
        activeTabFromParent(e) {
            if (this.activeTab != e) {
                this.activeTab = e
            }
            if (this.activeTab == 1 && (!this.tissusSort || this.tissusSort.length != this.tissus.length)) {
                this.tissusSort = this.tissus
                this.tissusSort.sort(this.compare)
            }
        },
        activeTab(newValue) {
            this.$emit('updateActiveTab', newValue);
        },
    },
    methods: {
        compare( a, b ) {
            if ( a.nom < b.nom ){
                return -1;
            }
            if ( a.nom > b.nom ){
                return 1;
            }
            return 0;
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/scss/variables.scss";
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
}

td, th {
  border: 1px solid #ca634750;
  text-align: center !important;
  padding:5px !important;
  width: 200px;
}

tr:nth-child(even) {
  background-color: #ca634750;
}

.block-image-1 {
    padding-right:4px !important;
}

.block-image-2 {
    padding-left:4px !important;
}

.tabs.is-toggle a {
    background-color: white !important;
    width: 200px;
}

.product-menu-description, .product-menu-couleur, .product-menu-guide-taille {
    padding-top:20px
}

.tissu-name {
    text-align: center;
    font-weight:700
}

.tissu-img {
    margin-bottom:10px
}

.select:not(.is-multiple):not(.is-loading)::after {
    border-color: $primary-color !important;
}

.description {
    text-align: justify;
    line-height: 20px;
    margin-bottom:10px;
}

.container-image {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
