<template>
    <div>
        <div class="columns is-multiline caracteristique">
            <div class="column is-6">
                <div class="caracteristique-titre">
                    Tissu haut :
                </div>
                <b-field :type="error && !this.tissuSecondaire ? 'is-danger' : ''">
                    <b-select v-model="tissuSecondaire" placeholder="Choisi un tissu" expanded>
                        <option></option>
                        <option value="Blanc">Blanc</option>
                        <option value="Noir">Noir</option>
                    </b-select>
                </b-field>
            </div>
            <div class="column is-6">
                <div class="caracteristique-titre">
                    Tissu bas:
                    <a class="see-more" href="#information" v-on:click="goToInformation(1)">
                        voir les coloris
                    </a>
                </div>
                <b-field :type="error && !tissuSelected ? 'is-danger' : ''">
                    <b-select v-model="tissuSelected" placeholder="Choisi un tissu" expanded>
                        <option></option>
                        <option v-for="tissu in tissus" :value="tissu" :key="tissu.id">{{tissu.name}}</option>
                    </b-select>
                </b-field>
            </div>
            <div class="column is-6">
                <p class="caracteristique-titre">
                    <span v-if="type != 'livret'">Prénom / texte :</span>
                    <span v-if="type == 'livret'">Texte :</span>
                </p>
                <b-field :type="error && !this.texte ? 'is-danger' : ''">
                    <b-input v-model="texte"></b-input>
                </b-field>
            </div>
            <div class="column is-6">
                <p class="caracteristique-titre">Couleur du texte et du liseré : </p>
                <b-field :type="error && !this.couleurTexte ? 'is-danger' : ''">
                    <b-select v-model="couleurTexte" placeholder="Choisi une couleur" expanded>
                        <option></option>
                        <option value="doré">Doré</option>
                        <option value="argent">Argent</option>
                        <option value="confiance">Je fais confiance</option>
                    </b-select>
                </b-field>
            </div>
        </div>
        <div class="columns is-mobile">
            <div class="column is-4">
                <p class="caracteristique-titre">Quantité : </p>
                <b-field>
                    <b-select v-model="quantite">
                        <option :value=1>1</option>
                        <option :value=2>2</option>
                        <option :value=3>3</option>
                        <option :value=4>4</option>
                        <option :value=5>5</option>
                        <option :value=6>6</option>
                        <option :value=7>7</option>
                        <option :value=8>8</option>
                        <option :value=9>9</option>
                    </b-select>
                </b-field>
            </div>
            <div class="column is-8" v-if="type != 'chouchou'">
                <div class="caracteristique-titre">
                    Ajouter un petit noeud (+1 €)
                </div>
                <b-field style="padding-top:5px">
                    <b-switch
                        v-model="avecNoeud"
                        type="is-terracota">
                    </b-switch>
                </b-field>
            </div>
        </div>
        <b-button type="is-primary" size="is-medium" @click="ajouterPanier()">Ajouter au panier</b-button>
    </div>
</template>

<script>
export default {
    name: 'Housse',
    data() {
        return {
            activeTab: 0,
            tissuSecondaire: "",
            tissuSelected: "",
            texte: "",
            couleurTexte: "",
            quantite: 1,
            avecNoeud: false,
            error: false
        }
    },
    props: ['type', 'prix', 'tissus'],
    methods: {
        updateActiveTab(valueFromChild) {
            if (this.activeTab != valueFromChild) {
                this.activeTab = valueFromChild
            }
        },
        goToInformation(value) {
            this.$emit('goToInformation', value)
        },
        ajouterPanier() {
            this.error = false
            if (!this.tissuSecondaire || !this.tissuSelected || !this.texte || !this.couleurTexte) {
                this.error = true;
                return
            }

            let produit = {
                type: this.type,
                tissuSecondaire: this.tissuSecondaire,
                tissu: this.tissuSelected.id,
                tissuNom: this.tissuSelected.name,
                texte: this.texte,
                couleurTexte: this.couleurTexte,
                prix: this.avecNoeud ? this.prix.avec_noeud : this.prix.normal,
                noeud: this.avecNoeud,
                quantite: this.quantite,
                taille: "normal"
            }

            this.$store.commit('ajouterPanier', produit)

            let message = 'Votre produit a été aujouté au panier'

            if (this.quantite > 1) {
                message = 'Vos produits ont été aujoutés au panier'
            }

            this.quantite = 1
            this.tissuSelected = ""
            this.tissuSecondaire = ""
            this.texte = ""
            this.couleurTexte = ""
            this.$buefy.snackbar.open({
                duration: 5000,
                position: 'is-top',
                message: message,
                actionText: 'Voir mon panier',
                onAction: () => {
                    this.$router.push('/panier')
                }
            })
        }
    },
}
</script>

<style lang="scss">
@import "@/assets/scss/variables.scss";

.caracteristique {
    padding-top:20px;
}

.caracteristique-titre {
    margin-bottom:5px;
    font-weight: 650;
}

.see-more {
    color:$primary-color;
    font-weight: 400;
}

.notices .snackbar {
    background: $primary-color !important;
}

.notices .snackbar .action .button {
    color: $primary-color !important;
    font-weight: 600;
    text-transform: uppercase;
    background: white !important;
}
</style>
