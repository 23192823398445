<template>
  <div @click="toTop()" :class="['back-to-top-btn', {'go-top': scY}]">
    <b-icon class="back-to-top-icon" icon="arrow-up"></b-icon>
  </div>
</template>

<script>
export default {
  name: 'GoToTopButton',
  data() {
    return {
      scTimer: 0,
      scY: 0,
    }
  },
  watch: {
    $route (to, from) {
      if (from.name == to.name) {
        return;
      }
      this.toTopNotSmooth()
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.toTopNotSmooth()
  },
  methods: {
    handleScroll: function () {
      if (this.scTimer) return;
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY;
        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 100);
    },
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
    toTopNotSmooth: function () {
      window.scrollTo({
        top: 0,
      });
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.back-to-top-btn {
    position: fixed;
    cursor: pointer;
    bottom: -100px;
    right: 20px;
    color: white;
    background-color: #CA6347; //$primary-color;
    z-index: 4;
    width: 60px;
    text-align: center;
    height: 60px;
    opacity: 0;
    visibility: hidden;
    border-radius: 50%;
    font-size: 22px;
    transition: .6s;
    overflow: hidden;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);

    &.go-top {
        opacity: 1;
        visibility: visible;
        bottom: 50px;
    }
    &:hover {
        background-color: #CA6347; //$primary-color;
        color: black;
        transition: .6s;
        box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
        transform: translateY(-5px);
    }

    .back-to-top-icon {
        position: absolute;
        right: 0;
        left: 0;
        top: 45%;
        transform: translateY(-45%);
        text-align: center;
        font-size: 30px;
        margin-left: auto;
        margin-right: auto;
    }
}
</style>
