<template>
    <div>
        <div class="container">
            <p class="title has-text-centered">La balle de fouille, l’alternative idéale à la gamelle !</p>

            <div class="columns is-multiline">
                <div class="column is-8">
                    <p class="sous-titre">Qu’est-ce qu’une balle de fouille ? </p>
                    <p class="description">Il s’agit d’un jouet d’occupation, d’intelligence et de stimulation mentale pour chien. Il est composé de plaids polaires circulaires noués entre eux. </p>

                    <p class="sous-titre">Quel est l’objectif de la balle de fouille ? </p>
                    <p class="description">En cachant ses croquettes ou ses friandises préférées, il utilise son flair pour les manger, ce qui lui demande un effort intellectuel. De cette façon, le chien stimule ses sens et allonge son temps de repas. Grâce à la balle de fouille, ses besoins primaires sont respectés ! </p>
                    <p class="description">C’est également un excellent moyen pour aider votre chien à gérer sa frustration, à réduire son stress, à supporter vos absences ainsi qu’à combattre la destruction et l’ennui. La balle peut devenir un vrai déclencheur de calme et d’apaisement.</p>
                    <p class="description">La balle de fouille est considérée comme la version un peu plus difficile <a class="link-page" href="/articles/tapis-fouille">du tapis de fouille</a> car votre animal doit faire rouler la balle pour trouver les endroits où les friandises sont cachées. </p>
                    
                </div>
                <div class="column is-4">
                    <img class="presentation-image center-block" src="@/assets/img/produits/tapis/balle-3.webp" alt="Tapis de fouille">
                </div>
                <div class="column is-5 box-1">
                    <img style="margin-top:40px" class="presentation-image center-block" src="@/assets/img/produits/tapis/balle-2.webp" alt="Tapis de fouille">
                </div>
                <div class="column is-7">
                    <p class="sous-titre">Comment l’utiliser ? </p>
                    <p class="description">Dispersez de la nourriture sèche (croquettes ou friandises de grande taille) à l'intérieur de la balle. 
                    <p class="description">Pour les premières utilisations, encouragez votre chien à les chercher. Félicitez-le quand il les mange pour renforcer ce comportement positif. Il se sentira alors récompensé et encouragé à continuer. </p>
                    <p class="description">Au fur et à mesure, augmentez la difficulté en cachant profondément dans les plis de la balle des friandises de plus petites tailles. Le chien mettra plus de temps à les trouver. Une alimentation plus lente est meilleure pour la digestion. </p>
                    <p class="description">A utiliser sous surveillance.</p>

                    <p class="sous-titre">Comment le laver ? </p>
                    <p class="description">Après chaque utilisation, secouez la balle de fouille pour faire tomber la nourriture restante. Vous pouvez également utiliser une brosse douce. </p>
                    <p class="description">Pour un nettoyage plus en profondeur, lavez-le à la main avec du savon. Vous pouvez le mettre à la machine à laver avec un programme délicat à 30° mais l'option lavage à la main reste fortement recommandée. </p>
                    <p class="description">Pour le séchage, privilégiez un séchage à l’air libre. </p>
                </div>
                <div class="column is-5 box-2">
                    <img class="presentation-image center-block" src="@/assets/img/produits/tapis/balle-2.webp" alt="Tapis de fouille">
                </div>
                <div class="column is-12">
                    <p class="sous-titre">Recommandé par les éducateurs comportementalistes canins en positif : </p>
                    <p class="description">“15 minutes d’activité olfactive équivaut à environ 1 heure d’activité physique. Imaginez donc les perspectives de dépense mentale qui s’offrent à vous juste en changeant la façon de nourrir nos chiens” - Florian, éducateur canin Paws up à Lille.</p>
                </div>
                <div class="column is-12">
                    <img class="presentation-image center-block" src="@/assets/img/produits/tapis/balle-1.webp" alt="Tapis de fouille">
                </div>
            </div>
            
            
            
            </div>
    </div>
</template>
  
<script>
export default {
    name: 'ArticleBalleFouille',
}
</script>
  
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

@media screen and (max-width: 1023px) {
    .container {
        margin-left:25px;
        margin-right: 25px
    }
}

.title {
    padding-top:50px;
    padding-bottom:30px;
    color: $primary-color;
    font-weight: 750;
    font-size:40px;
}

hr {
    margin-top:20px;
}

.description {
    text-align: justify;
    line-height: 20px;
    margin-bottom:10px;
}

.sous-titre {
    font-size: 20px;
    color: $primary-color;
    font-weight:650;
    margin-top:30px;
    margin-bottom:10px;
}

.link-page {
    color: $primary-color;
    font-weight: 650;
    text-decoration: underline
}

.box-2 {
    display: none
}

@media screen and (max-width:768px) {
    .box-1 {
        display: none
    }

    .box-2 {
        display: block
    }
}

.presentation-image {
    max-height: 26rem;
}
</style>
