<template>
    <div v-if="imageLoad">
        <!-- <link rel="preload" as="image" :href="require(`@/assets/img/transforme/nouveaute-header.webp`)"/> -->
        <img class="image-desktop" :src="require(`@/assets/img/transforme/noel/background-noel.webp`)" alt="Image de présentation">
        <b-carousel
            class="image-mobile"
            :indicator="true"
            :indicator-inside="true"
            :interval="6000"
            indicator-mode="hover"
            indicator-position="is-bottom"
            indicator-style="is-lines">
            <b-carousel-item v-for="(carousel, i) in carousels" :key="i">
                <img :src="require(`@/assets/img/${carousel.img}`)" alt="Image de présentation">
            </b-carousel-item>
        </b-carousel>
    </div>

</template>

<script>
export default {
    name: 'PresentationHeader',
    data() {
        return {
            imageLoad: false,
            carousels: [
                { img: 'transforme/noel/back-noel-mobile-1.webp' },
                { img: 'transforme/noel/back-noel-mobile-2.webp' },
                { img: 'transforme/noel/back-noel-mobile-3.webp' },
            ]
        }
    },
    methods: {
        shuffleArray(array) {
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]];
            }
        }
    },
    created() {
        this.shuffleArray(this.carousels);
        this.imageLoad = true;
    }
}
</script>

<style lang="scss">
@import "@/assets/scss/variables.scss";

.image-mobile {
    display:none;
}

.image-desktop {
    width:100%
}

@media screen and (max-width: 769px) {
    .image-mobile {
        display:block;
    }

    .image-desktop {
        display:none;
    }
}

.carousel .carousel-indicator .indicator-item .indicator-style {
    border: 1px solid $primary-color !important;
}
.carousel .carousel-indicator .indicator-item.is-active .indicator-style {
    background: $primary-color !important;
    border: 1px solid white !important;
}
</style>