<template>
    <div>
        <div class="columns is-multiline caracteristique">
            <div class="column is-6">
                <div class="caracteristique-titre">
                    Choix du texte :
                </div>
                <b-field :type="error && !texte ? 'is-danger' : ''">
                    <b-select v-model="texte" placeholder="Choix du texte" expanded>
                        <option></option>
                        <option value="Dog Mama" key="Dog Mama">Dog Mama</option>
                        <option value="Dog Dad" key="Dog Dad">Dog Dad</option>
                    </b-select>
                </b-field>
            </div>
            <div class="column is-7">
                <p class="caracteristique-titre">Quantité : </p>
                <b-field>
                    <b-select v-model="quantite">
                        <option :value=1>1</option>
                        <option :value=2>2</option>
                        <option :value=3>3</option>
                        <option :value=4>4</option>
                        <option :value=5>5</option>
                        <option :value=6>6</option>
                        <option :value=7>7</option>
                        <option :value=8>8</option>
                        <option :value=9>9</option>
                    </b-select>
                </b-field>
            </div>
        </div>
        <p class="caracteristique-soustitre">Pour la personnalisation du tote bag, envoyez une photo de votre chien par mail ou sur instagram :</p>
        <li class="caracteristique-soustitre">@cameli_melo</li>
        <li class="caracteristique-soustitre">camelimelo.creation@gmail.com</li>
        <br>
        <b-button type="is-primary" size="is-medium" @click="ajouterPanier()">Ajouter au panier</b-button>
    </div>
</template>

<script>
export default {
    name: 'Bandana',
    data() {
        return {
            activeTab: 0,
            texte: "",
            quantite: 1,
            taille: 'normal',
            error: false
        }
    },
    props: ['type', 'prix'],
    methods: {
        goToInformation(value) {
            this.$emit('goToInformation', value)
        },
        updateActiveTab(valueFromChild) {
            if (this.activeTab != valueFromChild) {
                this.activeTab = valueFromChild
            }
        },
        ajouterPanier() {
            this.error = false
            if (!this.texte) {
                this.error = true;
                return
            }
            let produit = {
                type: this.type,
                quantite: this.quantite,
                taille: "normal",
                prix: this.prix[this.taille],
                texte: this.texte
            }
        
            this.$store.commit('ajouterPanier', produit)

            let message = 'Votre produit a été aujouté au panier'

            this.quantite = 1
            this.texte = ""
            this.$buefy.snackbar.open({
                duration: 5000,
                position: 'is-top',
                message: message,
                actionText: 'Voir mon panier',
                onAction: () => {
                    this.$router.push('/panier')
                }
            })

        }
    }
}
</script>

<style lang="scss">
@import "@/assets/scss/variables.scss";

.caracteristique {
    padding-top:20px;
}

.caracteristique-titre {
    margin-bottom:5px;
    font-weight: 650;
}

.caracteristique-soustitre {
    font-weight: 500;
}


.see-more {
    color:$primary-color;
    font-weight: 400;
}

.notices .snackbar {
    background: $primary-color !important;
}

.notices .snackbar .action .button {
    color: $primary-color !important;
    font-weight: 600;
    text-transform: uppercase;
    background: white !important;
}
</style>
