<template>
    <div class="product-images">
        <div class="columns">
            <div class="column" v-if="screenSize >= 769" :class="screenSize >= 900 ? 'is-2' : 'is-3'">
                <div :key="index" v-for="(imgRoute, index) in images" >
                    <img v-if="index < 4 && imgRoute.includes('http')" v-on:click="changeSelectedImg(index)" class="img-small" :src="imgRoute">
                    <!-- <img v-on:click="changeSelectedImg(index)" class="img-small" :src="imgRoute"> -->
                    <img v-else-if="index < 4" v-on:click="changeSelectedImg(index)" class="img-small" :src="require(`@/assets/img/produits/${imgRoute}`)">
                </div>
                <div class="more-img" v-on:click="openModal()" v-if="images.length > 4">
                    <div class="center-text">+{{ images.length - 4}}</div>
                    <img class="more-img-detail"  src="@/assets/img/transforme/more-img.webp">
                </div>
            </div>
            <div class="column" :class="screenSize >= 900 ? 'is-10' : 'is-9'">
                <!-- <img class="img-principale" :src="images[imgSelect]" alt=""> -->
                <img v-if="images[imgSelect].includes('http')" class="img-principale" :src="images[imgSelect]" alt="">
                <img v-else class="img-principale" :src="require(`@/assets/img/produits/${images[imgSelect]}`)" alt="">
                <div class="img-principale-mobile">
                    <b-carousel class="img-principal-caroussel center-block" :autoplay="false" :indicator-inside="true">
                        <b-carousel-item :key="index" v-for="(imgRoute, index) in images">
                            <a class="image">
                                <img v-if="imgRoute.includes('http')" class="img-principale-caroussel center-block" :src="imgRoute">
                                <img v-else class="img-principale-caroussel center-block" :src="require(`@/assets/img/produits/${imgRoute}`)">
                            </a>
                        </b-carousel-item>
                    </b-carousel>
                </div>
            </div>
        </div>
        <b-modal v-model="showAllImg" :width="700" class="modal-show-more-image">
            <b-carousel :autoplay="false" indicator-custom :indicator-inside="false" :overlay="false">
                <b-carousel-item v-for="(imgRoute, index) in productImgsReorg" :key="index">
                    <a class="image ">
                        <img v-if="imgRoute.includes('http')" :src="imgRoute">
                        <img v-else :src="require(`@/assets/img/produits/${imgRoute}`)">
                    </a>
                </b-carousel-item>
                <span v-if="gallery"  class="modal-close is-large"/>
                <template #indicators="props">
                    <figure class="al image" :draggable="true">
                        <img v-if="productImgsReorg[props.i].includes('http')" :draggable="false" :src="productImgsReorg[props.i]">
                        <img v-else :draggable="false" :src="require(`@/assets/img/produits/${productImgsReorg[props.i]}`)">
                    </figure>
                </template>
            </b-carousel>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: 'ProductImages',
    data() {
        return {
            screenSize: window.innerWidth,
            productImgsReorg: [],
            showAllImg: false,
            imgSelect: 0,
            gallery: false,
        }
    },
    props: ['images'],
    methods: {
        changeSelectedImg(number) {
            this.imgSelect = number
        },
        openModal() {
            if (!this.images.length) {
                return;
            }
            this.showAllImg = true
            this.productImgsReorg =  this.images.slice(4, this.images.length).concat(this.images.slice(0, 4))
        },
        switchGallery(value) {
            this.gallery = value
            if (value) {
                return document.documentElement.classList.add('is-clipped')
            } else {
                return document.documentElement.classList.remove('is-clipped')
            }
        },
    }
}
</script>


<style lang="scss">
@import "@/assets/scss/variables.scss";

.product-images {
    .carousel .carousel-indicator .indicator-item .indicator-style {
        border: 1px solid $primary-color !important;
    }

    .carousel .carousel-indicator .indicator-item.is-active .indicator-style, .carousel .carousel-indicator .indicator-item .indicator-style:hover {
        background: $primary-color !important;
        border: 1px solid white;
    }

    .img-principale-caroussel {
        border-radius: 10px;
    }

    .modal-show-more-image {
        z-index: 999;
    }

    .indicator-item img {
        border: 1px solid white;
        filter: grayscale(0%) !important;
    }

    .more-img {
        position: relative;
        text-align: center;
        color: white;
        font-size:$text-title-size;
        cursor: pointer;
        font-weight: 700;
        border-radius:10px;
    }

    .more-img:hover {
        cursor: pointer;
        opacity: 0.5;
    }

    .center-text {
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    
    .more-img-detail {
        border-radius:10px;
    }

    .img-small {
        cursor: pointer;
        border-radius: 10px;
    }

    .img-small:hover {
        opacity: 0.5;
    }

    .img-principale {
        border-radius: 10px;
    }

    .tabs.is-toggle.is-toggle-rounded li:last-child a {
        background-color: white !important;
    }


    .img-principale-mobile {
        display: none
    }

    @media screen and  (max-width: 1023px) {
        .img-principale {
            display: none;
        }

        .img-principale-mobile {
            display: block
        }

        .img-principale-caroussel {
            max-width: 300px;
        }

        .center-text {
            font-size:18px
        }
    }
}
</style>
