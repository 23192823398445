<template>
    <div>
        <div class="columns is-multiline caracteristique">
            <div class="column is-6">
                <div class="caracteristique-titre">
                    Tissu <span v-if="tissusSelected.length != 1">coton 1</span> :
                    <a class="see-more" href="#information" v-on:click="goToInformation(1)">
                        voir les coloris
                    </a>
                </div>
                <b-field :type="error && (!tissusSelected || !tissusSelected[0].name) ? 'is-danger' : ''">
                    <b-select v-model="tissusSelected[0]" placeholder="Choisi un tissu" expanded>
                        <option></option>
                        <option v-for="tissu in tissus" :value="tissu" :key="tissu.id">{{tissu.name}}</option>
                    </b-select>
                </b-field>
            </div>
            <div class="column is-6">
                <div class="caracteristique-titre">
                    Choisir individuellement
                </div>
                <b-field style="padding-top:5px">
                    <b-switch
                        v-model="tissuIndividuel"
                        type="is-terracota">
                    </b-switch>
                </b-field>
            </div>
            <div class="column is-6"  v-if="tissusSelected.length > 1">
                <div class="caracteristique-titre">
                    Tissu coton 2 :
                </div>
                <b-field :type="error && (!tissusSelected || !tissusSelected[1].name) ? 'is-danger' : ''">
                    <b-select v-model="tissusSelected[1]" placeholder="Choisi un tissu" expanded>
                        <option></option>
                        <option v-for="tissu in tissus" :value="tissu" :key="tissu.id">{{tissu.name}}</option>
                    </b-select>
                </b-field>
            </div>
            <div class="column is-6"  v-if="tissusSelected.length > 1">
                <div class="caracteristique-titre">
                    Tissu coton 3 :
                </div>
                <b-field :type="error && (!tissusSelected || !tissusSelected[2].name) ? 'is-danger' : ''">
                    <b-select v-model="tissusSelected[2]" placeholder="Choisi un tissu" expanded>
                        <option></option>
                        <option v-for="tissu in tissus" :value="tissu" :key="tissu.id">{{tissu.name}}</option>
                    </b-select>
                </b-field>
            </div>
            <div class="column is-6"  v-if="tissusSelected.length > 1">
                <div class="caracteristique-titre">
                    Tissu coton 4 :
                </div>
                <b-field :type="error && (!tissusSelected || !tissusSelected[3].name) ? 'is-danger' : ''">
                    <b-select v-model="tissusSelected[3]" placeholder="Choisi un tissu" expanded>
                        <option></option>
                        <option v-for="tissu in tissus" :value="tissu" :key="tissu.id">{{tissu.name}}</option>
                    </b-select>
                </b-field>
            </div>
            <div class="column is-6"  v-if="tissusSelected.length > 4">
                <div class="caracteristique-titre">
                    Tissu coton 5 :
                </div>
                <b-field :type="error && (!tissusSelected || !tissusSelected[4].name) ? 'is-danger' : ''">
                    <b-select v-model="tissusSelected[4]" placeholder="Choisi un tissu" expanded>
                        <option></option>
                        <option v-for="tissu in tissus" :value="tissu" :key="tissu.id">{{tissu.name}}</option>
                    </b-select>
                </b-field>
            </div>
            <div class="column is-6"  v-if="tissusSelected.length > 4">
                <div class="caracteristique-titre">
                    Tissu coton 6 :
                </div>
                <b-field :type="error && (!tissusSelected || !tissusSelected[5].name) ? 'is-danger' : ''">
                    <b-select v-model="tissusSelected[5]" placeholder="Choisi un tissu" expanded>
                        <option></option>
                        <option v-for="tissu in tissus" :value="tissu" :key="tissu.id">{{tissu.name}}</option>
                    </b-select>
                </b-field>
            </div>
            <div class="column is-6"  v-if="tissusSelected.length > 4">
                <div class="caracteristique-titre">
                    Tissu coton 7 :
                </div>
                <b-field :type="error && (!tissusSelected || !tissusSelected[6].name) ? 'is-danger' : ''">
                    <b-select v-model="tissusSelected[6]" placeholder="Choisi un tissu" expanded>
                        <option></option>
                        <option v-for="tissu in tissus" :value="tissu" :key="tissu.id">{{tissu.name}}</option>
                    </b-select>
                </b-field>
            </div>
            <div class="column is-6"  v-if="tissusSelected.length > 4">
                <div class="caracteristique-titre">
                    Tissu coton 8 :
                </div>
                <b-field :type="error && (!tissusSelected || !tissusSelected[7].name) ? 'is-danger' : ''">
                    <b-select v-model="tissusSelected[7]" placeholder="Choisi un tissu" expanded>
                        <option></option>
                        <option v-for="tissu in tissus" :value="tissu" :key="tissu.id">{{tissu.name}}</option>
                    </b-select>
                </b-field>
            </div>
            <div class="column is-6"  v-if="tissusSelected.length > 8">
                <div class="caracteristique-titre">
                    Tissu coton 9 :
                </div>
                <b-field :type="error && (!tissusSelected || !tissusSelected[8].name) ? 'is-danger' : ''">
                    <b-select v-model="tissusSelected[8]" placeholder="Choisi un tissu" expanded>
                        <option></option>
                        <option v-for="tissu in tissus" :value="tissu" :key="tissu.id">{{tissu.name}}</option>
                    </b-select>
                </b-field>
            </div>
            <div class="column is-6"  v-if="tissusSelected.length > 8">
                <div class="caracteristique-titre">
                    Tissu coton 10 :
                </div>
                <b-field :type="error && (!tissusSelected || !tissusSelected[9].name) ? 'is-danger' : ''">
                    <b-select v-model="tissusSelected[9]" placeholder="Choisi un tissu" expanded>
                        <option></option>
                        <option v-for="tissu in tissus" :value="tissu" :key="tissu.id">{{tissu.name}}</option>
                    </b-select>
                </b-field>
            </div>
            <div class="column is-6"  v-if="tissusSelected.length != 1">
            </div>
            <div class="column is-5">
                <p class="caracteristique-titre">Nombre de cotons :
                </p>
                <b-field>
                    <b-select v-model="taille" :type="error && !this.taille ? 'is-danger' : ''" v-if="type != 'chouchou'">
                        <option value="lot4">Par 4 - {{  Number.parseFloat(prix['lot4']).toFixed(2) }} €</option>
                        <option value="lot8">Par 8 - {{  Number.parseFloat(prix['lot8']).toFixed(2) }} €</option>
                        <option value="lot10">Par 10 - {{  Number.parseFloat(prix['lot10']).toFixed(2) }} €</option>
                    </b-select>
                </b-field>
            </div>
        </div>
        <b-button type="is-primary" size="is-medium" @click="ajouterPanier()">Ajouter au panier</b-button>
    </div>
</template>

<script>
export default {
name: 'Cotons',
    data() {
        return {
            activeTab: 0,
            tissuSelected: {},
            texte: "",
            couleurTexte: "",
            tissuIndividuel: false,
            tissusSelected: [{}],
            quantite: 1,
            taille: 'lot4',
            error: false
        }
    },
    props: ['type', 'prix', 'tissus'],
    methods: {
        goToInformation(value) {
            this.$emit('goToInformation', value)
        },
        updateActiveTab(valueFromChild) {
            if (this.activeTab != valueFromChild) {
                this.activeTab = valueFromChild
            }
        },
        ajouterPanier() {
            this.error = false
            this.tissusSelected.forEach((tissu, index) => {
                 if (!tissu.name) {
                    this.error = true
                    return
                }
                if (index != 0) {
                    this.tissuSelected.name += " / "
                    this.tissuSelected.id += " / "
                } else {
                    this.tissuSelected.name = ""
                    this.tissuSelected.id = ""
                }
                this.tissuSelected.name += tissu.name
                this.tissuSelected.id += tissu.id
            })
            if (this.error) {
                this.error = true
                return
            }
            let produit = {
                type: this.type,
                tissu: this.tissuSelected.id,
                tissuNom: this.tissuSelected.name,
                quantite: this.quantite,
                taille: this.taille,
                prix: this.prix[this.taille]
            }


            this.$store.commit('ajouterPanier', produit)

            let message = 'Votre produit a été aujouté au panier'
            this.quantite = 1
            this.tissuSelected = {},
            this.tissuIndividuel = false
            this.$buefy.snackbar.open({
                duration: 5000,
                position: 'is-top',
                message: message,
                actionText: 'Voir mon panier',
                onAction: () => {
                    this.$router.push('/panier')
                }
            })

        }
    },
    watch: {
        type() {
            this.taille = 'lot4'
        },
        taille() {
            this.$emit('updatePrice', this.taille)
            if (this.tissuIndividuel && this.taille == 'lot4') {
                this.tissusSelected = [{}, {}, {}, {}]
            } else if (this.tissuIndividuel && this.taille == 'lot8') {
                this.tissusSelected = [{}, {}, {}, {}, {}, {}, {}, {}]
            } else if (this.tissuIndividuel && this.taille == 'lot10') {
                this.tissusSelected = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]
            }
        },
        tissuIndividuel() {
            if (!this.tissuIndividuel) {
                this.tissusSelected = [{}]
            } else {
                if (this.taille == 'lot4') {
                    this.tissusSelected = [{}, {}, {}, {}]
                } else if (this.taille == 'lot8') {
                    this.tissusSelected = [{}, {}, {}, {}, {}, {}, {}, {}]
                } else if (this.taille == 'lot10') {
                    this.tissusSelected = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]
                }
            }
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/scss/variables.scss";

.caracteristique {
    padding-top:20px;
}

.caracteristique-titre {
    margin-bottom:5px;
    font-weight: 650;
}

.see-more {
    color:$primary-color;
    font-weight: 400;
}

.notices .snackbar {
    background: $primary-color !important;
}

.notices .snackbar .action .button {
    color: $primary-color !important;
    font-weight: 600;
    text-transform: uppercase;
    background: white !important;
}
</style>
