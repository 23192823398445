<template>
    <div class="main_checkout">
        <div class="container">
            <p class="checkout-title has-text-centered">Validation de ma commande</p>
            <div class="columns">
                <div class="column" :class="screenSize >= 1024 ? 'is-7' : 'is-12'">
                    <div>
                        <div class="title_section">
                            <span v-if="valideDetailExpedition">
                                <b-icon icon="check" size="is-medium"></b-icon>
                            </span>
                            <span v-else>1</span>
                             - Détails d'expédition
                            <a v-if="valideDetailExpedition" class="is-pulled-right button-modifier" v-on:click="valideDetailExpedition = false">Modifier</a>
                        </div>
                        <div class="formulaire_checkout" v-if="!valideDetailExpedition">
                            <div class="formulaire_input">
                                <p>Email*</p>
                                <b-field :type="(!email && errorValideDetailExpedition) || errorEmail ? 'is-danger': ''">
                                    <b-input
                                        v-model="email"
                                        placeholder="Email"
                                        type="email"
                                    ></b-input>
                                </b-field>
                                <p class="errorCGV" v-if="errorEmail">Format de l'email invalide.</p>
                            </div>
                            <div class="formulaire_input">
                                <p>Prénom*</p>
                                <b-field :type="!prenom && errorValideDetailExpedition ? 'is-danger': ''">
                                    <b-input v-model="prenom" placeholder="Prénom"></b-input>
                                </b-field>
                            </div>
                            <div class="formulaire_input">
                                <p>Nom*</p>
                                <b-field :type="!nom && errorValideDetailExpedition ? 'is-danger': ''">
                                    <b-input v-model="nom" placeholder="Nom"></b-input>
                                </b-field>
                            </div>
                            <div class="formulaire_input">
                                <p>Adresse*</p>
                                <b-field :type="!adresse && errorValideDetailExpedition ? 'is-danger': ''">
                                    <b-input v-model="adresse" placeholder="Adresse"></b-input>
                                </b-field>
                            </div>
                            <div class="formulaire_input">
                                <p>Complément d'adresse</p>
                                <b-input v-model="complement" placeholder="Complément d'adresse"></b-input>
                            </div>
                            <div class="columns">
                                <div class="column is-6">
                                    <div class="formulaire_input">
                                        <p>Code postal*</p>
                                        <b-field :type="!telephone && errorValideDetailExpedition ? 'is-danger': ''">
                                            <b-input v-model="code_postal" placeholder="Code postal"></b-input>
                                        </b-field>
                                    </div>
                                </div>
                                <div class="column is-6">
                                    <div class="formulaire_input">
                                        <p>Ville*</p>
                                        <b-field :type="!telephone && errorValideDetailExpedition ? 'is-danger': ''">
                                            <b-input v-model="ville" placeholder="Ville"></b-input>
                                        </b-field>
                                    </div>
                                </div>
                            </div>
                            <div class="formulaire_input">
                                <p>Téléphone*</p>
                                <b-field :type="(!telephone && errorValideDetailExpedition) || errorTelephone ? 'is-danger': ''">
                                    <b-input v-model="telephone" class="is-danger" placeholder="Téléphone"></b-input>
                                </b-field>
                                <p class="errorCGV" v-if="errorEmail">Format du numéro de téléphone invalide.</p>
                            </div>
                            <div class="formulaire_input is-margin-bottom-25">
                                <p>Instagram</p>
                                <b-field>
                                    <b-input v-model="instagram" placeholder="Compte instagram (facultatif)"></b-input>
                                </b-field>
                            </div>
                        </div>
                        <div v-else>
                            <p>{{ prenom + ' ' + nom }}</p>
                            <p>{{ email }}</p>
                            <p>{{ adresse }} {{ complement ? ', ' + complement : '' }} -  {{code_postal}} {{ ville }}</p>
                            <p>{{ telephone }}</p>
                            <p v-if="instagram">{{ instagram }}</p>
                        </div>
                        <a v-if="!valideDetailExpedition" class="button is-primary is-fullwidth button_details" type="submit" v-on:click="validerDetailExpedition()">Continuer</a>
                    </div>
                    <hr class="hr-section">
                    <div class="choix_mode_livraison" id="livraison">
                        <div class="title_section">
                            <span v-if="valideDetailExpedition && valideModeLivraison">
                                <b-icon icon="check" size="is-medium"></b-icon>
                            </span>
                            <span v-else>2</span>
                             - Mode de livraison
                            <a v-if="valideDetailExpedition && valideModeLivraison" class="is-pulled-right button-modifier" v-on:click="updateModeLivraison()">Modifier</a>
                        </div>
                        <div v-if="valideDetailExpedition && !valideModeLivraison">
                            <p class="type-livraison">Expédition</p>
                            <div class="box" v-on:click="mode_livraison = 'colissimo'" v-if="!sacBananes.length && !balleFouilles.length">
                                <div class="columns is-vcentered is-mobile">
                                    <div class="column is-2">
                                        <b-radio v-model="mode_livraison"
                                            name="mode_livraison"
                                            native-value="colissimo">
                                        </b-radio>
                                    </div>
                                    <div class="column is-6">
                                        <p class="has-text-weight-semibold">Colissimo</p>
                                        <p>2 jours ouvrés</p>
                                    </div>
                                    <div class="column is-4">
                                        <p class="is-pulled-right">4.95 €</p>
                                    </div>
                                </div>
                            </div>
                            <div class="box" v-on:click="mode_livraison = 'mondial_relay'">
                                <div class="columns is-vcentered is-mobile">
                                    <div class="column is-2">
                                        <b-radio v-model="mode_livraison"
                                            name="mode_livraison"
                                            native-value="mondial_relay">
                                        </b-radio>
                                    </div>
                                    <div class="column is-6">
                                        <p class="has-text-weight-semibold">Mondial Relay - Livraison en point relais</p>
                                        <p>3 à 5 jours ouvrés</p>
                                    </div>
                                    <div class="column is-4">
                                        <p class="is-pulled-right">4.40 €</p>
                                    </div>
                                </div>
                            </div>
                            <p v-if="mode_livraison == 'mondial_relay' && this.mondial_relay" style="margin-top:10px; margin-bottom:10px;">
                                Livraison en point relais au : <br/><span style="font-weight:600">{{ this.mondial_relay.Nom }} - {{ this.mondial_relay.Ville }}</span>
                            </p>
                            <template v-if="mode_livraison == 'mondial_relay'">
                                <div id="app" style="max-width: 980px; margin: auto">
                                    <WidgetMondialRelay
                                        brand="BDTEST  "
                                        :defaultPostCode=code_postal
                                        defaultCountry="FR"
                                        maxResults="15"
                                        deliveryMode="24R"
                                        allowedCountries="FR"
                                        :translations="{
                                            headerTitle: 'Selectionnez votre point mondial relay ®',
                                            findCpText: 'CP',
                                            findCityText: 'Ville',
                                            cityNoResults: 'Aucun résultat',
                                            btnListMobile: 'Liste',
                                            btnMapMobile: 'Carte'
                                        }"
                                        @select="setParcelSelected($event)"
                                    />
                                </div>
                            </template>
                            <br v-if="mode_livraison == 'mondial_relay'">
                            <p class="type-livraison">Retrait</p>
                            <div class="box" v-on:click="mode_livraison = 'retrait'">
                                <div class="columns is-vcentered is-mobile">
                                    <div class="column is-2">
                                        <b-radio v-model="mode_livraison"
                                            name="mode_livraison"
                                            native-value="retrait">
                                        </b-radio>
                                    </div>
                                    <div class="column is-6">
                                        <p class="has-text-weight-semibold">Main propre</p>
                                        <p>2 Rue Alexandre Dumas, 59155 Faches Thumesnil</p>
                                    </div>
                                    <div class="column is-4">
                                        <p class="is-pulled-right">GRATUIT</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else-if="valideDetailExpedition && valideModeLivraison">
                            <div v-if="mode_livraison == 'mondial_relay'">
                                <p class="has-text-weight-medium">Livraison mondial relay <span class="is-pulled-right">4.40 €</span></p>
                                <p v-if="mondial_relay">Point retrait : {{ mondial_relay.Nom }} - {{ mondial_relay.Ville }}</p>
                            </div>
                            <div v-if="mode_livraison == 'colissimo'">
                                <p class="has-text-weight-medium">Livraison colissimo en 2 jours ouvrés <span class="is-pulled-right">4.95 €</span></p>
                            </div>
                            <div v-if="mode_livraison == 'retrait'">
                                <p class="has-text-weight-medium">Retrait en main propre <span class="is-pulled-right">GRATUIT</span></p>
                                <p>2 Rue Alexandre Dumas, 59155 Faches Thumesnil, France</p>
                            </div>
                        </div>
                        <a v-if="valideDetailExpedition && !valideModeLivraison" class="button is-primary is-fullwidth button_mode_livraison" type="submit" v-on:click="validerModeLivraison()">Continuer</a>
                    </div>
                    <hr class="hr-section">
                    <div id="validation">
                        <div class="title_section">
                            3 - Vérifier la commande et payer
                        </div>
                        <div v-if="valideDetailExpedition && valideModeLivraison">
                            <div class="box-recap-commande-mobile">
                                <p class="is-margin-bottom-10">Articles</p>
                                <div v-for="(produit, index) in panier.produits" :key="produit.key" class="box-recap-info-mobile">
                                    <div>
                                        <p>
                                            <span class="has-text-weight-semibold">{{
                                                produit.type == "carnet" ? `Housse de carnet de santé pour animaux ${produit.noeud ? 'avec noeud': ''}` :
                                                produit.type == "passeport" ? `Housse de passeport européen pour animaux ${produit.noeud ? 'avec noeud': ''}` :
                                                produit.type == "livret" ? `Housse de livret de famille ${produit.noeud ? 'avec noeud': ''}` :
                                                produit.type == "bandana" ? `Bandana (${produit.taille == 'extra-small' ? 'XS' :
                                                    produit.taille == 'small' ? 'S' :
                                                    produit.taille == 'small-medium' ? 'S/M' :
                                                    produit.taille == 'medium' ? 'M' :
                                                    produit.taille == 'large' ? 'L' :
                                                    produit.taille == 'extra-large' ? 'XL' : ''})` :
                                                produit.type == "collier" ? `Collier (${produit.taille == 'extra-small' ? 'XS' :
                                                    produit.taille == 'small' ? 'S' :
                                                    produit.taille == 'small-medium' ? 'S/M' :
                                                    produit.taille == 'medium' ? 'M' :
                                                    produit.taille == 'large' ? 'L' :
                                                    produit.taille == 'extra-large' ? 'XL' : ''})` :
                                                produit.type == "noeud" ? `Noeud (${produit.taille == 'extra-small' ? 'XS' :
                                                    produit.taille == 'small' ? 'S' :
                                                    produit.taille == 'small-medium' ? 'S/M' :
                                                    produit.taille == 'medium' ? 'M' :
                                                    produit.taille == 'large' ? 'L' :
                                                    produit.taille == 'extra-large' ? 'XL' : ''})` :
                                                produit.type == "noeud-noel" ? `Noeud de Noël (${produit.taille == 'extra-small' ? 'XS' :
                                                    produit.taille == 'small' ? 'S' :
                                                    produit.taille == 'small-medium' ? 'S/M' :
                                                    produit.taille == 'medium' ? 'M' :
                                                    produit.taille == 'large' ? 'L' :
                                                    produit.taille == 'extra-large' ? 'XL' : ''})` :
                                                produit.type == "bandana_prenom" ? `Bandana avec prénom (${produit.taille == 'extra-small' ? 'XS' :
                                                    produit.taille == 'small' ? 'S' :
                                                    produit.taille == 'small-medium' ? 'S/M' :
                                                    produit.taille == 'medium' ? 'M' :
                                                    produit.taille == 'large' ? 'L' :
                                                    produit.taille == 'extra-large' ? 'XL' : ''})` :
                                                produit.type == "bandana-reversible" ? `Bandana réversible (${produit.taille == 'extra-small' ? 'XS' :
                                                    produit.taille == 'small' ? 'S' :
                                                    produit.taille == 'small-medium' ? 'S/M' :
                                                    produit.taille == 'medium' ? 'M' :
                                                    produit.taille == 'large' ? 'L' :
                                                    produit.taille == 'extra-large' ? 'XL' : ''})` :
                                                produit.type == "bandana-reversible-noel" ? `Bandana réversible de Noël (${produit.taille == 'extra-small' ? 'XS' :
                                                    produit.taille == 'small' ? 'S' :
                                                    produit.taille == 'small-medium' ? 'S/M' :
                                                    produit.taille == 'medium' ? 'M' :
                                                    produit.taille == 'large' ? 'L' :
                                                    produit.taille == 'extra-large' ? 'XL' : ''})` :
                                                produit.type == "bandana-noel" ? `Bandana de Noël (${produit.taille == 'extra-small' ? 'XS' :
                                                    produit.taille == 'small' ? 'S' :
                                                    produit.taille == 'small-medium' ? 'S/M' :
                                                    produit.taille == 'medium' ? 'M' :
                                                    produit.taille == 'large' ? 'L' :
                                                    produit.taille == 'extra-large' ? 'XL' : ''})` :
                                                produit.type == "bandana_texte" ? `Bandana avec texte (${produit.taille == 'extra-small' ? 'XS' :
                                                    produit.taille == 'small' ? 'S' :
                                                    produit.taille == 'small-medium' ? 'S/M' :
                                                    produit.taille == 'medium' ? 'M' :
                                                    produit.taille == 'large' ? 'L' :
                                                    produit.taille == 'extra-large' ? 'XL' : ''})` :
                                                produit.type == "chouchou" ? `Chouchou ${produit.taille == 'medium' ? 'adulte' : 'enfant'}` :
                                                produit.type == "tote-bag" ? `Tote bag` :
                                                produit.type == "balle-fouille" ? `Balle de fouille` :
                                                produit.type == "cotons" ? `Cotons lavables par ${ produit.taille == 'lot4' ? '4' : produit.taille == 'lot8' ? '8' : produit.taille == 'lot10' ? '10' : '' }` :
                                                produit.type == "sac-banane" && produit.metadata.sangleInterchangeable && produit.metadata.deuxiemeSangle ? "Sac banane avec 2 sangles" :
                                                produit.type == "sac-banane" && produit.metadata.sangleInterchangeable && !produit.metadata.deuxiemeSangle ? "Sac banane avec sangle interchangeable" :
                                                produit.type == "sac-banane" && !produit.metadata.sangleInterchangeable ? "Sac banane avec sangle intégrée" : ""
                                            }}</span>
                                            <span class="is-pulled-right">
                                            {{
                                                Number.parseFloat(produit.quantite * produit.prix).toFixed(2)
                                            }} €</span>
                                        </p>
                                        <p>Quantité : {{ produit.quantite }}</p>
                                        <p v-if="!produit.tissuSecondaire && produit.type != 'sac-banane' && produit.type != 'balle-fouille' && produit.type != 'tote-bag'">Tissu : {{produit.tissuNom}}</p>
                                        <p v-else-if="produit.type == 'balle-fouille'">Ensemble : {{produit.tissuNom}}</p>
                                        <p v-else-if="produit.type == 'tote-bag'"></p>
                                        <div v-else>
                                            <p>Tissu haut : {{produit.tissuSecondaire}} <span v-if="produit.texte"></span></p>
                                            <p>Tissu bas : {{produit.tissuNom}} <span v-if="produit.texte"></span></p>
                                        </div>
                                        <div v-if="produit.type == 'sac-banane'">
                                            <p class="product-details-more"><span class="product-details">Tissus : </span>{{produit.tissuNom}}</p>
                                            <p v-if="!produit.metadata.deuxiemeSangle" class="product-details-more">
                                                Sangle avec le même tissu que l'{{produit.metadata.couleurSangle}}
                                            </p>
                                            <p v-else-if="produit.metadata.deuxiemeSangle" class="product-details-more">
                                                <span>Sangle 1 avec le même tissu que l'extérieur</span><br/>
                                                <span>Sangle 2 avec le même tissu que l'intérieur</span>
                                            </p>
                                        </div>
                                        
                                        <p v-if="produit.texte">Texte : {{ produit.texte }}</p>
                                    </div>
                                    <hr v-if="panier.produits.length != index + 1"/>
                                </div>
                                <hr>
                                <p>Sous-total<span class="is-pulled-right">{{ Number.parseFloat(panier.tarif).toFixed(2) }} €</span></p>   
                                <p style="font-style:italic" v-if="code_promo">{{code_promo.id}} (-{{ code_promo.percent }}%)<span class="is-pulled-right">- {{ Number.parseFloat(reduction).toFixed(2) }} €</span></p>                                             
                                <p>Expédition
                                    <span class="is-pulled-right">
                                        {{ mode_livraison == 'colissimo' ? '4.95 €' : mode_livraison == 'mondial_relay' ? '4.40 €' : 'GRATUIT'}}
                                    </span>
                                </p>
                                <hr>
                                <p v-if="code_promo" class="has-text-weight-semibold size-18">Total<span class="is-pulled-right">{{ Number.parseFloat((mode_livraison == 'colissimo' ? (panier.tarif - reduction) + 4.95 : mode_livraison == 'mondial_relay' ? (panier.tarif - reduction) + 4.40 : panier.tarif - reduction)).toFixed(2) }} €</span></p>                  
                                <p v-else class="has-text-weight-semibold size-18">Total<span class="is-pulled-right">{{ Number.parseFloat((mode_livraison == 'colissimo' ? panier.tarif + 4.95 : mode_livraison == 'mondial_relay' ? panier.tarif + 4.40 : panier.tarif)).toFixed(2) }} €</span></p>                  
                            </div>
                            <b-field class="want_to_create_account">
                                <b-checkbox v-model="wantToCreateAnAccount" type="is-terracota">J'accepte les <span @click="goTo('/condition-generale-de-vente')" class="button_condition_generale">conditions générales de vente</span></b-checkbox>
                            </b-field>
                            <p class="errorCGV" v-if="errorCGV && !wantToCreateAnAccount">Vous devez accepter les CGV avant de passer au paiement.</p>

                            <b-button class="button is-primary is-fullwidth button_payer" type="submit" v-on:click="payer()">Payer</b-button>
                        </div>
                    </div>
                </div>
                <div class="column is-5 box-recap-commande">
                    <div class="box">
                        <p class="has-text-weight-semibold size-18">Récapitulatif de la commande</p>
                        <hr>
                        <div v-for="produit in panier.produits" :key="produit.key" class="box-recap-info">
                            <checkout-detail :produit="produit" />
                        </div>
                        <hr>
                        <p>Articles<span class="is-pulled-right">{{ Number.parseFloat(panier.tarif).toFixed(2) }} €</span></p>
                        <p style="font-style:italic" v-if="code_promo">{{code_promo.id}} (-{{ code_promo.percent }}%)<span class="is-pulled-right">- {{ Number.parseFloat(reduction).toFixed(2) }} €</span></p>                        
                        <p>Expédition
                            <span class="is-pulled-right">
                                {{ mode_livraison == 'colissimo' ? '4.95 €' : mode_livraison == 'mondial_relay' ? "4.40 €" : 'GRATUIT'}}
                            </span>
                        </p> 
                        <hr>
                        <p v-if="code_promo" class="has-text-weight-semibold size-18">Total<span class="is-pulled-right">{{ Number.parseFloat((mode_livraison == 'colissimo' ? panier.tarif - (reduction) + 4.95 : mode_livraison == 'mondial_relay' ? panier.tarif -(reduction) + 4.40 : panier.tarif - (reduction))).toFixed(2) }} €</span></p>                  
                        <p v-else class="has-text-weight-semibold size-18">Total<span class="is-pulled-right">{{ Number.parseFloat((mode_livraison == 'colissimo' ? panier.tarif + 4.95 : mode_livraison == 'mondial_relay' ? panier.tarif + 4.40  : panier.tarif)).toFixed(2) }} €</span></p>                  
                    </div>
                </div>
            </div>
        </div>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
    </div>
</template>
  
<script>
import { mapState } from 'vuex'
import CheckoutDetail from '@/components/page/checkout/CheckoutDetail.vue';
import { sauvegarderCommande } from '@/services/PaiementService';
import { paiement, checkIfNumCommandeExist } from '@/services/PaiementService';
import {v4 as uuidv4} from 'uuid';
import WidgetMondialRelay from 'vue-widget-mondial-relay';

export default {
    name: 'CheckoutView',
    components: {
        CheckoutDetail,
        WidgetMondialRelay
    },
    data() {
        return {
            isLoading: false,
            wantToCreateAnAccount: false,
            errorValiditePassword: false,
            password: '',
            confirm_password: '',
            errorValideDetailExpedition: false,
            email: '',
            prenom: '',
            nom: '',
            adresse: '',
            complement: '',
            code_postal: '',
            ville: '',
            telephone: '',
            instagram: '',
            mode_livraison: 'colissimo',
            valideDetailExpedition: false,
            valideModeLivraison: false,
            totalAmountWithShipping: 0,
            montantLivraison: 0,
            lineItems: [],
            screenSize: window.innerWidth,
            errorCGV: false,
            errorEmail: false,
            errorTelephone: false,
            uuid: null,
            mondial_relay: null,
            sacBananes: [],
            balleFouilles: []
        }
    },
    computed: {
        ...mapState(['currentUser', 'panier', 'codes_promo', 'code_promo', 'reduction'])
    },
    methods: {
        updateModeLivraison() {
            this.valideModeLivraison = false;
            this.valideModeLivraisonIsMondialRelay = false; 
            this.mondial_relay = null;
        },
        setParcelSelected(data){
            this.mondial_relay = data
            console.log(data)
        },
        goTo(value) {
            this.$router.push(value)
        },
        validerDetailExpedition() {
            this.errorTelephone = false
            this.errorEmail = false
            this.errorValideDetailExpedition = false;
            if (
                !this.email ||
                !this.prenom ||
                !this.nom ||
                !this.adresse ||
                !this.code_postal ||
                !this.ville ||
                !this.telephone ||
                (this.wantToCreateAnAccount && !this.password && !this.confirm_password)
            ) {
                this.errorValideDetailExpedition = true
                return;
            }
            const regexTelephone = /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/g;
            const foundTelephone = this.telephone.match(regexTelephone);
            if (!foundTelephone) {
                this.errorTelephone = true;
            }

            // eslint-disable-next-line
            const regexEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
            const foundEmail = this.email.match(regexEmail);
            if (!foundEmail) {
                this.errorEmail = true;
            }

            if (this.errorEmail || this.errorTelephone) {
                return;
            }

            this.valideDetailExpedition = true
            window.location.href = "#livraison";
        },
        validerModeLivraison() {
            if (!this.mode_livraison || this.mode_livraison == 'mondial_relay' && this.mondial_relay == null) {
                return;
            }
            this.montantLivraison =
                this.mode_livraison == 'colissimo' ? 4.95 : 
                this.mode_livraison == 'mondial_relay' ? 4.40 : 0
            this.totalAmountWithShipping = this.panier.tarif + this.montantLivraison
            this.valideModeLivraison = true
            window.location.href = "#validation";
        },
        validerModeLivraisonMondialRelay() {
            if (!this.mode_livraison || !this.mondial_relay) {
                return;
            }
            this.valideModeLivraison = true
            window.location.href = "#validation";
        },
        checkValiditePassword() {
            const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}/g;
            const found = this.password.match(regex);
            if (!found) {
                this.errorValiditePassword = true;
            } else {
                this.errorValiditePassword = false;
            }
        },
        async payer () {
            this.lineItems = []
            this.errorCGV = false;
            if (this.code_promo && this.code_promo.expirationDate <= new Date()) {
                this.$store.commit('SET_CODE_PROMO', null)
                this.$buefy.toast.open({
                    duration: 5000,
                    message: 'Le code promo que vous avez sélectionné n\'est plus valide.',
                    type: 'is-danger'
                })
                return
            }
            if (!this.wantToCreateAnAccount) {
                this.errorCGV = true;
                return;
            }
            this.isLoading = true;
            this.panier.produits.forEach(e => {
                this.lineItems.push({
                    price: e.id_stripe,
                    quantity: e.quantite,
                })
            })

            const tempDate = new Date();
            let day = tempDate.getDate()
            if (day < 10) {
                day = "0" + day
            }

            let month = tempDate.getMonth() +1
            if (month < 10) {
                month = "0" + month
            }

            let randomValue = Math.floor(Math.random() * (9999 - 1 + 1) + 1)
            if (randomValue < 10) {
                randomValue = "000" + randomValue
            } else if (randomValue < 100) {
                randomValue = "00" + randomValue
            } else if (randomValue < 1000) {
                randomValue = "0" + randomValue
            } else {
                randomValue = randomValue.toString()
            } 
            let numCommande = month + day + randomValue
            let exist = await checkIfNumCommandeExist(numCommande)
            let i = 0;
            while (exist && i < 9) {
                randomValue = Math.floor(Math.random() * (9999 - 1 + 1) + 1)
                if (randomValue < 10) {
                randomValue = "000" + randomValue
                } else if (randomValue < 100) {
                randomValue = "00" + randomValue
                } else if (randomValue < 1000) {
                randomValue = "0" + randomValue
                } else {
                randomValue = randomValue.toString()
                } 
                numCommande = month+ day + randomValue
                exist = await checkIfNumCommandeExist(numCommande)
                i++
            }
            if (exist) {
                this.isLoading = false;
                this.$buefy.toast.open({
                    duration: 5000,
                    message: 'Une erreur est survenue, veuillez réessayer plus tard ou prendre contact directement sur instagram ou par email.',
                    type: 'is-danger'
                })
                return;
            }
            if (this.mode_livraison != 'mondial_relay') {
                this.mondial_relay = null
            }
            try {
                let commande = {
                    prenom: this.prenom,
                    nom: this.nom,
                    email: this.email,
                    adresse: this.adresse,
                    complement: this.complement,
                    ville: this.ville,
                    code_postal: this.code_postal,
                    telephone: this.telephone,
                    instagram: this.instagram,
                    mode_livraison: this.mode_livraison,
                    tarif: this.panier.tarif,
                    quantite: this.panier.quantite,
                    tarif_avec_livraison: this.totalAmountWithShipping,
                    produits: this.panier.produits,
                    uuid: this.uuid,
                    numero_commande: numCommande,
                    livraison: this.mode_livraison == 'colissimo' || this.mode_livraison == 'mondial_relay' ? true : false,
                    code_promo: this.code_promo,
                    reduction: this.reduction,
                    mondial_relay: this.mondial_relay
                }
                const result = await sauvegarderCommande(commande, this.currentUser)
                commande.reference = result
                this.$store.commit('SET_COMMANDE', commande);
            } catch(e) {
                this.isLoading = false;
                this.$buefy.toast.open({
                    duration: 5000,
                    message: 'Une erreur est survenue, veuillez réessayer plus tard ou prendre contact directement sur instagram ou par email.',
                    type: 'is-danger'
                })
                return;
            }
            try {
                const url = await paiement(
                    this.lineItems,
                    this.code_promo ? this.code_promo.value : '',
                    this.uuid,
                    this.mode_livraison == 'colissimo' || this.mode_livraison == 'mondial_relay' ? true : false,
                    this.mode_livraison
                )
                this.isLoading = false;
                window.location.href = url;
            } catch(e) {
                this.isLoading = false;
                this.$buefy.toast.open({
                    duration: 5000,
                    message: 'Une erreur est survenue, veuillez réessayer plus tard ou prendre contact directement sur instagram ou par email.',
                    type: 'is-danger'
                })
                return;
            }
        },
    },
    mounted() {
        this.uuid = uuidv4()
        this.sacBananes = this.panier.produits.filter(e => e.type == 'sac-banane')
        this.balleFouilles = this.panier.produits.filter(e => e.type == 'balle-fouille')
        if (this.sacBananes.length || this.balleFouilles.length) {
            this.mode_livraison = 'mondial_relay'
        }
        this.montantLivraison = this.mode_livraison == 'colissimo' ? 4.95 : this.mode_livraison == 'mondial_relay' ? 4.40 : 0
        this.totalAmountWithShipping = this.panier.tarif + this.montantLivraison
        if (this.currentUser) {
            this.email = this.currentUser.email ? this.currentUser.email : ''
            this.prenom = this.currentUser.prenom ? this.currentUser.prenom : ''
            this.nom = this.currentUser.nom ? this.currentUser.nom : ''
            this.adresse = this.currentUser.adresse ? this.currentUser.adresse : ''
            this.complement = this.currentUser.complement ? this.currentUser.complement : ''
            this.code_postal = this.currentUser.code_postal ? this.currentUser.code_postal : ''
            this.ville = this.currentUser.ville ? this.currentUser.ville : ''
            this.telephone = this.currentUser.telephone ? this.currentUser.telephone : ''
        }
        if (this.code_promo && this.code_promo.expirationDate <= new Date()) {
            this.$store.commit('SET_CODE_PROMO', null)
        }
    }
}
</script>
  
<style lang="scss">
@import "@/assets/scss/variables.scss";


.mondial-relay-right-column {
    display: none;
}
.checkout-title {
    padding-bottom:30px;
    color: $primary-color;
    font-size:40px;
}

.hr-section {
    background-color: $primary-color;
    opacity: 0.3
}

.title_section {
    font-weight: 400;
    font-size: 30px;
    color: $primary-color;
    margin-bottom:10px;
}

.main_checkout {
    padding-top:50px;
    padding-bottom:30px;
}

@media screen and (max-width: 1407px) {
    .main_checkout {
        .container {
            padding-left:0 !important;
            padding-right:0 !important;
        }
    }
}

@media screen and (max-width: 1023px) {
    .main_checkout {
        .container {
            padding-left:2rem !important;
            padding-right:2rem !important;
        }
    }
}

.errorCGV {
    font-size:14px;
    margin-top:-5px;
    color: red;
}

.login {
    color: $primary-color;
}

.formulaire_checkout {
    .columns, .column {
        padding-bottom:0 !important
    }
}


.password {
    .mdi-24px.mdi-set, .mdi-24px.mdi:before {
        font-size: 24px;
        color: $primary-color !important;
    }
}

.errorPassword {
    input {
        border-color:#f14668!important;
        border-radius:5px;
        border: 0.07rem solid;
    }
}

.text-error-password {
    color:#f14668!important;
    font-size: 0.75rem;
    margin-top: -0.5rem;
    margin-bottom: 0.75rem
}

.formulaire_input {
    margin-top:10px;
    p {
        margin-bottom:5px
    }
}

.want_to_create_account {
    margin-top:20px;
}

.main_checkout {
    .box {
        background-color: $secondary-color;
    }
}

.main_checkout {
    .container {
        padding-left:10rem;
        padding-right:10rem;
    }
}

.button_details {
    margin-top:10px;
}

.button-modifier {
    font-size: 17px;
    margin-top:10px;
    color: $primary-color;
    text-decoration: underline;
}

.button_mode_livraison { 
    margin-top:25px;
}

.button_payer {
    margin-top:30px;
}

.button_condition_generale {
    color: $primary-color;
    font-weight: 550;
}

.button_condition_generale:hover {
    color: $primary-color-hover;
}

.box-recap-commande-mobile {
    display:none;
}

@media screen and (max-width:1023px) {
    .box-recap-commande {
        display:none
    }

    .box-recap-commande-mobile {
        display:block;
    }
}

.type-livraison {
    font-size:18px;
    margin-bottom:10px;
}

.choix_mode_livraison {
    .box {
        cursor: pointer;
    }
}

.size-18 {
    font-size: 18px;
}

.is-margin-bottom-25 {
    margin-bottom:25px
}

.is-margin-bottom-10 {
    margin-bottom:10px
}

.box-recap-info-mobile {
    padding-bottom:0;
    margin-bottom:10px;
}

.box-recap-info {
    padding-bottom:0;
    margin-bottom:5px;
}

.box-recap-info-mobile {
    hr {
        margin-top:5px;
        margin-bottom:5px;
    }
}
</style>
  
