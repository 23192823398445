<template>
  <div id="app" class="app-test"> 
    <nav-bar v-if="$route.name != 'connexion' && $route.name != 'inscription'"/>
    <router-view :class="$route.name != 'connexion' && $route.name != 'inscription'? 'router-style' : ''"/>
    <pictos v-if="$route.name != 'connexion' && $route.name != 'inscription' && $route.name != 'checkout'"/>
    <footer-page v-if="$route.name != 'connexion' && $route.name != 'inscription'" class="footer-test"/>
    <cookies/>
    <go-to-top-button />
  </div>
</template>

<script>
import NavBar from '@/components/general/navbar/Navbar.vue'
import FooterPage from '@/components/general/footer/Footer.vue'
import Pictos from '@/components/general/Pictos.vue'
import Cookies from '@/components/general/Cookies.vue'
import GoToTopButton from '@/components/general/GoToTopButton.vue'

export default {
    name: 'App',
    components: {
      NavBar,
      FooterPage,
      Pictos,
      Cookies,
      GoToTopButton,
    },
}
</script>

<style lang="scss">
@import "@/assets/scss/variables.scss";
// Import Bulma's core
@import "~bulma/sass/utilities/_all";

// Set your colors
$primary: $primary-color;
$carousel-arrow-color: $primary !important;
$primary-light: findLightColor($primary);
$primary-dark: findDarkColor($primary);
$primary-invert: findColorInvert($primary);
$grey: #EAE0D9;
$grey-light: findLightColor($grey);
$grey-dark: findDarkColor($grey);
$grey-invert: findColorInvert($grey);
// Lists and maps
$custom-colors: null !default;
$custom-shades: null !default;
$modal-background-background-color: bulmaRgba($scheme-invert, 0.7) !important;

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: mergeColorMaps(
    (
        "terracota": (
            $primary-color,
            findLightColor($primary-color)
        ),
        "white": (
            $white,
            $black,
        ),
        "black": (
            $black,
            $white,
        ),
        "light": (
            $light,
            $light-invert,
        ),
        "dark": (
            $dark,
            $dark-invert,
        ),
        "primary": (
            $primary-color,
            $primary-invert,
            $primary-light,
            $primary-dark,
        ),
        "link": (
            $link,
            $link-invert,
            $link-light,
            $link-dark,
        ),
        "info": (
            red,
            $info-invert,
            $info-light,
            $info-dark,
        ),
        "success": (
            $success,
            $success-invert,
            $success-light,
            $success-dark,
        ),
        "warning": (
            $warning,
            $warning-invert,
            $warning-light,
            $warning-dark,
        ),
        "danger": (
            $danger,
            $danger-invert,
            $danger-light,
            $danger-dark,
        ),
        "grey": (
            $grey,
            $grey-invert,
            $grey-light,
            $grey-dark,
        ),
    ),
    $custom-colors
);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;
$link-active: $primary-color !important;
$button-active-color: $primary-color !important;
$tabs-toggle-link-active-background-color:$primary-color !important;
$tabs-toggle-link-active-border-color: $primary-color !important;

$navbar-background-color: rgb(255, 255, 255);
$title-weight: 400 !important;
$input-focus-border-color: white!important;
$input-focus-box-shadow-color: rgba($primary-color, 0.25) !important;

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";

.app-test {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.router-style {
    margin-top:6.5rem
}

@media screen and (min-width:769px) and (max-width: 1023px) {
    .container {
        margin-left:25px;
        margin-right: 25px
    }
}

@media screen and (max-width: 1023px) {
    .router-style {
        margin-top:4.9rem
    }
}

.footer-test {
    margin-top: auto;
}

.button.is-primary {
    // font-family: Futura,sans-serif;
    font-weight: 400 !important;
    background-color:$primary-color !important; //#AEC2BD !important;
}

.button.is-primary:hover {
    background-color:white !important; //#AEC2BD !important;
    border-color: $primary-color !important;
    color: $primary-color !important;
}

p {
    color: $text-color;
    size: $text-size;
}


.center-block {
    display:block;
    margin-left: auto;
    margin-right: auto;
}

.b-radio.radio input[type=radio]:checked + .check {
    border-color:$primary-color !important; //#AEC2BD !important;
}

.b-radio.radio input[type=radio] + .check {
    border-color:$primary-color !important; //#AEC2BD !important;
}


.b-radio.radio input[type=radio] + .check:before {
    background-color:$primary-color !important; //#AEC2BD !important;
}

.footer {
    padding: 2.5rem 1.5rem 3rem!important; 
}
</style>
