import { render, staticRenderFns } from "./ProductInformations.vue?vue&type=template&id=936bd69c&scoped=true&"
import script from "./ProductInformations.vue?vue&type=script&lang=js&"
export * from "./ProductInformations.vue?vue&type=script&lang=js&"
import style0 from "./ProductInformations.vue?vue&type=style&index=0&id=936bd69c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "936bd69c",
  null
  
)

export default component.exports