import Vue from 'vue';
import VueRouter from 'vue-router';

import HomeView from '../views/page/HomeView.vue';
import CommandesView from '../views/page/CommandesView.vue';
import CommandeDetailsView from '../views/page/CommandeDetailsView.vue';
import ProductsView from '../views/page/ProductsView.vue';
import PanierView from '../views/shop/PanierView.vue';
import LoginView from '../views/authentification/LoginView.vue';
import SigninView from '../views/authentification/SigninView.vue';
import CheckoutView from '../views/shop/CheckoutView.vue';
import ConfirmationView from '../views/shop/ConfirmationView.vue';
import ConditionGeneraleView from '../views/obligation/ConditionGeneraleView.vue';
import MentionsLegalesView from '../views/obligation/MentionsLegalesView.vue';
import RetourRemboursementView from '../views/obligation/RetourRemboursementView.vue';
import ConfidentialiteView from '../views/obligation/ConfidentialiteView.vue';
import ContactView from '../views/obligation/ContactView.vue';
import PageNotFound from '../views/obligation/PageNotFound.vue';
import ArticleBalleFouille from '../views/blog/ArticlesBalleFouille.vue';
import ArticleTapisFouille from '../views/blog/ArticlesTapisFouille.vue';

Vue.use(VueRouter);

const routes = [
  { path: '/', name: 'home', component: HomeView },
  { path: '/connexion', name: 'connexion', component: LoginView },
  { path: '/inscription', name: 'inscription', component: SigninView },
  { path: '/produits', name: 'products', component: ProductsView },
  { path: '/commandes', name: 'commandes', component: CommandesView },
  { path: '/commandes/:id', name: 'commande-details', component: CommandeDetailsView },
  { path: '/panier', name: 'panier', component: PanierView },
  { path: '/checkout', name: 'checkout', component: CheckoutView },
  { path: '/confirmation', name: 'confirmation', component: ConfirmationView },
  { path: '/condition-generale-de-vente', name: 'condition-generale', component: ConditionGeneraleView },
  { path: '/mentions-legales', name: 'mentions-legales', component: MentionsLegalesView },
  { path: '/confidentialite', name: 'confidentialite', component: ConfidentialiteView },
  { path: '/retour-et-remboursement', name: 'retour-et-remboursement', component: RetourRemboursementView },
  { path: '/contact', name: 'contact', component: ContactView },
  { path: '/articles/balle-fouille', name: 'article-balle-fouille', component: ArticleBalleFouille },
  { path: '/articles/tapis-fouille', name: 'article-tapis-fouille', component: ArticleTapisFouille },
  { path: '*', name: '404', component: PageNotFound },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
