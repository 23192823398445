<template>
    <div>
        <div class="columns is-multiline caracteristique" v-if="type == 'bandana-noel'">
            <div class="column is-6">
                <div class="caracteristique-titre">
                    Personnalisation :
                </div>
                <b-field :type="error && !tissuSelected ? 'is-danger' : ''">
                    <b-select v-model="tissuSelected" placeholder="Choisi un tissu" expanded>
                        <option></option>
                        <option v-for="tissu in tissus" :value="tissu" :key="tissu.id">{{tissu.name}}</option>
                    </b-select>
                </b-field>
            </div>
            <div class="column is-6" v-if="type != 'chouchou'">
                <div class="caracteristique-titre">
                    Avec chouchou assorti (+5 €)
                </div>
                <b-field style="padding-top:5px">
                    <b-switch
                        v-model="ajouterChouchou"
                        type="is-terracota">
                    </b-switch>
                </b-field>
            </div>
            <div class="column is-5">
                <p class="caracteristique-titre">Taille :
                    <a class="see-more" href="#information" v-on:click="goToInformation(2)">
                        voir les tailles
                    </a>
                </p>
                <b-field>
                    <b-select v-model="taille" :type="error && !this.taille ? 'is-danger' : ''" v-if="type != 'chouchou'">
                        <option value="extra-small">XS - {{  Number.parseFloat(prix['extra-small']).toFixed(2) }} €</option>
                        <option value="small">S - {{  Number.parseFloat(prix['small']).toFixed(2) }} €</option>
                        <option value="medium">M - {{  Number.parseFloat(prix['medium']).toFixed(2) }} €</option>
                        <option value="large">L - {{  Number.parseFloat(prix['large']).toFixed(2) }} €</option>
                        <option value="extra-large">XL - {{  Number.parseFloat(prix['extra-large'] ).toFixed(2) }} €</option>
                    </b-select>
                    <b-select v-model="taille" :type="error && !this.taille ? 'is-danger' : ''" v-else>
                        <option value="extra-small">Enfant - {{  Number.parseFloat(prix['extra-small']).toFixed(2) }} €</option>
                        <option value="medium">Adulte - {{  Number.parseFloat(prix['medium']).toFixed(2) }} €</option>
                    </b-select>
                </b-field>
            </div>
            <div class="column is-7">
                <p class="caracteristique-titre">Quantité : </p>
                <b-field>
                    <b-select v-model="quantite">
                        <option :value=1>1</option>
                        <option :value=2>2</option>
                        <option :value=3>3</option>
                        <option :value=4>4</option>
                        <option :value=5>5</option>
                        <option :value=6>6</option>
                        <option :value=7>7</option>
                        <option :value=8>8</option>
                        <option :value=9>9</option>
                    </b-select>
                </b-field>
            </div>
        </div>
        <div class="columns is-multiline caracteristique" v-else>
            <div class="column is-6">
                <div class="caracteristique-titre">
                    Tissu :
                    <a v-if="type != 'noeud-noel' && type != 'bandana-reversible-noel'" class="see-more" href="#information" v-on:click="goToInformation(1)">
                        voir les coloris
                    </a>
                </div>
                <b-field :type="error && !tissuSelected ? 'is-danger' : ''">
                    <b-select v-model="tissuSelected" placeholder="Choisi un tissu" expanded>
                        <option></option>
                        <option v-for="tissu in tissus" :value="tissu" :key="tissu.id">{{tissu.name}}</option>
                    </b-select>
                </b-field>
            </div>
            <div class="column is-6" v-if="type != 'chouchou'">
                <div class="caracteristique-titre">
                    Avec chouchou assorti (+5 €)
                </div>
                <b-field style="padding-top:5px">
                    <b-switch
                        v-model="ajouterChouchou"
                        type="is-terracota">
                    </b-switch>
                </b-field>
            </div>
            <div class="column is-7" v-if="type == 'bandana_prenom' || type == 'bandana_texte'">
                <p v-if="type == 'bandana_prenom'" class="caracteristique-titre">Prénom :</p>
                <p v-if="type == 'bandana_texte'" class="caracteristique-titre">Texte :</p>
                <b-field :type="error && !this.texte ? 'is-danger' : ''">
                    <b-input v-model="texte"></b-input>
                </b-field>
            </div>
            <div class="column" :class="ajouterTexteNoel ? 'is-6' : 'is-8'" v-if="type == 'bandana-reversible-noel'">
                <div class="caracteristique-titre">
                    <b-tooltip 
                        label="Ex : Prénom, Joyeux Noël, Merry Christmas, Ho Ho Ho"
                        position="is-top"
                        type="is-primary"
                    >
                        Avec texte personnalisé côté velour (+2€) : <b-icon class="test1" icon="help-circle" size="is-small"></b-icon>
                     </b-tooltip>
                </div>
                <b-field style="padding-top:5px">
                    <b-switch
                        v-model="ajouterTexteNoel"
                        type="is-terracota">
                    </b-switch>
                </b-field>
            </div>
            <div class="column is-4" v-if="type == 'bandana-reversible-noel' && !ajouterTexteNoel">
            </div>
            <div class="column is-6" v-if="type == 'bandana-reversible-noel' && ajouterTexteNoel">
                <p class="caracteristique-titre">
                    <b-tooltip 
                        label="Voir les images pour découvrir les personnalisations"
                        position="is-top"
                        type="is-primary"
                    >
                        Personnalisation : <b-icon class="test1" icon="help-circle" size="is-small"></b-icon>
                     </b-tooltip>
                </p>
                <b-field :type="error && !personnalisation ? 'is-danger' : ''">
                    <b-select v-model="personnalisation" placeholder="Personnalisation" expanded>
                        <option></option>
                        <option value="prenom" key="prenom">Prénom</option>
                        <option value="joyeux-noel" key="joyeux-noel">Joyeux Noël</option>
                        <option value="merry-christmas" key="merry-christmas">Merry Christmas</option>
                        <option value="hohoho" key="hohoho">Ho Ho Ho</option>
                    </b-select>
                </b-field>
            </div>
            <div class="column is-6" v-if="type == 'bandana-reversible-noel' && ajouterTexteNoel && personnalisation == 'prenom'">
            </div>
            <div class="column is-6" v-if="type == 'bandana-reversible-noel' && ajouterTexteNoel && personnalisation == 'prenom'">
                <p class="caracteristique-titre">
                    Prénom :
                </p>
                <b-field :type="error && !this.texte ? 'is-danger' : ''">
                    <b-input v-model="texte" maxlength="15"></b-input>
                </b-field>
            </div>
            
            <div class="column is-5" v-if="type == 'bandana_prenom'">
                <p class="caracteristique-titre">Couleur du texte : </p>
                <b-field :type="error && !this.couleurTexte ? 'is-danger' : ''">
                    <b-select v-model="couleurTexte" placeholder="Choisi une couleur" expanded>
                        <option></option>
                        <option value="doré">Doré</option>
                        <option value="argent">Argent</option>
                        <option value="confiance">Je fais confiance</option>
                    </b-select>
                </b-field>
            </div>
            <div class="column is-5" v-if="type == 'bandana_texte'">
            </div>
            <div class="column is-5">
                <p class="caracteristique-titre">Taille :
                    <a class="see-more" href="#information" v-on:click="goToInformation(2)">
                        voir les tailles
                    </a>
                </p>
                <b-field>
                    <b-select v-model="taille" :type="error && !this.taille ? 'is-danger' : ''" v-if="type != 'chouchou'">
                        <option value="extra-small">XS - {{  Number.parseFloat(prix['extra-small']).toFixed(2) }} €</option>
                        <option value="small">S - {{  Number.parseFloat(prix['small']).toFixed(2) }} €</option>
                        <option value="medium">M - {{  Number.parseFloat(prix['medium']).toFixed(2) }} €</option>
                        <option value="large">L - {{  Number.parseFloat(prix['large']).toFixed(2) }} €</option>
                        <option value="extra-large">XL - {{  Number.parseFloat(prix['extra-large'] ).toFixed(2) }} €</option>
                    </b-select>
                    <b-select v-model="taille" :type="error && !this.taille ? 'is-danger' : ''" v-else>
                        <option value="extra-small">Enfant - {{  Number.parseFloat(prix['extra-small']).toFixed(2) }} €</option>
                        <option value="medium">Adulte - {{  Number.parseFloat(prix['medium']).toFixed(2) }} €</option>
                    </b-select>
                </b-field>
            </div>
            <div class="column is-7">
                <p class="caracteristique-titre">Quantité : </p>
                <b-field>
                    <b-select v-model="quantite">
                        <option :value=1>1</option>
                        <option :value=2>2</option>
                        <option :value=3>3</option>
                        <option :value=4>4</option>
                        <option :value=5>5</option>
                        <option :value=6>6</option>
                        <option :value=7>7</option>
                        <option :value=8>8</option>
                        <option :value=9>9</option>
                    </b-select>
                </b-field>
            </div>
        </div>
        <b-button type="is-primary" size="is-medium" @click="ajouterPanier()">Ajouter au panier</b-button>
    </div>
</template>

<script>
export default {
    name: 'Bandana',
    data() {
        return {
            activeTab: 0,
            tissuSelected: "",
            texte: "",
            couleurTexte: "",
            ajouterChouchou: false,
            quantite: 1,
            taille: 'extra-small',
            error: false,
            ajouterTexteNoel: false,
            personnalisation: '',
        }
    },
    props: ['type', 'prix', 'tissus'],
    methods: {
        goToInformation(value) {
            this.$emit('goToInformation', value)
        },
        updateActiveTab(valueFromChild) {
            if (this.activeTab != valueFromChild) {
                this.activeTab = valueFromChild
            }
        },
        ajouterPanier() {
            this.error = false
            if (!this.tissuSelected) {
                this.error = true;
                return
            }
            let prix = this.prix[this.taille];
            if (this.type == "bandana-reversible-noel" && this.ajouterTexteNoel) {
                if (!this.personnalisation) {
                    this.error = true;
                    return
                } else if (this.personnalisation != 'prenom') {
                    this.texte = this.personnalisation
                }
                prix = this.prix[this.taille + '-texte']
            }

            let produit = {
                type: this.type,
                tissu: this.tissuSelected.id,
                tissuNom: this.tissuSelected.name,
                quantite: this.quantite,
                taille: this.taille,
                prix: prix
            }
            if (this.type == "bandana_prenom" || this.type == "bandana_texte" || (this.type == "bandana-reversible-noel" && this.ajouterTexteNoel)) {
                if (!this.texte) {
                    this.error = true;
                    return;
                }
                produit.texte = this.texte

                if (this.type == "bandana_prenom") {
                    if (!this.couleurTexte) {
                        this.error = true;
                        return;
                    }
                    produit.couleurTexte = this.couleurTexte
                }
            }

            this.$store.commit('ajouterPanier', produit)

            if (this.ajouterChouchou) {
                this.$store.commit('ajouterPanier', {
                    type: "chouchou",
                    tissu: this.tissuSelected.id,
                    tissuNom: this.tissuSelected.name,
                    quantite: 1,
                    prix: 5,
                    taille: 'medium'
                })
            }
            let message = 'Votre produit a été aujouté au panier'
            if (this.quantite > 1 || this.ajouterChouchou == true) {
                message = 'Vos produits ont été aujoutés au panier'
            }
            this.quantite = 1
            this.tissuSelected = ""
            this.texte = ""
            this.couleurTexte = ""
            this.ajouterChouchou = false
            this.$buefy.snackbar.open({
                duration: 5000,
                position: 'is-top',
                message: message,
                actionText: 'Voir mon panier',
                onAction: () => {
                    this.$router.push('/panier')
                }
            })

        }
    },
    watch: {
        type() {
            this.taille = 'extra-small'
        },
        taille() {
            this.$emit('updatePrice', this.taille)
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/scss/variables.scss";

.caracteristique {
    padding-top:20px;
}

.caracteristique-titre {
    margin-bottom:5px;
    font-weight: 650;
}

.see-more {
    color:$primary-color;
    font-weight: 400;
}

.notices .snackbar {
    background: $primary-color !important;
}

.notices .snackbar .action .button {
    color: $primary-color !important;
    font-weight: 600;
    text-transform: uppercase;
    background: white !important;
}

.test1 {
    color: $primary-color;
}

.b-tooltip.is-primary .tooltip-content {
    background: $primary-color
}

.b-tooltip.is-top.is-primary .tooltip-content::before {
    border-top-color: $primary-color;
}
</style>
