import Parse from 'parse'

export async function paiement(line_items, coupon, uuid, livraison, mode_livraison) {
    try {
        const response = await fetch(`https://api.camelimelo.fr/create-checkout-session`, {
        // const response = await fetch(`/api/create-checkout-session`, {
        // const response = await fetch(`http://localhost:4242/create-checkout-session`, {
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                line_items: line_items,
                coupon: coupon,
                uuid: uuid,
                livraison: livraison,
                mode_livraison: mode_livraison
            }),
            method: 'POST',
          })
        const value = await response.json()
        return value.url
    } catch(e) {
        throw new Error(e)
    }    
}

export async function commandeAnnule(objectId) {
    const ParseCommande = Parse.Object.extend("commande");
    const query = new Parse.Query(ParseCommande);

    query.equalTo("objectId", objectId);
    const result = await query.first()

    if (!result) {
        return;
    }

    result.set("status", "annulé")
    await result.save();
    return ''
}

export async function sauvegarderCommande(commande) {
    try {
        const ParseCommande = Parse.Object.extend("commande");
        const parseCommande = new ParseCommande();
        
        parseCommande.set("prenom", commande.prenom);
        parseCommande.set("nom", commande.nom);
        parseCommande.set("email", commande.email);
        parseCommande.set("adresse", commande.adresse);
        parseCommande.set("complement", commande.complement);
        parseCommande.set("code_postal", commande.code_postal);
        parseCommande.set("ville", commande.ville);
        parseCommande.set("telephone", commande.telephone);
        parseCommande.set("instagram", commande.instagram);
        if (Parse.User.current()) {
            parseCommande.set("user", Parse.User.current());
        }
        parseCommande.set("produits", commande.produits);
        parseCommande.set("quantite", commande.quantite);
        parseCommande.set("tarif", commande.tarif);
        parseCommande.set("tarif_avec_livraison", commande.tarif_avec_livraison);
        parseCommande.set("tarif", commande.tarif);
        parseCommande.set("code_promo", commande.code_promo);
        parseCommande.set("reduction", commande.reduction);
        parseCommande.set("status", "en attente de paiement");
        parseCommande.set("commande_id", commande.uuid);
        parseCommande.set("numero_commande", commande.numero_commande);
        parseCommande.set("livraison", commande.livraison);
        parseCommande.set("mode_livraison", commande.mode_livraison);
        parseCommande.set("mondial_relay", commande.mondial_relay);

        return await parseCommande.save()
    } catch(e) {
        console.log(e)
        throw new Error('Erreur d\'ajout de la commande dans Parse')
    }
}

export async function checkIfNumCommandeExist(numeroCommande) {
    const ParseCommande = Parse.Object.extend("commande");
    const query = new Parse.Query(ParseCommande);
    query.equalTo("numero_commande", numeroCommande);
    const results = await query.find();
    if (results.length == 0) {
        return false;
    }
    return true;
}