<template>
    <div>
        <div class="popup-cookie" v-if="popupCookieIsActive">
            <p class="cookie-titre">
                Ce site utilise des cookies.
                <a @click="accepterCookie(false)" class="is-pulled-right primary-color" ><b-icon icon="close" size="is-small"></b-icon></a>
            </p>
            <p class="cookie-description">Nous utilisons des cookies pour mémoriser vos préférences.</p>
            <button @click="accepterCookie(true)" class="cookie-consent-banner__cta">
                Accepter
            </button>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'Cookies',
    data() {
        return {
            popupCookieIsActive: false,
        }
    },
    computed: {
        ...mapState(['cookie'])
    },
    methods: {
        accepterCookie(value) {
            this.$store.commit('SET_COOKIE', value);
            this.popupCookieIsActive = false;
        },
    },
    mounted() {
        if (!this.cookie) {
            this.popupCookieIsActive = true;
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";

.popup-cookie {
    padding:15px;
    border: 5px solid $primary-color;
    border-radius:10px;
    max-width: 350px;
    z-index: 9999999;
    background-color: white;//#f0eae3;
    position: fixed;
    bottom: 25px;
    left: 25px;
}

.cookie-titre {
    font-weight: 600;
    margin-bottom:10px;
    font-size:17px;
    color:$primary-color
}

.cookie-description {
    margin-bottom:25px;
}

@media screen and (max-width: 769px) {
    .popup-cookie {
        display:hidden;
    }
}

.primary-color {
    color: $primary-color;
}

.cookie-consent-banner__cta {
    box-sizing: border-box;
    display: inline-block;
    width: 100%;
    padding: 11px 13px;
    border-radius: 5px;
    background-color: $primary-color;
    border: 2px solid $primary-color;
    color: #FFF;
    text-decoration: none;
    text-align: center;
    font-size: 16px;
    line-height: 20px;
}

.cookie-consent-banner__cta:hover {
    background-color: transparent;
    border: 2px solid $primary-color;
    color: $primary-color;
}
</style>