<template>
    <div>
        <!-- <div class="columns is-multiline caracteristique">
            <div class="column is-7">
                <p class="caracteristique-titre">Quantité : </p>
                <b-field>
                    <b-select v-model="quantite">
                        <option :value=1>1</option>
                        <option :value=2>2</option>
                        <option :value=3>3</option>
                        <option :value=4>4</option>
                        <option :value=5>5</option>
                        <option :value=6>6</option>
                        <option :value=7>7</option>
                        <option :value=8>8</option>
                        <option :value=9>9</option>
                    </b-select>
                </b-field>
            </div>
        </div>
        <b-button type="is-primary" size="is-medium" @click="ajouterPanier()">Ajouter au panier</b-button> -->
    </div>
</template>

<script>
export default {
    name: 'Unique',
    data() {
        return {
            activeTab: 0,
            error: false
        }
    },
    props: ['type', 'prix'],
    methods: {
        goToInformation(value) {
            this.$emit('goToInformation', value)
        },
        updateActiveTab(valueFromChild) {
            if (this.activeTab != valueFromChild) {
                this.activeTab = valueFromChild
            }
        },
        ajouterPanier() {
            let produit = {
                type: this.type,
                quantite: this.quantite,
                prix: this.prix['normal']
            }

            this.$store.commit('ajouterPanier', produit)

            let message = 'Votre produit a été aujouté au panier'
            if (this.quantite > 1) {
                message = 'Vos produits ont été aujoutés au panier'
            }
            this.quantite = 1
            this.$buefy.snackbar.open({
                duration: 5000,
                position: 'is-top',
                message: message,
                actionText: 'Voir mon panier',
                onAction: () => {
                    this.$router.push('/panier')
                }
            })

        }
    }
}
</script>

<style lang="scss">
@import "@/assets/scss/variables.scss";

.caracteristique {
    padding-top:20px;
}

.caracteristique-titre {
    margin-bottom:5px;
    font-weight: 650;
}

.see-more {
    color:$primary-color;
    font-weight: 400;
}

.notices .snackbar {
    background: $primary-color !important;
}

.notices .snackbar .action .button {
    color: $primary-color !important;
    font-weight: 600;
    text-transform: uppercase;
    background: white !important;
}
</style>
