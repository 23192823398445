<template>
    <div>
        <div class="container">
            <p class="title-commande has-text-centered">Mes commandes</p>
            <b-table :data="products" v-if="products.length" :selected.sync="selected">
                <b-table-column field="date" label="Date" v-slot="props" centered>
                    {{ new Date(props.row.createdAt).toLocaleDateString() }}
                </b-table-column>

                <b-table-column field="numero_commande" label="Numéro de commande" v-slot="props" centered>
                    N° {{ props.row.numero_commande }}
                </b-table-column>

                <b-table-column field="quantite" label="Produit(s)" v-slot="props" centered>
                    {{ props.row.quantite }}
                </b-table-column>

                <b-table-column field="tarif_avec_livraison" label="Total" v-slot="props" centered>
                    {{ Number.parseFloat(props.row.tarif_avec_livraison).toFixed(2) }} €
                </b-table-column>

                <b-table-column field="status" label="Etat de la commande" v-slot="props" centered>
                    {{ props.row.status.charAt(0).toUpperCase() + props.row.status.slice(1) }} 
                </b-table-column>
            </b-table>
            <div v-else>
                <p class="has-text-centered commande-empty-text">Aucune commande n'a encore été enregistrée pour le moment</p>
                <b-button class="go-to-product-button is-primary" tag="router-link" :to="{ path: '/produits' }">Voir les produits</b-button>
            </div>
        </div>
    </div>
</template>
  
<script>
import { getOrders } from '@/services/UserService'
import { Parse } from 'parse/lib/browser/Parse';

export default {
    name: 'CommandesView',
    data() {
        return {
            products: [],
            selected: null,
        }
    },
    async mounted() {
        if (!Parse.User.current()) {
            this.$router.push(`/`)
            return;
        }
        this.products = await getOrders(Parse.User.current())
    },
    watch: {
        selected() {
            this.$router.push(`/commandes/${this.selected.commande_id}`)
        }
    }
}
</script>
  
<style lang="scss">
@import "@/assets/scss/variables.scss";

@media screen and (max-width: 1023px) {
    .container {
        margin-left:25px;
        margin-right: 25px
    }
}

.container {
    padding-bottom:50px;
}

.title-commande {
    padding-top:50px;
    padding-bottom:30px;
    color: $primary-color;
    // font-weight: 750;
    font-size:40px;
}

.commande-empty-text {
    font-size:20px;
    font-weight: 700;
}

.go-to-product-button {
    margin-top:25px;
    margin-bottom:50px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 250px;
}

tr {
    cursor: pointer !important;
}

</style>
