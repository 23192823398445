<template>
  <div class="navbar-general">
    <b-navbar :mobile-burger=false>
      <template #brand>
        <div tag="router-link" :to="{ path: '/' }" style="margin-right:50px">
          <img v-on:click="goTo('/')" class="logo center-block" src="@/assets/img/logo/logo-terracota-detoure.webp" alt="logo">
        </div>
        <div class="custom-menu-shop-button">
          <shop-button-mobile class="is-pulled-right"></shop-button-mobile>
        </div>
        <div @click="showMenu = !showMenu" class="custom-menu-burger">
          <b-icon v-if="!showMenu" icon="menu"></b-icon>
          <b-icon v-else icon="close"></b-icon>
        </div>
      </template>
      <template #start>
        <b-navbar-dropdown class="custom-navbar-dropdown menu-item" v-for="categorie in categories" :key="categorie.key" :label="`${categorie.nom}`" :hoverable=true :arrowless=true>
          <div v-for="produit in produits" :key="produit.type">
            <b-navbar-item v-if="produit.categorie == categorie.key" v-on:click="goTo(`/produits/${produit.type}`)">
              {{ produit.titre_navbar }}
            </b-navbar-item>
          </div>
        </b-navbar-dropdown>
        <b-navbar-item v-on:click="goTo('/produits')" class="menu-item custom-navbar-dropdown">
            Voir tout
        </b-navbar-item>
      </template>
      <template #end>
        <div style="padding:25px" v-if="!currentUser">
          <b-button
            v-on:click="goTo('/inscription')"
            type="is-terracota"
            class="button-inscription"
          >
            Inscription
          </b-button>
          <b-button
            style="margin-left:15px"
            v-on:click="goTo('/connexion')"
            icon-left="account-circle"
            class="button-connexion"
          >
            Connexion
          </b-button>
        </div>
        <div style="padding:25px; padding-right:30px" v-else>
          <b-dropdown aria-role="list">
            <template #trigger>
              <user-button></user-button>
            </template>
            <!-- <b-dropdown-item aria-role="listitem">
              <b-icon icon="account" size="is-small"></b-icon>
              <span style="margin-left:10px">Mon profil</span>
            </b-dropdown-item> -->
            <b-dropdown-item aria-role="listitem" v-on:click="goTo('/commandes')">
              <b-icon icon="shopping" size="is-small"></b-icon>
              <span style="margin-left:10px">Mes commandes</span>
            </b-dropdown-item>
            <b-dropdown-item aria-role="listitem" v-on:click="logOut()">
              <b-icon icon="power" size="is-small"></b-icon>
              <span style="margin-left:10px">Me déconnecter</span>
            </b-dropdown-item>
        </b-dropdown>
        </div>
        <div style="padding:25px; padding-left:30px; padding-right:50px">
          <shop-button></shop-button>
        </div>
      </template>
    </b-navbar>
    <b-menu v-if="showMenu">
      <b-menu-list label="Produits">
        <b-menu-item v-for="categorie in categories" :key="categorie.key" :label="`${categorie.nom}`" class="sans-icon">
          <div v-for="produit in produits" :key="produit.type">
            <b-menu-item v-if="produit.categorie == categorie.key" v-on:click="goTo(`/produits/${produit.type}`)" :label="`${produit.titre_navbar}`"></b-menu-item>
          </div>
        </b-menu-item>
        <b-menu-item class="sans-icon" label="Voir tout" v-on:click="goTo('/produits')">
        </b-menu-item>
      </b-menu-list>
      <b-menu-list label="Profil">
        <b-menu-item v-if="!currentUser" class="sans-icon" v-on:click="goTo('/connexion')" label="Connexion"></b-menu-item>
        <b-menu-item v-if="!currentUser" class="sans-icon" v-on:click="goTo('/inscription')" label="Inscription"></b-menu-item>
        <b-menu-item v-if="currentUser" class="sans-icon" v-on:click="goTo('/commandes')" label="Mes commandes"></b-menu-item>
        <b-menu-item v-if="currentUser" class="sans-icon" v-on:click="logOut()" label="Me déconnecter"></b-menu-item>
      </b-menu-list>
    </b-menu>
  </div>
</template>

<script>
import ShopButton from './ShopButton.vue'
import ShopButtonMobile from './ShopButtonMobile.vue'
import UserButton from './UserButton.vue'
import { mapState } from 'vuex'
import {  logOut } from '@/services/UserService'

export default {
  name: 'NavBarSecondaire',
  components: {
    ShopButton,
    ShopButtonMobile,
    UserButton
  },
  data() {
    return {
      showMenu: false
    }
  },
  computed: {
    ...mapState(['currentUser', 'quantite', 'panier', 'produits', 'categories'])
  },
  methods: {
    goTo(dir) {
      this.showMenu = false;
      this.$router.push(`${dir}`)
    },
    async logOut() {
      await logOut()
      this.$store.commit('SET_CURRENT_USER', null)
      this.$router.push(`/`)
      this.showMenu = false
    }
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/variables.scss";

/* Style the collapsible content. Note: hidden by default */

.logo:hover {
  cursor: pointer;
}
.logo {
  padding:15px;
  padding-left:30px;
  max-height: 6.5rem !important;
}

.menu-item:hover {
  color: $primary-color !important;
  background-color: white !important;
  text-decoration: underline;
  text-underline-offset: 1rem;
  text-decoration-thickness: 0.12rem;
}

.menu-item {
  margin-left:15px !important;
  margin-right:15px !important;
}

.navbar-link:focus, .navbar-link:focus-within, .navbar-link:hover, .menu-item:hover {
  color: $primary-color !important;
  background-color: white !important;
}

.navbar-dropdown {
  margin-top:-33px;
  border-top: 2px solid $primary-color !important;
}

.navbar-general {
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);
  position: fixed;
  z-index: 999;
  width: 100%;
}

.custom-menu-burger {
  margin-left:0px;
  margin-right:30px;
  margin-top:29px;
  display: none;
}

.custom-menu-shop-button {
  margin-left:auto;
  margin-right:30px;
  padding-top:18px;
  display: none;
}

@media screen and (max-width: 1023px) {
  .logo {
    padding:8px;
    padding-left:15px;
    max-height: 4.9rem !important;
  }

  .custom-menu-burger {
    display: block;
  }

  .custom-menu-shop-button {
    display:block
  }
}

.menu-label {
  margin-left:25px;
}

.menu-list {
  .sans-icon {
    span {
      margin-left:25px
    }
  }

  .icon-text {
    margin-left:25px
  }
}

.menu {
  padding-top:10px;
  padding-bottom:10px;
}

.menu-list a.is-active {
  background-color: $primary-color !important;
  color: #fff;
}

.button-connexion {
  background-color: white !important;
  border-color: white!important;
  color: $primary-color !important;
  border:none;
}

.dropdown-menu {
  margin-left:-75px;
}
</style>
